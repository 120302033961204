






















































































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'ValidationWidget',
    props: {
        errors: { type: Array, required: false },
        isActive: { type: Boolean, default: false },
    },
    setup(props, { emit }) {
        const clickToggle = () => {
            emit('toggle');
        };
        return { clickToggle };
    },
});























import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import { BlockCategoryWrapper } from '../../../constants';
import { DagLoop, Task, WorkflowExecution } from '../../../types';
import Dag from './Dag.vue';

export default defineComponent({
    name: 'Graph',
    props: {
        tasks: { type: Array as PropType<Task[]>, required: true },
        selectedTask: { type: String, required: false },
        action: { type: String, default: null },
        runningExecution: { type: Object, default: null },
        invalidTaskIds: {
            type: Array,
            default: () => [],
        },
        blockedExecutions: {
            type: Array as PropType<WorkflowExecution[]>,
            default: () => [],
        },
        validationErrors: {
            type: Array,
            default: () => [],
        },
        pipelines: {
            type: Array,
            default: () => [],
        },
        dagLoops: {
            type: Array as PropType<DagLoop[]>,
            default: () => [],
        },
        readonly: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
    },
    components: { Dag },
    setup(props, { emit }) {
        const dagData: any = ref({
            nodes: [],
            edges: [],
        });

        const filteredGraphData = computed(() => {
            dagData.value.nodes = [];
            dagData.value.edges = [];

            props.tasks.forEach((task: any) => {
                const toEdges = task.downstreamTaskIds;
                if (toEdges.length) {
                    toEdges.forEach((e: any) =>
                        dagData.value.edges.push({
                            from: task.id,
                            to: e,
                        }),
                    );
                }

                const blockCategoryWrapper: any = BlockCategoryWrapper.find(task.block.category);
                dagData.value.nodes.push({
                    id: task.id,
                    label: task.block.name,
                    category: task.block.category,
                    name: task.displayName,
                    blockCategory: blockCategoryWrapper,
                    createdAt: task.createdAt,
                    executions: task.executions,
                });
            });

            return dagData.value;
        });

        const taskMap = computed(() =>
            props.tasks.reduce((acc: Record<string, Task>, task: Task): Record<string, Task> => {
                acc[task.id] = task;
                return acc;
            }, {}),
        );

        const showSettings = (id: string | number) => {
            const task: any = props.tasks.find((t: any) => t.id === id);
            emit('show-settings', task);
        };

        return {
            taskMap,
            filteredGraphData,
            showSettings,
            emit,
        };
    },
});

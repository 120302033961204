var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col flex-1 overflow-hidden"},[_c('TailwindTable',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"pagination":_vm.pagination,"loading":_vm.loading,"sortBy":_vm.query.sortBy},on:{"change-page":function($event){return _vm.$emit('change-page', $event)},"sort":_vm.changeSort},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
return [(row.asset)?[_vm._v(_vm._s(row.asset.name))]:_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("Asset "+_vm._s(row.assetId)+" does not exist")])]}},{key:"name-tooltip",fn:function(ref){
var row = ref.row;
return [(row.asset)?_c('div',{staticClass:"flex flex-col space-y-2"},[_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_c('span',{staticClass:"px-2 pt-1 pb-0.5 text-xs rounded bg-neutral-200 text-neutral-900"},[_vm._v(" ID "),_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(row.asset.id))])]),_c('span',[_vm._v(" "+_vm._s(row.asset.name)+" ")])]),_c('div',{staticClass:"max-w-sm text-xs italic truncate text-neutral-200"},[_vm._v(" "+_vm._s(row.asset.description)+" ")])]):_c('div',{staticClass:"flex flex-col space-y-2"},[_c('div',{staticClass:"max-w-sm text-xs italic truncate text-neutral-200"},[_vm._v(" Asset "+_vm._s(row.assetId)+" does not exist any more ")])])]}},{key:"type",fn:function(ref){
var row = ref.row;
return [(row.asset)?[_vm._v(_vm._s(_vm.assetTypeName(row.asset.assetTypeId)))]:_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("Asset "+_vm._s(row.assetId)+" does not exist")])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [(row.asset)?_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(row.asset.status))]):_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("Asset "+_vm._s(row.assetId)+" does not exist")])]}},{key:"workflowIds",fn:function(ref){
var value = ref.value;
var formatShortUid = ref.formatShortUid;
return [(value.length === 1)?_c('RouterLink',{attrs:{"to":{
                    name: 'workflow-designer:edit',
                    params: { id: ("" + value) },
                }}},[_vm._v(_vm._s(formatShortUid(value[0])))]):[_vm._v(_vm._s(value.length)+" Pipelines")]]}},{key:"workflowIds-tooltip",fn:function(ref){
                var value = ref.value;
                var formatShortUid = ref.formatShortUid;
return [_c('div',{staticClass:"flex flex-col space-y-1"},_vm._l((value),function(val){return _c('span',{key:val,staticClass:"flex flex-row space-x-2"},[_c('span',{staticClass:"font-bold"},[_vm._v("Pipeline")]),_c('span',[_vm._v(_vm._s(formatShortUid(val))+" ")])])}),0)]}},{key:"failedExecutions",fn:function(ref){
                var row = ref.row;
return [_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_c('WeatherDynamicIcon',{attrs:{"weather":row.failedExecutions / row.totalExecutions}}),_c('div',[_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(row.failedExecutions))]),_vm._v(" out of "+_vm._s(row.totalExecutions)+" ")])],1)]}},{key:"failedExecutions-tooltip",fn:function(ref){
                var row = ref.row;
return [_c('div',{staticClass:"flex flex-row items-center py-2 space-x-2 text-sm"},[_c('WeatherDynamicIcon',{attrs:{"weather":row.failedExecutions / row.totalExecutions}}),_c('div',[_vm._v(" "+_vm._s(row.failedExecutions)+" "+_vm._s(row.failedExecutions === 1 ? 'execution' : 'executions')+" failed out of "+_vm._s(row.totalExecutions)+" ")])],1)]}},{key:"errors",fn:function(ref){
                var value = ref.value;
                var row = ref.row;
return [(value.length === 0)?_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("No reason")]):_c('div',{staticClass:"flex flex-row items-center space-x-1"},[_c('ExecutionError',{attrs:{"error":value[0].errorCode,"occurrences":value[0].count,"totalOccurrences":row.totalFailures}}),_c('QuestionMarkCircleIcon',{staticClass:"w-4 h-4"})],1)]}},{key:"errors-tooltip",fn:function(ref){
                var value = ref.value;
                var row = ref.row;
return [(value.length === 0)?_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("No reasons for failure")]):_c('div',{staticClass:"flex flex-col p-3 space-y-1"},[_c('div',{staticClass:"font-bold"},[_vm._v("Reasons for failure")]),_c('div',{staticClass:"flex flex-col space-y-2"},_vm._l((_vm.R.min(value.length, 10)),function(v,vIdx){return _c('ExecutionError',{key:value[vIdx].errorCode,attrs:{"error":value[vIdx].errorCode,"occurrences":value[vIdx].count,"totalOccurrences":row.totalFailures,"extended":true}})}),1),(value.length > 10)?_c('div',{staticClass:"text-sm italic text-neutral-600"},[_vm._v("More reasons exist...")]):_vm._e()])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
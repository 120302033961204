import * as R from 'ramda';
import { RadialBarIcon } from '../../components/results-view/assets/icons';
import { ApexChartColors, ApexChartLineCap, ApexChartType, ChartConfigType } from '../../constants';
import { Chart } from './chart';

export class RadialBar extends Chart {
    protected chartType: ApexChartType = ApexChartType.RadialBar;

    protected description = 'Radial Bar Charts are mostly used in single-unit data to indicate progress/activity.';

    protected chartConfigurationSections: ChartConfigType[] = [ChartConfigType.General];

    protected icon: any = RadialBarIcon;

    protected showLabel = true;

    protected iAmPie = true;

    configuration(title = 'Title'): any {
        const stroke = { ...Chart.defaultStroke, lineCap: ApexChartLineCap.ROUND };
        return R.clone({
            ...Chart.defaultConfiguration(R.isNil(title) ? 'Title' : title, this.description),
            labels: [],
            plotOptions: {
                radialBar: {
                    hollow: {
                        margin: 15,
                        size: '70%',
                    },
                    //  https://apexcharts.com/docs/options/plotoptions/radialbar/
                    dataLabels: {
                        showOn: 'always',
                        name: {
                            offsetY: -10,
                            show: true,
                            color: ApexChartColors.ValueName,
                            fontSize: '48px',
                        },
                        value: {
                            color: ApexChartColors.Text,
                            fontSize: '30px',
                            show: true,
                        },
                    },
                },
            },
            stroke,
        });
    }
}

Chart.register(new RadialBar());






































import { InformationCircleIcon, SearchIcon, DownloadIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { useVisualisation } from '../../composable';
import { logoBase64 } from './assets/logo/logoBase64';
import { ApexChart } from './charts';

export default defineComponent({
    name: 'ResultsViewMain',
    props: {
        readOnly: {
            type: Boolean,
            default: false,
        },
        chartConfig: {
            type: Object,
        },
        upstreamTaskSample: {
            type: Array,
            default: () => [],
        },
    },
    components: { ApexChart, InformationCircleIcon, SearchIcon, DownloadIcon },
    setup(props, { emit }) {
        const chartRef = ref<any>({});

        const { computeResultsViewVisualisationData, downloadChart } = useVisualisation();

        const inputParams = computed(() => ({
            config: props.chartConfig,
            sampleData: props.upstreamTaskSample,
        }));

        const chartData = computed(() => computeResultsViewVisualisationData(inputParams.value));

        const testRun = () => {
            emit('test-run');
        };
        const resetZoom = () => {
            if (chartRef.value) chartRef.value.resetSeries(false, true);
        };

        return {
            chartData,
            downloadChart,
            logoBase64,
            chartRef,
            resetZoom,
            testRun,
        };
    },
});

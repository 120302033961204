













import { computed, defineComponent } from '@vue/composition-api';
import { PaperAirplaneIcon } from '@vue-hero-icons/outline';
import * as R from 'ramda';
import { S } from '@/app/utilities';
import { ExecutionTypeWrapper } from '../../constants';

export default defineComponent({
    name: 'RunWidget',
    props: {
        disabled: { type: Boolean, default: false },
        runningExecution: {
            type: Object,
            default: null,
        },
    },
    components: { PaperAirplaneIcon },
    setup(props) {
        const runningExecutionTooltip = computed(() => {
            if (!R.isNil(props.runningExecution) && S.has('task', props.runningExecution)) {
                const executionType = ExecutionTypeWrapper.find(props.runningExecution.type);
                return executionType?.message(props.runningExecution.status, props.runningExecution.task).message;
            }
            if (props.disabled) {
                return 'The analytics pipeline cannot be run';
            }
            return null;
        });

        return { runningExecutionTooltip };
    },
});

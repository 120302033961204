













































































































































import { defineComponent, computed, Ref, ref, PropType, watch } from '@vue/composition-api';
import {
    PlusIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    XIcon,
    PencilAltIcon,
    PresentationChartBarIcon,
} from '@vue-hero-icons/outline';
import { BlockCategoryWrapper, BlockCategory, ApexChartType } from '../../constants';
import { Chart, Task, Visualisation } from '../../types';

export default defineComponent({
    name: 'ResultsViewTask',
    props: {
        task: { type: Object as PropType<Task>, required: true },
        visualisations: { type: Array as PropType<Visualisation[]>, default: () => [] },
        disabled: { type: Boolean, default: false },
        selected: { type: String, required: false },
        inAddMode: { type: Boolean, default: false },
        inChangeChartTypeMode: { type: Boolean, default: false },
    },
    components: { PlusIcon, ChevronDownIcon, ChevronRightIcon, XIcon, PencilAltIcon, PresentationChartBarIcon },
    setup(props) {
        const expanded: Ref<boolean> = ref<boolean>(props.visualisations.length > 0);
        const blockCategory = computed(() => BlockCategoryWrapper.find(props.task.block.category as BlockCategory));
        const blockType = computed(() => {
            return props.task.block.type;
        });

        const label = computed(() => {
            if (blockType.value !== null) {
                return `${blockCategory.value?.label} (${blockType})`;
            }
            return blockType;
        });
        // keep since more block types will be added
        const colour = computed(() => blockCategory.value?.colour);

        const iconHtml = computed(() => {
            return blockCategory.value?.iconHtml;
        });

        const selectedVisualisation: Ref<Visualisation | undefined> = computed(() =>
            props.selected ? props.visualisations.find((v: Visualisation) => v.id === props.selected) : undefined,
        );

        const isTaskSelected = computed(() => selectedVisualisation.value?.taskId === props.task.id || props.inAddMode);

        const getChartIcon = (chartType: ApexChartType) => Chart.findChart(chartType)?.getIcon();

        watch(
            () => props.inAddMode,
            (newAddMode: boolean) => {
                if (newAddMode) expanded.value = true;
            },
            { immediate: true },
        );

        return {
            expanded,
            label,
            colour,
            iconHtml,
            blockCategory,
            isTaskSelected,
            getChartIcon,
        };
    },
});

import * as R from 'ramda';
import { DonutIcon } from '../../components/results-view/assets/icons';
import {
    AggregationFunction,
    ApexChartColors,
    ApexChartFontFamily,
    ApexChartType,
    ChartConfigType,
} from '../../constants';
import { Chart } from './chart';

export class Donut extends Chart {
    protected chartType: ApexChartType = ApexChartType.Donut;

    protected chartConfigurationSections: ChartConfigType[] = [ChartConfigType.General, ChartConfigType.Series];

    protected description = 'Donut Chart is useful in expressing data and information in terms of percentages, ratio.';

    protected icon: any = DonutIcon;

    protected aggregationFunctions: AggregationFunction[] = [AggregationFunction.COUNT, AggregationFunction.SUM];

    protected showTotal = true;

    protected iAmPie = true;

    configuration(title = 'Title'): any {
        return R.clone({
            ...Chart.defaultConfiguration(R.isNil(title) ? 'Title' : title, this.description),
            labels: [],
            legend: {
                position: 'right',
                offsetY: 35,
                show: true,
                itemMargin: {
                    horizontal: 6,
                    vertical: 6,
                },
                horizontalAlign: 'center',
                floating: false,
                fontSize: 14,
                fontFamily: ApexChartFontFamily.DEFAULT,
                fontWeight: 400,
            },
            dataLabels: {
                enabled: true,
            },
            plotOptions: {
                pie: {
                    startAngle: 0,
                    endAngle: 360,
                    expandOnClick: true,
                    offsetX: 0,
                    offsetY: 30,
                    customScale: 1,
                    dataLabels: {
                        offset: 0,
                        minAngleToShowLabel: 10,
                    },
                    donut: {
                        size: '65%',
                        background: 'transparent',
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                fontSize: '22px',
                                fontFamily: ApexChartFontFamily.DEFAULT,
                                fontWeight: 600,
                                color: undefined,
                                offsetY: -10,
                            },
                            value: {
                                show: true,
                                fontSize: '16px',
                                fontFamily: ApexChartFontFamily.DEFAULT,
                                fontWeight: 400,
                                color: undefined,
                                offsetY: 16,
                            },
                            total: {
                                show: false,
                                showAlways: false,
                                label: 'Total',
                                fontSize: '32px',
                                fontFamily: 'sans-serif',
                                fontWeight: 600,
                                color: ApexChartColors.TotalLabel,
                                formatter(w: any) {
                                    return `${w.globals.seriesTotals.reduce((a: any, b: any) => {
                                        return Number(parseFloat(a + b).toFixed(2));
                                    }, 0)}`;
                                },
                            },
                        },
                    },
                },
            },
            xaxis: {
                type: '',
            },
        });
    }
    // eslint-disable-next-line class-methods-use-this
    yAxisSeriesOptions(structure: any): any[] {
        const temp: any[] = [];
        Object.keys(structure).forEach((key) => {
            // PieFamily can visualise all data types
            temp.push({ label: key, value: structure[key] });
        });

        return temp;
    }
}

Chart.register(new Donut());

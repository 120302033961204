























































































import { MonitoringAPI } from '@/app/api';
import { TailwindTable } from '@/app/components';
import { ColumnPosition, ColumnType, WorkflowType } from '@/app/constants';
import { MonitoringExecutionQuery } from '@/app/types';
import { Block } from '@/modules/workflow-designer/types';
import { useAxios } from '@/app/composable';
import { HashtagIcon } from '@vue-hero-icons/outline';
import { QuestionMarkCircleIcon } from '@vue-hero-icons/solid';
import { PropType, Ref, computed, defineComponent, ref, watch } from '@vue/composition-api';
import ExecutionError from './ExecutionError.vue';

export default defineComponent({
    name: 'TopFailures',
    props: {
        query: { type: Object as PropType<MonitoringExecutionQuery>, required: true },
        blocks: { type: Object as PropType<Record<string, Block>>, required: true },
    },
    components: { TailwindTable, ExecutionError, HashtagIcon, QuestionMarkCircleIcon },
    setup(props, { root, emit }) {
        const { exec, loading } = useAxios(true);
        const failures: Ref<any> = ref<any>();

        const fetchFailures = async (query: MonitoringExecutionQuery) => {
            await exec(MonitoringAPI.executionTopFailures(query, WorkflowType.Analytics))
                .then((res: any) => {
                    failures.value = res.data;
                    emit('update-show-results-warning', res.data.pagination.total >= 1000);
                })
                .catch((e) => {
                    (root as any).$toastr.e(e.response.data.message, 'Error');
                    throw e;
                });
        };

        const columns = [
            {
                key: 'errorCode',
                label: 'Failure',
                type: ColumnType.Integer,
                position: ColumnPosition.Left,
            },
            {
                key: 'blockId',
                label: 'Relevant Block',
                type: ColumnType.String,
                showAfter: 'xl',
                position: ColumnPosition.Center,
            },
            {
                key: 'executionsCount',
                label: 'Occurrences',
                type: ColumnType.Integer,
                showAfter: 'sm',
                sortable: true,
            },
            {
                key: 'workflowIds',
                label: 'Affected Analytics Pipeline(s)',
                type: ColumnType.Array,
            },
            {
                key: 'dateFrom',
                label: 'First Occurrence',
                type: ColumnType.Datetime,
                showAfter: 'xl',
                formatInUtc: true,
                sortable: true,
            },
            {
                key: 'dateTo',
                label: 'Latest Occurrence',
                type: ColumnType.Datetime,
                showAfter: 'md',
                formatInUtc: true,
                sortable: true,
            },
        ];

        const rows = computed(() => failures.value?.results);
        const rejectedItems = computed(() => failures.value?.accessControl?.rejectedItems ?? 0);
        const pagination = computed(() => failures.value?.pagination);

        const changeSort = (sortBy: { field: string; asc: boolean }) => {
            emit('sort', sortBy);
        };

        const blockName = (blockKey: string) => {
            const block = props.blocks[blockKey];
            return block ? block.name : blockKey;
        };

        watch(
            () => props.query,
            (query: MonitoringExecutionQuery, oldQuery: MonitoringExecutionQuery | undefined) => {
                if (!oldQuery || JSON.stringify(oldQuery) !== JSON.stringify(query)) fetchFailures(query);
            },
            { deep: true, immediate: true },
        );

        watch(
            () => loading.value,
            (l: boolean) => emit('loading', l),
            { immediate: true },
        );

        return {
            rows,
            pagination,
            loading,
            columns,
            blockName,
            rejectedItems,
            changeSort,
        };
    },
});




























import { defineComponent, PropType } from '@vue/composition-api';
import { WorkflowExecution } from '../../types';

export default defineComponent({
    name: 'BlockedExecutionsMessage',
    props: {
        blockedExecutions: {
            type: Array as PropType<WorkflowExecution[]>,
            required: true,
        },
        blockerExecutions: {
            type: Array as PropType<WorkflowExecution[]>,
            required: true,
        },
    },
});

import * as R from 'ramda';
import { BarIcon } from '../../components/results-view/assets/icons';
import { ApexChartDataTypes, ApexChartType, ApexChartXAxisValidValues } from '../../constants';
import { Chart } from './chart';

export class Bar extends Chart {
    protected chartType: ApexChartType = ApexChartType.Bar;

    protected description = 'Bar graphs provide a visual presentation of categorical data.';

    protected validDataTypes: string[] = [
        ApexChartDataTypes.INT,
        ApexChartDataTypes.DOUBLE,
        ApexChartDataTypes.DATETIME,
        ApexChartDataTypes.STRING,
    ];

    protected aggregationMaxFields = 1;

    protected icon: any = BarIcon;

    protected xAxisTypes = {
        category: ApexChartXAxisValidValues.category,
    };

    configuration(title: string): any {
        const xaxis = { ...Chart.defaultXAxis.xaxis, type: 'category' };
        const yaxis = {
            ...Chart.defaultYAxis.yaxis,
            labels: { ...Chart.defaultYAxis.yaxis.labels, offsetX: 0 },
        };

        return R.clone({
            ...Chart.defaultConfiguration(R.isNil(title) ? 'Title' : title, this.description),
            ...Chart.defaultLegend,
            ...Chart.defaultChartOption,
            ...Chart.defaultDataLabels,
            yaxis,
            xaxis,
            labels: [],
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 3,
                    columnWidth: '70%',
                    barHeight: '100%',
                    distributed: false,
                    rangeBarOverlap: false,
                    rangeBarGroupRows: false,
                },
            },
        });
    }
}

Chart.register(new Bar());


















































import { computed, defineComponent, PropType, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { useFailedTasks } from '../../composable';
import { BlockCategory, BlockCategoryWrapper } from '../../constants';
import { Task } from '../../types';
import TableViewTask from './TableViewTask.vue';

export default defineComponent({
    name: 'TableViewOverview',
    model: {
        prop: 'selected',
        event: 'update-selected',
    },
    props: {
        selected: {
            type: Object,
            default: null,
        },
        tasks: {
            type: Array as PropType<Task[]>,
            required: true,
        },
        taskMap: {
            type: Map,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        columnsPerTask: {
            type: Object,
        },
        runningExecution: {
            type: Object,
            default: null,
        },
        invalidTaskIds: {
            type: Array,
            default: () => [],
        },
        validationErrors: {
            type: Array,
            default: () => [],
        },
    },
    components: { TableViewTask },
    setup(props, { emit, root }) {
        const taskMap = computed(() => Object.fromEntries(props.taskMap));
        const { getFailureMessage } = useFailedTasks(taskMap);

        const allUpstreamTaskIds = (task: Task) => {
            if (!R.isNil(task) && task.upstreamTaskIds.length > 0) {
                const otherUpstreamTaskIds: string[] = task.upstreamTaskIds.reduce((acc: string[], id: string) => {
                    return [...acc, ...allUpstreamTaskIds(props.taskMap.get(id) as Task)];
                }, []);
                return [...task.upstreamTaskIds, ...otherUpstreamTaskIds];
            }
            return [];
        };

        const isHighlighted = (task: Task) => {
            return (
                props.selected === null ||
                props.selected.id === task.id ||
                allUpstreamTaskIds(props.selected as Task).includes(task.id)
            );
        };

        const allCategories = BlockCategoryWrapper.all();

        const clearSelected = () => {
            emit('update-selected', null);
        };

        const showSettings = (task: Task) => {
            emit('show-settings', task);
        };

        watch(
            () => props.tasks,
            (changedTasks: any) => {
                if (changedTasks.filter((task: Task) => props.selected && task.id === props.selected.id).length === 0) {
                    clearSelected();
                }

                // Defaulting to first task if none is selected
                if (R.isNil(props.selected) && changedTasks.length > 0) {
                    emit('update-selected', changedTasks[0]);
                }
            },
        );

        // Clearing selection in case escape is clicked
        const handleEscape = (e: KeyboardEvent) => {
            if ((e.key === 'Esc' || e.key === 'Escape') && !props.disabled) {
                clearSelected();
            }
        };

        const selectTask = (task: Task) => {
            emit('update-selected', task);
        };

        const validationInfo = (task: Task) => {
            const isValid = !props.invalidTaskIds.includes(task.id);
            if (!isValid) {
                const error: any = props.validationErrors.filter((vError: any) => vError.taskId === task.id);

                return {
                    isValid,
                    message: error.length > 0 ? error[0].message : '',
                    isMajor: error.length > 0 ? error[0].major : undefined,
                };
            }
            return { isValid: true };
        };

        document.addEventListener('keydown', handleEscape);
        root.$once('hook:beforeDestroy', () => {
            document.removeEventListener('keydown', handleEscape);
        });

        return {
            isHighlighted,
            showSettings,
            allCategories,
            selectTask,
            validationInfo,
            BlockCategory,
            getFailureMessage,
        };
    },
});

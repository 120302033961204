



















































import * as R from 'ramda';
import { defineComponent, computed, ref } from '@vue/composition-api';
import { TwAccordion, TwAccordionCard } from '@/app/components';
import { ExclamationIcon, ExclamationCircleIcon } from '@vue-hero-icons/solid';
import { TaskConfigurationMessageType } from '../../constants/task-configuration-message-type.constants';

export default defineComponent({
    name: 'TaskConfigurationMessages',
    props: {
        messages: {
            type: Array,
            required: true,
            default: () => [],
        },
        configuration: {
            type: Object,
        },
    },
    components: {
        TwAccordion,
        TwAccordionCard,
        ExclamationIcon,
        ExclamationCircleIcon,
    },
    setup(props) {
        const config = ref(props.configuration);

        const isWarn = (type: string) => type?.toLowerCase() === TaskConfigurationMessageType.WARN;
        const isInfo = (type: string) => type?.toLowerCase() === TaskConfigurationMessageType.INFO;

        const configMessages = computed(() => {
            if (!props.messages.length) return [];

            const data: any = props.messages.reduce((acc: any, obj: any) => {
                // set warnings first and everything else below
                obj.priority = isWarn(obj.type) ? 0 : 1;

                // set default type to info for those messages without type
                obj.type = 'type' in obj ? obj.type : TaskConfigurationMessageType.INFO;

                if (obj.displayIf) {
                    const validKeys = Object.keys(obj.displayIf).filter((key: any) => {
                        if (!config.value) return false;

                        const fieldValue = obj.displayIf[key];
                        const configValue = config.value[key].value;

                        if (R.hasIn('hasValue', fieldValue)) {
                            return fieldValue.hasValue ? !R.isNil(configValue) : R.isNil(configValue);
                        }

                        if (R.hasIn('value', fieldValue)) {
                            if (R.type(fieldValue.value) === 'Array') {
                                return fieldValue.value.includes(configValue);
                            }

                            return fieldValue.value === configValue;
                        }

                        return false;
                    });

                    if (!validKeys.length) return acc;
                }

                acc.push(obj);

                return acc;
            }, []);

            // sort messages by order field
            return R.sort(R.ascend<any>(R.prop('priority')), data);
        });

        const warnText = computed(() => {
            const warnings = configMessages.value.filter((obj: any) => obj.type === TaskConfigurationMessageType.WARN);
            if (!warnings.length) return;
            let text = 'Warning';
            text = warnings.length > 1 ? `${text}s` : text;

            return `${warnings.length} ${text}`;
        });

        const infoText = computed(() => {
            const info = configMessages.value.filter(
                (obj: any) => obj.type === TaskConfigurationMessageType.INFO || !R.hasIn('type', obj),
            );

            if (!info.length) return;

            return `${info.length} Info`;
        });

        return {
            warnText,
            infoText,
            isWarn,
            isInfo,
            configMessages,
        };
    },
});













import { computed, defineComponent, PropType } from '@vue/composition-api';
import { ApexOptions } from 'apexcharts';
import apexchart from 'vue-apexcharts';

export default defineComponent({
    name: 'SchemaChangesBarChart',
    props: {
        selection: { type: Object as PropType<{ x: number; y: number }>, required: false },
        series: { type: Array as PropType<{ name: string; data: number[] }[]>, default: () => [] },
        xAxisCategories: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
        stacked: {
            type: Boolean,
            default: true,
        },
        title: {
            type: Object as PropType<{
                text: string;
                align: string;
            }>,
            required: false,
        },
        chartHeight: { type: String, default: '100%' },
        chartWidth: { type: String, default: '100%' },
        tooltipFormatter: { type: Function, required: false },
        identifier: { type: String, required: false },
        colors: { type: Array as PropType<string[]>, required: false },
        showYAxisLabels: { type: Boolean, default: false },
        showXAxisLabels: { type: Boolean, default: false },
        xAxisTitle: { type: String, required: false },
        yAxisTitle: { type: String, required: false },
    },
    components: { apexchart },
    setup(props, { emit }) {
        const options = computed(() => {
            const chartOptions: ApexOptions = {
                legend: { show: false },
                yaxis: {
                    labels: { show: props.showYAxisLabels },
                    axisBorder: {
                        offsetX: 0,
                        offsetY: 0,
                    },
                    title: { text: props.yAxisTitle || undefined },
                },
                xaxis: {
                    categories: props.xAxisCategories,
                    labels: { show: props.showXAxisLabels },
                    title: { text: props.xAxisTitle || undefined },
                },
                chart: {
                    height: props.chartHeight,
                    width: props.chartWidth,
                    stacked: props.stacked,
                    toolbar: { show: false },
                    offsetX: 0,
                    offsetY: 0,
                },
                states: {
                    active: {
                        filter: {
                            type: 'none',
                        },
                    },
                },
                grid: {
                    show: true,
                    xaxis: {
                        lines: {
                            offsetX: 0,
                            offsetY: 0,
                        },
                    },
                    yaxis: {
                        lines: {
                            offsetX: 0,
                            offsetY: 0,
                        },
                    },
                    padding: {
                        top: props.showYAxisLabels ? 0 : -30,
                        right: 0,
                        bottom: props.showXAxisLabels ? 0 : -30,
                        left: 0,
                    },
                },

                tooltip: {
                    enabled: true,
                    custom: props.tooltipFormatter,
                },
                colors: props.colors,
                dataLabels: { enabled: false },
            };

            return chartOptions;
        });

        const chartClickEvent = (event: Event, chartContext: any, config: any) => {
            if (config.seriesIndex >= 0 && config.dataPointIndex >= 0)
                emit('data-point-clicked', { series: config.seriesIndex, dataPoint: config.dataPointIndex });
            else emit('chart-clicked');
        };

        return { options, chartClickEvent };
    },
});






























import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'ResultsViewChart',
    props: {
        chart: {
            type: Object,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        stopClickPropagation: {
            type: Boolean,
            default: true,
        },
        colour: {
            type: String,
            default: 'blue',
        },
    },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col flex-1 overflow-hidden"},[_c('TailwindTable',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"pagination":_vm.pagination,"loading":_vm.loading,"sortBy":_vm.query.sortBy},on:{"change-page":function($event){return _vm.$emit('change-page', $event)},"sort":_vm.changeSort},scopedSlots:_vm._u([{key:"errorCode",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"flex flex-row items-center space-x-1"},[_c('ExecutionError',{attrs:{"error":value}}),_c('QuestionMarkCircleIcon',{staticClass:"w-4 h-4"})],1)]}},{key:"errorCode-tooltip",fn:function(ref){
var value = ref.value;
return [_c('ExecutionError',{attrs:{"error":value,"extended":true}})]}},{key:"workflowIds",fn:function(ref){
var value = ref.value;
var formatShortUid = ref.formatShortUid;
return [(value.length === 1)?[_vm._v(_vm._s(formatShortUid(value[0])))]:[_vm._v(_vm._s(value.length)+" Pipelines")]]}},{key:"workflowIds-tooltip",fn:function(ref){
var value = ref.value;
var formatShortUid = ref.formatShortUid;
return [_c('div',{staticClass:"flex flex-col space-y-1"},_vm._l((value),function(val){return _c('span',{key:val,staticClass:"flex flex-row space-x-2"},[_c('span',{staticClass:"font-bold"},[_vm._v("Pipeline")]),_c('span',[_vm._v(_vm._s(formatShortUid(val))+" ")])])}),0)]}},{key:"blockId",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',{staticClass:"inline-flex group-hover:bg-secondary-700 group-hover:text-secondary-100 items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-neutral-200 text-neutral-800"},[_vm._v(" "+_vm._s(_vm.blockName(value))+" ")]):_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("No related block")])]}},{key:"blockId-tooltip",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v("Failed in "),_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(_vm.blockName(value)))]),_vm._v(" step")])]}},{key:"executionsCount",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"flex flex-row items-center space-x-1"},[_c('HashtagIcon',{staticClass:"w-3.5 h-3.5 text-neutral-700"}),_c('span',[_vm._v(_vm._s(value))])],1)]}},{key:"executionsCount-tooltip",fn:function(ref){
var value = ref.value;
return [_vm._v(" Appeared in "+_vm._s(value)+" failed "+_vm._s(value.length === 1 ? 'execution' : 'executions')+" ")]}},{key:"dateFrom",fn:function(ref){
var formattedValue = ref.formattedValue;
var formattedTooltip = ref.formattedTooltip;
return [_c('div',{staticClass:"w-40 whitespace-nowrap"},[_c('span',{staticClass:"block group-hover:hidden"},[_vm._v(_vm._s(formattedValue))]),_c('span',{staticClass:"hidden group-hover:block"},[_vm._v(_vm._s(formattedTooltip))])])]}},{key:"dateTo",fn:function(ref){
var formattedValue = ref.formattedValue;
var formattedTooltip = ref.formattedTooltip;
return [_c('div',{staticClass:"w-40 whitespace-nowrap"},[_c('span',{staticClass:"block group-hover:hidden"},[_vm._v(_vm._s(formattedValue))]),_c('span',{staticClass:"hidden group-hover:block"},[_vm._v(_vm._s(formattedTooltip))])])]}},{key:"footerRow",fn:function(){return [(_vm.rejectedItems)?[_vm._v(" Due to access level limitations, "+_vm._s(_vm.rejectedItems)+" item"+_vm._s(_vm.rejectedItems === 1 ? ' is' : 's are')+" omitted ")]:_vm._e()]},proxy:true},{key:"noRowsMessage",fn:function(){return [(!_vm.rejectedItems)?_c('span',[_vm._v("No data to show")]):_c('span',[_vm._v("Due to access level limitations, "+_vm._s(_vm.rejectedItems)+" item"+_vm._s(_vm.rejectedItems === 1 ? ' is' : 's are')+" omitted")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }













































































import { computed, defineComponent, ref } from '@vue/composition-api';
import { BlockCategoryWrapper } from '../../constants';
import { LoginIcon, ClipboardListIcon, ChipIcon, LogoutIcon, AdjustmentsIcon } from '@vue-hero-icons/outline';

export default defineComponent({
    name: 'LibraryMenuFilters',
    props: {
        collapsed: {
            type: Boolean,
            default: false,
        },
        // disableClickEvent: {
        //     type: Boolean,
        //     default: false,
        // },
    },
    components: { LoginIcon, ClipboardListIcon, ChipIcon, LogoutIcon, AdjustmentsIcon },
    setup(props, { emit }) {
        const activeFilter = ref('input');
        const categories = computed(() => BlockCategoryWrapper.all());
        const changeFilter = (filter: string) => {
            // if (!props.disableClickEvent) {
            activeFilter.value = filter;
            emit('change-filter', filter);
            // }
        };
        return { emit, activeFilter, changeFilter, categories };
    },
});





































import { defineComponent } from '@vue/composition-api';
import { v4 as uuidv4 } from 'uuid';

export default defineComponent({
    name: 'WorkflowTriggersPlaceholder',
    props: {
        triggersExists: {
            type: Boolean,
            default: false,
        },
        show: {
            type: Boolean,
            default: false,
        },
        canEditTrigger: {
            type: Boolean,
            default: false,
        },
        triggerType: {
            type: String,
            default: 'trigger',
        },
    },
    setup() {
        return {
            uuidv4,
        };
    },
});






























import { defineComponent } from '@vue/composition-api';
import { ChevronDoubleUpIcon } from '@vue-hero-icons/outline';

export default defineComponent({
    name: 'UpgradeWidget',
    props: {
        disabled: { type: Boolean, default: false },
    },
    components: { ChevronDoubleUpIcon },
});

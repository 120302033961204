import * as R from 'ramda';
import { LineIcon } from '../../components/results-view/assets/icons';
import { AggregationFunction, ApexChartType, ApexChartXAxisValidValues } from '../../constants';
import { Chart } from './chart';

export class Line extends Chart {
    protected chartType: ApexChartType = ApexChartType.Line;

    protected description = 'Line graphs can be used to show how data change over time';

    protected xAxisTypes = {
        numeric: ApexChartXAxisValidValues.numeric,
        datetime: ApexChartXAxisValidValues.datetime,
    };

    protected icon: any = LineIcon;

    protected aggregationFunctions: AggregationFunction[] = [
        AggregationFunction.AVG,
        AggregationFunction.COUNT,
        AggregationFunction.SUM,
    ];

    configuration(title: string): any {
        const chart = { ...Chart.defaultChartOption.chart, ...Chart.defaultChartSelection };
        return R.clone({
            ...Chart.defaultConfiguration(R.isNil(title) ? 'Title' : title, this.description),
            ...Chart.defaultLegend,
            ...Chart.defaultStroke,
            ...Chart.defaultMarkers,
            ...Chart.defaultGrid,
            ...Chart.defaultXAxis,
            ...Chart.defaultYAxis,
            ...Chart.defaultDataLabels,
            chart,
        });
    }
}

Chart.register(new Line());

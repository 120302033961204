



import { defineComponent, watch } from '@vue/composition-api';

export default defineComponent({
    name: 'ValidationStatus',
    props: {
        invalid: {
            type: Boolean,
            required: true,
        },
        canSave: {
            type: Boolean,
            required: true,
        },
    },
    setup(props, { emit }) {
        watch(
            () => [props.invalid, !props.canSave],
            (updated: any) => emit('change', updated[0] || updated[1]),
        );
    },
});

import * as R from 'ramda';
import { PieIcon } from '../../components/results-view/assets/icons';
import { AggregationFunction, ApexChartFontFamily, ApexChartType, ChartConfigType } from '../../constants';
import { Chart } from './chart';

export class Pie extends Chart {
    protected chartType: ApexChartType = ApexChartType.Pie;

    protected description = 'Pie Chart is useful in expressing data and information in terms of percentages, ratio.';

    protected icon: any = PieIcon;

    protected iAmPie = true;

    protected chartConfigurationSections: ChartConfigType[] = [ChartConfigType.General, ChartConfigType.Series];

    protected aggregationFunctions: AggregationFunction[] = [AggregationFunction.COUNT, AggregationFunction.SUM];

    configuration(title = 'Title'): any {
        return R.clone({
            ...Chart.defaultConfiguration(R.isNil(title) ? 'Title' : title, this.description),
            labels: [],
            legend: {
                position: 'right',
                offsetY: 35,
                show: true,
                itemMargin: {
                    horizontal: 6,
                    vertical: 6,
                },
                horizontalAlign: 'center',
                floating: false,
                fontSize: 14,
                fontFamily: ApexChartFontFamily.DEFAULT,
                fontWeight: 400,
            },
            dataLabels: {
                enabled: true,
            },
            plotOptions: {
                pie: {
                    startAngle: 0,
                    endAngle: 360,
                    expandOnClick: true,
                    offsetX: 0,
                    offsetY: 30,
                    customScale: 1,
                    dataLabels: {
                        offset: 0,
                        minAngleToShowLabel: 10,
                    },
                },
            },
            xaxis: {
                type: '',
            },
        });
    }

    // eslint-disable-next-line class-methods-use-this
    yAxisSeriesOptions(structure: any): any[] {
        const temp: any[] = [];
        Object.keys(structure).forEach((key) => {
            // PieFamily can visualise all data types
            temp.push({ label: key, value: structure[key] });
        });

        return temp;
    }
}

Chart.register(new Pie());




































































































































































import * as R from 'ramda';
import { ConfirmModal, OnClickOutside, Toggle } from '@/app/components';
import { maxLengthValidator, requiredValidator } from '@/app/validators';
import { WorkflowStatus } from '@/modules/apollo/constants/apollo-pipeline.constants';
import { computed, defineComponent, PropType, Ref, ref, watch } from '@vue/composition-api';
import { clone } from 'ramda';
import { extend, ValidationObserver } from 'vee-validate';
import { CheckCircleIcon, XCircleIcon } from '@vue-hero-icons/solid';
import SampleConfiguration from './SampleConfiguration.vue';
import { Task, Workflow } from '../../types';
import { ExecutionFramework } from '../../constants';

extend('required', requiredValidator);
extend('max', maxLengthValidator);

export default defineComponent({
    name: 'Configure',
    props: {
        workflow: { type: Object as PropType<Workflow>, required: true },
        tasks: { type: Array as PropType<Task[]>, required: true },
        isFinalised: { type: Boolean, default: false },
        isDeprecated: { type: Boolean, default: false },
        queryParams: {
            type: String,
            default: '{}',
        },
    },
    components: {
        ConfirmModal,
        ValidationObserver,
        OnClickOutside,
        SampleConfiguration,
        Toggle,
        CheckCircleIcon,
        XCircleIcon,
    },
    setup(props, { emit }) {
        const justCreated = ref(true);
        const showConfirmation = ref(false);
        const runInBatchesInitial = ref<boolean>(
            !R.isNil(props.workflow.configuration.batchable) ? props.workflow.configuration.batchable : true,
        );
        const runInBatches = ref<boolean>(runInBatchesInitial.value);

        const workflowConfigurationRef = ref(null);

        const sampleConfiguration = ref(
            props.workflow.configuration?.sample
                ? clone(props.workflow.configuration.sample)
                : {
                      size: {
                          rowsCount: 1000,
                          percentage: null,
                      },
                      selection: 'first',
                  },
        );

        const differencesExist = computed(() => {
            if (!props.workflow) return false;
            if (runInBatchesInitial.value !== runInBatches.value) return true;

            const sample = JSON.stringify(props.workflow.configuration.sample);
            const currentSample = JSON.stringify(sampleConfiguration.value);

            if (!sample) return false;

            const hasDiff = sample !== currentSample;

            if (hasDiff) {
                emit('toggle-click-event', true);
            }

            return hasDiff;
        });

        const batchablePrerequisites: Ref<Record<string, boolean>> = computed(() => {
            return {
                allBlocksBatchable: !props.tasks.some((t: Task) => !t.block.batchable),
                inPython: props.workflow.framework === ExecutionFramework.python3,
                atLeastOneCompatibleRead: props.tasks.some((t: Task) =>
                    ['ui.ReadDatasetWithFlatten', 'ui.ReadDatasetWithoutFlatten', 'ui.ReadResult'].includes(t.block.id),
                ),
                isNotRandomSample: sampleConfiguration.value.selection !== 'random',
            };
        });

        const isBatchablePrerequisitesMet = computed(
            () => !Object.values(batchablePrerequisites.value).some((prerequisite: boolean) => !prerequisite),
        );

        const close = (confirm = true) => {
            if (differencesExist.value && confirm) {
                showConfirmation.value = true;
            } else if (!justCreated.value) {
                emit('toggle-click-event', false);
                emit('cancel');
                showConfirmation.value = false;
            }
            justCreated.value = false;
        };

        const save = () => {
            emit('save-configuration', {
                configuration: {
                    ...props.workflow.configuration,
                    sample: sampleConfiguration.value,
                    batchable: runInBatches.value,
                },
            });
        };

        watch(
            () => props.workflow.configuration.batchable,
            (newBatchable: boolean | undefined) => (runInBatchesInitial.value = R.isNil(newBatchable) || newBatchable),
        );

        return {
            showConfirmation,
            close,
            save,
            differencesExist,
            sampleConfiguration,
            workflowConfigurationRef,
            WorkflowStatus,
            runInBatches,
            batchablePrerequisites,
            isBatchablePrerequisitesMet,
        };
    },
});

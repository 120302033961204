












































import { AdvancedSelect, ButtonGroup } from '@/app/components';
import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';
import { usePipelineSearch } from '../../composable';
import { TriggerPipelineStatus, TriggerEntityConditionType } from '../../constants';
import { TriggerPipelineExecutedCondition, Workflow } from '../../types';

export default defineComponent({
    name: 'TriggerPipelineExecutedCheck',
    components: {
        AdvancedSelect,
        ValidationProvider,
        ButtonGroup,
    },
    props: {
        condition: {
            type: Object as PropType<TriggerPipelineExecutedCondition>,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        workflow: {
            type: Object as PropType<Workflow>,
            required: true,
        },
        excludedPipelineIds: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
    },
    setup(props, { emit, root }: { emit: any; root: any }) {
        const noOfItems = ref<number>(30);
        const pipelineType = computed(() =>
            props.condition.entityTypeId === TriggerEntityConditionType.DataCheckin ? 'data-checkin' : 'analytics',
        );
        const selectedEntityId = computed(() => props.condition.entityId as string);
        const excludedPipelineIds = computed(() => [props.workflow.id, ...props.excludedPipelineIds]);

        const { pipelines, refetch, totalItems, loading: loadingPipelines } = usePipelineSearch(
            root,
            pipelineType,
            noOfItems,
            selectedEntityId,
            excludedPipelineIds,
        );

        const statuses = [
            {
                label: TriggerPipelineStatus.Success,
            },
            {
                label: TriggerPipelineStatus.Failure,
            },
        ];

        const handlePipelineChange = (_: string, pipeline: any) => (props.condition.entityName = pipeline.name);

        watch(
            () => props.condition.entityTypeId,
            async () => {
                await refetch('', 1);
            },
            { immediate: true },
        );

        watch(
            () => pipelines.value,
            () => {
                emit(
                    'pipelines-fetched',
                    pipelines.value.find((pipeline: any) => pipeline.id === props.condition.entityId),
                );
            },
        );

        watch(
            () => loadingPipelines.value,
            () => emit('set-loading', loadingPipelines.value),
            { immediate: true },
        );

        return {
            pipelines,
            totalItems,
            noOfItems,
            statuses,
            refetch,
            handlePipelineChange,
            TriggerEntityConditionType,
        };
    },
});

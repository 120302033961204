var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col flex-1 overflow-hidden"},[_c('portal',{attrs:{"to":"modals"}},[(_vm.chartModal && _vm.chartModal.chartType === 'column')?_c('ResultCompletenessColumnChangesModal',{attrs:{"completeness":_vm.completeness,"columnChangesSeries":_vm.columnChangesSeries,"columnChangesTooltipFormatter":_vm.columnChangesTooltipFormatter,"chartColors":_vm.chartColors},on:{"close":function($event){_vm.chartModal = undefined}},model:{value:(_vm.chartModal),callback:function ($$v) {_vm.chartModal=$$v},expression:"chartModal"}}):(_vm.chartModal && _vm.chartModal.chartType === 'type')?_c('ResultCompletenessTypeChangesModal',{attrs:{"completeness":_vm.completeness,"columnTypeChangesSeries":_vm.columnTypeChangesSeries,"columnTypeChangesTooltipFormatter":_vm.columnTypeChangesTooltipFormatter,"chartColors":_vm.chartColors},on:{"close":function($event){_vm.chartModal = undefined}},model:{value:(_vm.chartModal),callback:function ($$v) {_vm.chartModal=$$v},expression:"chartModal"}}):_vm._e()],1),_c('TailwindTable',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"pagination":_vm.pagination,"loading":_vm.loading,"sortBy":_vm.query.sortBy},on:{"change-page":function($event){return _vm.$emit('change-page', $event)},"sort":_vm.changeSort},scopedSlots:_vm._u([{key:"asset",fn:function(ref){
var value = ref.value;
var row = ref.row;
return [(value)?[_vm._v(_vm._s(value.name))]:_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("Asset "+_vm._s(row.assetId)+" does not exist")])]}},{key:"asset-tooltip",fn:function(ref){
var value = ref.value;
var row = ref.row;
return [(value)?_c('div',{staticClass:"flex flex-col space-y-2 text-sm"},[_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_c('span',{staticClass:"px-2 pt-1 pb-0.5 text-xs rounded bg-neutral-200 text-neutral-900"},[_vm._v(" ID "),_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(value.id))])]),_c('span',[_vm._v(" "+_vm._s(value.name)+" ")])]),_c('div',{staticClass:"max-w-sm text-xs italic truncate text-neutral-200"},[_vm._v(" "+_vm._s(value.description)+" ")])]):_c('div',{staticClass:"flex flex-col space-y-2"},[_c('div',{staticClass:"max-w-sm text-xs italic truncate text-neutral-200"},[_vm._v(" Asset "+_vm._s(row.assetId)+" does not exist any more ")])])]}},{key:"workflowId",fn:function(ref){
var value = ref.value;
var formattedValue = ref.formattedValue;
return [(value)?[_vm._v(_vm._s(formattedValue))]:_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("-")])]}},{key:"volume",fn:function(ref){
var row = ref.row;
return [(row.asset.volume)?[_vm._v(_vm._s(row.asset.volume.value)+" "+_vm._s(row.asset.volume.unit))]:_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("-")])]}},{key:"successfulExecutions",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_c('WeatherDynamicIcon',{attrs:{"weather":row.totalExecutions > 0
                            ? (row.totalExecutions - row.successfulExecutions) / row.totalExecutions
                            : 0}}),_c('div',{staticClass:"whitespace-nowrap"},[_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(row.successfulExecutions))]),_vm._v(" out of "+_vm._s(row.totalExecutions)+" ")])],1)]}},{key:"successfulExecutions-tooltip",fn:function(ref){
                            var row = ref.row;
return [_c('div',{staticClass:"flex flex-row items-center py-2 space-x-2 text-sm"},[_c('WeatherDynamicIcon',{attrs:{"weather":row.totalExecutions > 0
                            ? (row.totalExecutions - row.successfulExecutions) / row.totalExecutions
                            : 0}}),_c('div',[_vm._v(" Percentage of successful executions that loaded data to the asset compared to the total executions: "),_c('span',{staticClass:"font-bold whitespace-nowrap"},[_vm._v(_vm._s(((row.successfulExecutions / row.totalExecutions) * 100).toFixed(2))+"%")])])],1)]}},{key:"header-columnChanges-tooltip",fn:function(){return [_c('div',{staticClass:"flex flex-col w-64 space-y-1 text-sm"},[_c('span',{staticClass:"font-bold"},[_vm._v("Column-level Consistency")]),_c('span',[_vm._v(" Column-level schema changes in terms of existing, new or removed columns between subsequent executions, for the latest 10 successful executions. The older executions appear in the left while the latest executions in the right. View the details by clicking on the bar chart. ")])])]},proxy:true},{key:"columnChanges",fn:function(ref){
                            var index = ref.index;
return [(_vm.columnChangesSeries && _vm.columnChangesSeries[index].series.length > 0)?_c('SchemaChangesBarChart',{staticClass:"cursor-pointer",attrs:{"series":_vm.columnChangesSeries[index].series,"xAxisCategories":_vm.columnChangesSeries[index].labels,"tooltipFormatter":_vm.columnChangesTooltipFormatter,"identifier":("" + index),"chartWidth":"200px","chartHeight":"60px","colors":_vm.chartColors},on:{"chart-clicked":function($event){return _vm.showDetailChart('column', index)},"data-point-clicked":function($event){return _vm.showDetailChart('column', index, $event.dataPoint)}}}):_vm._e()]}},{key:"header-columnDataTypeChanges-tooltip",fn:function(){return [_c('div',{staticClass:"flex flex-col w-64 space-y-1 text-sm"},[_c('span',{staticClass:"font-bold"},[_vm._v("Type-level Consistency")]),_c('span',[_vm._v("Type-level schema changes in terms of columns that changed data type between subsequent executions, for the latest 10 successful executions. The older executions appear in the left while the latest executions in the right. View the details by clicking on the bar chart.")])])]},proxy:true},{key:"columnDataTypeChanges",fn:function(ref){
                            var index = ref.index;
return [(_vm.columnTypeChangesSeries && _vm.columnTypeChangesSeries[index].series.length > 0)?_c('SchemaChangesBarChart',{staticClass:"cursor-pointer",attrs:{"series":_vm.columnTypeChangesSeries[index].series,"xAxisCategories":_vm.columnTypeChangesSeries[index].labels,"tooltipFormatter":_vm.columnTypeChangesTooltipFormatter,"identifier":("" + index),"chartWidth":"200px","chartHeight":"60px"},on:{"chart-clicked":function($event){return _vm.showDetailChart('type', index)},"data-point-clicked":function($event){return _vm.showDetailChart('type', index, $event.dataPoint)}}}):_vm._e()]}},{key:"completeness",fn:function(ref){
                            var value = ref.value;
return [(value !== null)?_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_c('WeatherDynamicIcon',{attrs:{"weather":1 - value}}),_c('div',[_vm._v(_vm._s((value * 100).toFixed(2))+" %")])],1):_c('div',{staticClass:"italic text-neutral-500"},[_vm._v("Not calculated")])]}},{key:"completeness-tooltip",fn:function(ref){
                            var value = ref.value;
return [(value)?_c('div',{staticClass:"flex flex-row items-center space-x-2 text-sm"},[_c('WeatherDynamicIcon',{attrs:{"weather":1 - value}}),_c('div',[_vm._v(" Presence of non-null values compared to total values: "),_c('span',{staticClass:"font-bold whitespace-nowrap"},[_vm._v(_vm._s((value * 100).toFixed(2))+"%")])])],1):_c('div',{staticClass:"italic text-neutral-500"},[_vm._v("Not completeness was calculated")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }































































































































import { defineComponent, ref, computed } from '@vue/composition-api';
import { requiredValidator } from '@/app/validators';
import { ValidationProvider, extend } from 'vee-validate';
import { TwSelect, TwInput } from '@/app/components';
import { AggregationFunction, YAxisSeriesSchema } from '@/modules/workflow-designer/constants';
import { InformationCircleIcon, PlusIcon } from '@vue-hero-icons/outline';
import { XCircleIcon } from '@vue-hero-icons/solid';
import { v4 as uuidv4 } from 'uuid';

extend('required', requiredValidator);

const numericTypes: string[] = ['int', 'double'];

export default defineComponent({
    name: 'ChartConfigurationPieFamilySeries',
    model: {
        prop: 'seriesOptions',
        event: 'update-series-options',
    },
    props: {
        seriesOptions: {
            type: Object,
            required: true,
        },
        availableSeries: {
            type: Array,
            default: () => [],
        },
        xAxisSeriesOptions: {
            type: Array,
            default: () => [],
        },
        allowMultiSeries: {
            type: Boolean,
            default: false,
        },
        showSeriesName: {
            type: Boolean,
            default: false,
        },
        validationRules: {
            type: Object,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        chart: {
            type: Object,
        },
    },
    components: {
        TwSelect,
        TwInput,
        ValidationProvider,
        InformationCircleIcon,
        XCircleIcon,
        PlusIcon,
    },
    setup(props, { emit }) {
        const hasAggregation = ref<boolean>(false);
        const selectInputUuid = `field_${uuidv4()}`;
        const nameInputUuid = `field_${uuidv4()}`;
        const labelInputUuid = `field_${uuidv4()}`;

        const xAxisSchema = {
            name: '',
            type: '',
            fields: [],
            buckets: null,
        };

        const editedSeriesOptions = computed({
            get: () => props.seriesOptions,
            set: (updatedSeriesOptions: any) => {
                emit('update-series-options', updatedSeriesOptions);
            },
        });

        const showInfo = computed(() => {
            // Hide info box if groupBy another field is selected
            if (hasAggregation.value) {
                return false;
            }
            // Hide info box if groupBy fields have been loaded
            const groupByValues = editedSeriesOptions.value.groupBy.fields.length;
            if (groupByValues > 1) {
                return false;
            }
            // Hide info box if groupBy field is not the same with series input, which means we don't have single series input
            if (
                groupByValues === 1 &&
                !editedSeriesOptions.value.series.find((serie: any) =>
                    editedSeriesOptions.value.groupBy.fields.includes(serie.field),
                )
            ) {
                return false;
            }
            return true;
        });

        const handleAddSeries = () => {
            // Reset inputs and keep series name
            editedSeriesOptions.value = {
                ...editedSeriesOptions.value,
                groupBy: { ...xAxisSchema },
                series: [
                    {
                        ...YAxisSeriesSchema,
                        name: editedSeriesOptions.value?.series[0]?.name || '',
                    },
                ],
            };
            hasAggregation.value = true;
        };

        const handleRemoveSeries = () => {
            // Reset inputs and keep series name
            editedSeriesOptions.value = {
                ...editedSeriesOptions.value,
                groupBy: { ...xAxisSchema },
                series: [
                    {
                        ...YAxisSeriesSchema,
                        name: editedSeriesOptions.value?.series[0]?.name || '',
                    },
                ],
            };
            hasAggregation.value = false;
        };

        const showAddSeries = computed(() => {
            if (props.allowMultiSeries || (props.availableSeries.length && showInfo.value)) {
                return true;
            }
            return false;
        });

        const validYSeriesInput = computed(() => {
            if (!showInfo.value) {
                return props.availableSeries.filter((option: any) => numericTypes.includes(option.value));
            }
            return props.availableSeries;
        });

        const handleInputChange = (value: any, name: string) => {
            if (name === 'series') {
                const selectedOption: any = props.availableSeries.find((x: any) => x.label === value) || null;
                const updatedItem = {
                    ...editedSeriesOptions.value.series[0],
                    type: selectedOption.value,
                    field: value,
                };
                //Checks if it is single series or not
                if (showInfo.value) {
                    editedSeriesOptions.value = {
                        ...editedSeriesOptions.value,
                        series: [{ ...updatedItem }],
                        groupBy: { ...editedSeriesOptions.value.groupBy, type: selectedOption.value, fields: [value] },
                    };
                } else {
                    editedSeriesOptions.value = {
                        ...editedSeriesOptions.value,
                        series: [{ ...updatedItem }],
                    };
                }
            } else if (name === 'groupBy') {
                if (value.length > 1) {
                    editedSeriesOptions.value = {
                        ...editedSeriesOptions.value,
                        groupBy: { ...editedSeriesOptions.value.groupBy, type: 'category', fields: value }, // type for multiple groupBy fields is category
                    };
                } else {
                    const selectedOption: any = props.availableSeries.find((x: any) => x.label === value[0]) || null;
                    editedSeriesOptions.value = {
                        ...editedSeriesOptions.value,
                        groupBy: { ...editedSeriesOptions.value.groupBy, type: selectedOption.value, fields: value },
                    };
                }
            }
        };

        const aggregationFunctionsOptions = computed(() => {
            // For single input, aggregation must be count.
            // #Group by other fields case
            if (!showInfo.value) {
                return props.chart?.getAggregationFunctions();
            }
            // #Single input case
            return [AggregationFunction.COUNT];
        });

        return {
            handleAddSeries,
            editedSeriesOptions,
            handleRemoveSeries,
            showAddSeries,
            handleInputChange,
            aggregationFunctionsOptions,
            validYSeriesInput,
            selectInputUuid,
            nameInputUuid,
            labelInputUuid,
            hasAggregation,
            showInfo,
        };
    },
});














































































import { defineComponent, ref } from '@vue/composition-api';
import { LockClosedIcon, LockOpenIcon } from '@vue-hero-icons/outline';
import { ConfirmModal } from '@/app/components';

export default defineComponent({
    name: 'LockWidget',
    props: {
        canBeReopened: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        isLocked: {
            type: Boolean,
            default: false,
        },
    },
    components: { LockClosedIcon, LockOpenIcon, ConfirmModal },
    setup() {
        const showUnlockConfirmation = ref<boolean>(false);

        return { showUnlockConfirmation };
    },
});

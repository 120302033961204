













































































































































































import { MonitoringAPI } from '@/app/api';
import { TailwindTable, WeatherDynamicIcon } from '@/app/components';
import { ColumnPosition, ColumnType } from '@/app/constants';
import { MonitoringExecutionQuery } from '@/app/types';
import { Block, ResultCompleteness, ResultCompletenessResult } from '@/modules/workflow-designer/types';
import { useAxios } from '@/app/composable';

import ResultCompletenessColumnChangesModal from './ResultCompletenessColumnChangesModal.vue';
import ResultCompletenessTypeChangesModal from './ResultCompletenessTypeChangesModal.vue';
import SchemaChangesBarChart from './SchemaChangesBarChart.vue';

import { AssetType } from '@/modules/asset/constants';
import { PropType, Ref, computed, defineComponent, ref, watch } from '@vue/composition-api';
import { useResultCompleteness } from '../../composable';
import { ColumnChangeStatus } from '../../constants';

export default defineComponent({
    name: 'ResultCompleteness',
    props: {
        query: { type: Object as PropType<MonitoringExecutionQuery>, required: true },
        blocks: { type: Object as PropType<Record<string, Block>>, required: true },
    },
    components: {
        TailwindTable,
        WeatherDynamicIcon,
        SchemaChangesBarChart,
        ResultCompletenessColumnChangesModal,
        ResultCompletenessTypeChangesModal,
    },
    setup(props, { root, emit }) {
        const { exec, loading } = useAxios(true);
        const completeness: Ref<ResultCompleteness | undefined> = ref<ResultCompleteness>();
        const {
            columnChangesSeries,
            columnTypeChangesSeries,
            columnChangesTooltipFormatter,
            columnTypeChangesTooltipFormatter,
        } = useResultCompleteness(completeness);
        const chartModal: Ref<{ chartType: string; row: number; selectedBar?: number } | undefined> = ref<
            { chartType: string; row: number; selectedBar?: number } | undefined
        >();
        const chartColors = [
            '#3182ce', // primary-600
            '#d69e2e', // secondary-600
        ];
        const MAX_ENTRIES = 10;

        const fetchCompleteness = async (query: MonitoringExecutionQuery) => {
            await exec(MonitoringAPI.executionCompleteness(query, AssetType.Result))
                .then((res: any) => {
                    completeness.value = {
                        results: res.data.results.reduce(
                            (acc: ResultCompletenessResult[], row: ResultCompletenessResult) => {
                                const columnChangesFiller: any[] = [];
                                for (
                                    let i = 0;
                                    row.columnChanges.length < MAX_ENTRIES &&
                                    i < MAX_ENTRIES - row.columnChanges.length;
                                    i++
                                ) {
                                    columnChangesFiller.push(undefined);
                                }
                                const columnChangesLastTenEntries = row.columnChanges.slice(
                                    row.columnChanges.length - MAX_ENTRIES > 0
                                        ? row.columnChanges.length - MAX_ENTRIES
                                        : 0,
                                    row.columnChanges.length,
                                );
                                const columnTypeChangesFiller: any[] = [];

                                for (
                                    let i = 0;
                                    row.columnDataTypeChanges.length < MAX_ENTRIES &&
                                    i < MAX_ENTRIES - row.columnDataTypeChanges.length;
                                    i++
                                ) {
                                    columnTypeChangesFiller.push(undefined);
                                }

                                const columnTypeChangesLastTenEntries = row.columnDataTypeChanges.slice(
                                    row.columnDataTypeChanges.length - MAX_ENTRIES > 0
                                        ? row.columnDataTypeChanges.length - MAX_ENTRIES
                                        : 0,
                                    row.columnDataTypeChanges.length,
                                );
                                acc.push({
                                    ...row,
                                    columnChanges: columnChangesFiller.concat(columnChangesLastTenEntries),
                                    columnDataTypeChanges: columnTypeChangesFiller.concat(
                                        columnTypeChangesLastTenEntries,
                                    ),
                                });
                                return acc;
                            },
                            [],
                        ),
                        pagination: res.data.pagination,
                    };
                    emit('update-show-results-warning', res.data.pagination.total >= 1000);
                })
                .catch((e) => {
                    (root as any).$toastr.e(e.response.data.message, 'Error');
                    throw e;
                });
        };

        const columns = [
            {
                key: 'asset',
                label: 'Asset',
                type: ColumnType.Object,
                link: (value: { id: number }) => {
                    return value ? { name: 'assets:view', params: { id: `${value.id}` } } : null;
                },
            },
            {
                key: 'workflowId',
                label: 'Pipeline ID',
                type: ColumnType.Uid,
                showAfter: 'md',
                copy: true,
                link: (value: number, row: any) => {
                    return value
                        ? {
                              name: 'history',
                              params: { workflowId: `${value}` },
                              query: {
                                  executionId: row.executionId,
                              },
                          }
                        : null;
                },
            },
            {
                key: 'volume',
                label: 'Volume',
                type: ColumnType.Object,
            },
            {
                key: 'successfulExecutions',
                label: 'Reliability',
                type: ColumnType.Integer,
                showAfter: 'md',
                position: ColumnPosition.Center,
            },
            {
                key: 'totalColumns',
                label: 'Number of Columns',
                type: ColumnType.Integer,
            },
            {
                key: 'columnChanges',
                label: 'Column-level Consistency',
                type: ColumnType.Object,
                position: ColumnPosition.Center,
                showAfter: 'lg',
            },
            {
                key: 'columnDataTypeChanges',
                label: 'Type-level Consistency',
                type: ColumnType.Object,
                position: ColumnPosition.Center,
                showAfter: 'lg',
            },
            {
                key: 'completeness',
                label: 'Completeness (%)',
                type: ColumnType.Double,
                sortable: true,
            },
        ];

        const rows = computed(() => completeness.value?.results);

        const pagination = computed(() => completeness.value?.pagination);

        const changeSort = (sortBy: { field: string; asc: boolean }) => {
            emit('sort', sortBy);
        };

        const blockName = (blockKey: string) => {
            const block = props.blocks[blockKey];
            return block ? block.name : blockKey;
        };

        const showDetailChart = (chartType: string, row: number, selectedBar?: number) => {
            chartModal.value = { chartType, row, selectedBar };
        };

        watch(
            () => props.query,
            (query: MonitoringExecutionQuery, oldQuery: MonitoringExecutionQuery | undefined) => {
                if (!oldQuery || JSON.stringify(oldQuery) !== JSON.stringify(query)) fetchCompleteness(query);
            },
            { deep: true, immediate: true },
        );

        watch(
            () => loading.value,
            (l: boolean) => emit('loading', l),
            { immediate: true },
        );

        return {
            rows,
            loading,
            columns,
            pagination,
            chartModal,
            chartColors,
            completeness,
            ColumnChangeStatus,
            columnChangesSeries,
            columnTypeChangesSeries,
            blockName,
            changeSort,
            showDetailChart,
            columnChangesTooltipFormatter,
            columnTypeChangesTooltipFormatter,
        };
    },
});



















































































































import * as R from 'ramda';
import { MonitoringAPI } from '@/app/api';
import { TailwindTable, WeatherDynamicIcon } from '@/app/components';
import { ColumnPosition, ColumnType } from '@/app/constants';
import { MonitoringExecutionQuery } from '@/app/types';
import { Block } from '@/modules/workflow-designer/types';
import { useAxios } from '@/app/composable';
import { QuestionMarkCircleIcon } from '@vue-hero-icons/solid';
import { PropType, Ref, computed, defineComponent, ref, watch } from '@vue/composition-api';
import ExecutionError from './ExecutionError.vue';
import { useAsset } from '@/modules/asset/composable/asset';

export default defineComponent({
    name: 'ProblematicAssets',
    props: {
        query: { type: Object as PropType<MonitoringExecutionQuery>, required: true },
        blocks: { type: Object as PropType<Record<string, Block>>, required: true },
    },
    components: { TailwindTable, QuestionMarkCircleIcon, ExecutionError, WeatherDynamicIcon },
    setup(props, { root, emit }) {
        const { exec, loading } = useAxios(true);
        const failures: Ref<any> = ref<any>();
        const { assetTypeName } = useAsset();

        const fetchProblematicAssets = async (query: MonitoringExecutionQuery) => {
            await exec(MonitoringAPI.problematicAssets(query))
                .then((res: any) => {
                    failures.value = res.data;
                    emit('update-show-results-warning', res.data.pagination.total >= 1000);
                })
                .catch((e) => {
                    (root as any).$toastr.e(e.response.data.message, 'Error');
                    throw e;
                });
        };

        const columns = [
            {
                key: 'name',
                label: 'Asset',
                type: ColumnType.Object,
                position: ColumnPosition.Left,
                link: (value: any, row: { asset: { id: number } }) => {
                    return row?.asset?.id ? { name: 'assets:view', params: { id: `${row.asset.id}` } } : null;
                },
            },
            {
                key: 'type',
                label: 'Type',
                type: ColumnType.Object,
                position: ColumnPosition.Center,
            },
            {
                key: 'status',
                label: 'Status',
                type: ColumnType.Object,
                position: ColumnPosition.Center,
            },
            {
                key: 'workflowIds',
                label: 'Involved Pipelines',
                type: ColumnType.Array,
            },
            {
                key: 'problematicExecutions',
                label: 'Problematic Executions',
                type: ColumnType.Number,
                position: ColumnPosition.Center,
                sortable: true,
            },
            {
                key: 'errors',
                label: 'Most Common Failure Reason',
                type: ColumnType.Array,
                showAfter: 'xl',
            },
        ];

        const rows = computed(() => failures.value?.results);
        const rejectedItems = computed(() => failures.value?.accessControl?.rejectedItems ?? 0);
        const pagination = computed(() => failures.value?.pagination);

        const changeSort = (sortBy: { field: string; asc: boolean }) => {
            emit('sort', sortBy);
        };

        watch(
            () => props.query,
            (query: MonitoringExecutionQuery, oldQuery: MonitoringExecutionQuery | undefined) => {
                if (!oldQuery || JSON.stringify(oldQuery) !== JSON.stringify(query)) fetchProblematicAssets(query);
            },
            { deep: true, immediate: true },
        );

        watch(
            () => loading.value,
            (l: boolean) => emit('loading', l),
            { immediate: true },
        );

        return {
            rows,
            pagination,
            loading,
            columns,
            rejectedItems,
            R,
            changeSort,
            assetTypeName,
        };
    },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col flex-1 overflow-hidden"},[_c('TailwindTable',{attrs:{"columns":_vm.columns,"rows":_vm.executions.results,"loading":_vm.loading,"sortBy":_vm.query.sortBy,"pagination":_vm.executions.pagination},on:{"change-page":function($event){return _vm.$emit('change-page', $event)},"sort":_vm.changeSort},scopedSlots:_vm._u([{key:"workflow-id",fn:function(ref){
var value = ref.value;
var formattedValue = ref.formattedValue;
return [(value)?[_vm._v(_vm._s(formattedValue))]:_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("-")])]}},{key:"workflow-name",fn:function(ref){
var value = ref.value;
var formattedValue = ref.formattedValue;
return [(value)?[_vm._v(_vm._s(formattedValue))]:_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("Pipeline does not exist")])]}},{key:"createdAt",fn:function(ref){
var formattedValue = ref.formattedValue;
var formattedTooltip = ref.formattedTooltip;
return [_c('div',{staticClass:"w-32 whitespace-nowrap"},[_c('span',{staticClass:"block group-hover:hidden"},[_vm._v(_vm._s(formattedValue))]),_c('span',{staticClass:"hidden group-hover:block"},[_vm._v(_vm._s(formattedTooltip))])])]}},{key:"workflow-name-tooltip",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.workflow.description)+" ")]}},{key:"errors",fn:function(ref){
var value = ref.value;
return [(!value || value.length === 0)?_c('div',{staticClass:"italic text-neutral-600"},[_vm._v("No failures")]):(value.length === 1)?_c('div',{staticClass:"flex flex-row items-center space-x-1"},[_c('ExecutionError',{attrs:{"error":value[0].errorCode}}),_c('QuestionMarkCircleIcon',{staticClass:"w-4 h-4"})],1):_c('div',{staticClass:"flex flex-row items-center space-x-1"},[_c('ExecutionError',{attrs:{"error":value[0].errorCode}}),_c('span',{staticClass:"italic text-neutral-700"},[_vm._v(" (and "+_vm._s(value.length - 1)+" more "+_vm._s(value.length - 1 === 1 ? 'failure' : 'failures')+") ")]),_c('QuestionMarkCircleIcon',{staticClass:"w-4 h-4"})],1)]}},{key:"errors-tooltip",fn:function(ref){
var value = ref.value;
return [(!value || value.length === 0)?_c('div',{staticClass:"italic text-neutral-600"},[_vm._v("No failures")]):(value.length === 1)?_c('div',[_c('ExecutionError',{attrs:{"error":value[0].errorCode,"extended":true,"details":value[0].mappedField}})],1):_c('div',{staticClass:"flex flex-col"},_vm._l((value),function(error,idx){return _c('ExecutionError',{key:((error.errorCode) + "_" + idx),staticClass:"mb-1",attrs:{"error":error.errorCode,"extended":true,"details":error.mappedField}})}),1)]}},{key:"failedBlocks",fn:function(ref){
var row = ref.row;
return [(_vm.failedBlocks(row).length === 0)?_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("No related block")]):_c('div',{staticClass:"flex flex-row items-center space-x-1"},[_c('MostCommonFailedBlock',{attrs:{"block":_vm.failedBlocks(row)[0].id,"category":_vm.failedBlocks(row)[0].category,"occurrences":_vm.failedBlocks(row)[0].count,"totalOccurrences":row.totalFailures}}),_c('QuestionMarkCircleIcon',{staticClass:"w-4 h-4"})],1)]}},{key:"failedBlocks-tooltip",fn:function(ref){
var row = ref.row;
return [(_vm.failedBlocks(row).length === 0)?_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("No related block")]):_c('div',{staticClass:"flex flex-col p-3 space-y-1"},[_c('div',{staticClass:"font-bold"},[_vm._v("Failed blocks")]),_c('ul',{staticClass:"flex flex-col pl-8 space-y-2 list-disc"},_vm._l((_vm.R.min(_vm.failedBlocks(row).length, 10)),function(v,vIdx){return _c('li',{key:_vm.failedBlocks(row)[vIdx].id},[_c('MostCommonFailedBlock',{attrs:{"block":_vm.failedBlocks(row)[vIdx].id,"category":_vm.failedBlocks(row)[vIdx].category,"occurrences":_vm.failedBlocks(row)[vIdx].count,"totalOccurrences":row.totalFailures,"extended":""}})],1)}),0),(_vm.failedBlocks(row).length > 10)?_c('div',{staticClass:"text-sm italic text-neutral-600"},[_vm._v(" More blocks exist... ")]):_vm._e()])]}},{key:"duration",fn:function(ref){
var value = ref.value;
return [(value)?_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_vm._v(" "+_vm._s(_vm.executionTime(value).value.toFixed(2))+" "+_vm._s(_vm.executionTime(value).metric)+" ")]):_c('div',{staticClass:"italic text-neutral-600"},[_vm._v("None available")])]}},{key:"duration-tooltip",fn:function(ref){
var value = ref.value;
return [(value)?_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_vm._v(" It took "+_vm._s(_vm.executionTime(value).value.toFixed(2))+" "+_vm._s(_vm.executionTime(value).metric)+" to run this execution ")]):_c('div',{},[_vm._v("No execution time available")])]}},{key:"footerRow",fn:function(){return [(_vm.rejectedItems)?[_vm._v(" Due to access level limitations, "+_vm._s(_vm.rejectedItems)+" item"+_vm._s(_vm.rejectedItems === 1 ? ' is' : 's are')+" omitted ")]:_vm._e()]},proxy:true},{key:"noRowsMessage",fn:function(){return [(!_vm.rejectedItems)?_c('span',[_vm._v("No data to show")]):_c('span',[_vm._v("Due to access level limitations, "+_vm._s(_vm.rejectedItems)+" item"+_vm._s(_vm.rejectedItems === 1 ? ' is' : 's are')+" omitted")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
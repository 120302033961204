var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col flex-1 overflow-hidden"},[_c('TailwindTable',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"pagination":_vm.pagination,"loading":_vm.loading,"sortBy":_vm.query.sortBy},on:{"change-page":function($event){return _vm.$emit('change-page', $event)},"sort":_vm.changeSort},scopedSlots:_vm._u([{key:"header-asset-tooltip",fn:function(){return [_c('div',{staticClass:"w-64 text-sm"},[_vm._v(" List of assets (results) for which the quality metrics have been collected, i.e. results that have status \"Available\" and their relevant accrual periodicity information has been provided. ")])]},proxy:true},{key:"asset",fn:function(ref){
var value = ref.value;
var row = ref.row;
return [(value)?[_vm._v(_vm._s(value.name))]:_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("Asset "+_vm._s(row.assetId)+" does not exist")])]}},{key:"asset-tooltip",fn:function(ref){
var value = ref.value;
var row = ref.row;
return [(value)?_c('div',{staticClass:"flex flex-col space-y-2"},[_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_c('span',{staticClass:"px-2 pt-1 pb-0.5 text-xs rounded bg-neutral-200 text-neutral-900"},[_vm._v(" ID "),_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(value.id))])]),_c('span',[_vm._v(" "+_vm._s(value.name)+" ")])]),_c('div',{staticClass:"max-w-sm text-xs italic truncate text-neutral-200"},[_vm._v(" "+_vm._s(value.description)+" ")])]):_c('div',{staticClass:"flex flex-col space-y-2"},[_c('div',{staticClass:"max-w-sm text-xs italic truncate text-neutral-200"},[_vm._v(" Asset "+_vm._s(row.assetId)+" does not exist any more ")])])]}},{key:"workflowId",fn:function(ref){
var value = ref.value;
var formattedValue = ref.formattedValue;
return [(value)?[_vm._v(_vm._s(formattedValue))]:_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("-")])]}},{key:"timeliness-icon",fn:function(){return [_c('ExclamationIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: "Timeliness in this view is updated whenever there is an expected execution of any data analytics pipeline. The expected execution of a data analytics pipeline is generally calculated based on the time elapsed since its previous execution, considering the result’s accrual periodicity.",
                    classes: 'max-w-lg break-words',
                }),expression:"{\n                    content: `Timeliness in this view is updated whenever there is an expected execution of any data analytics pipeline. The expected execution of a data analytics pipeline is generally calculated based on the time elapsed since its previous execution, considering the result’s accrual periodicity.`,\n                    classes: 'max-w-lg break-words',\n                }"}],staticClass:"w-4 h-4 text-yellow-500"})]},proxy:true},{key:"timeliness",fn:function(ref){
                var value = ref.value;
return [_c('TimelinessBadge',{attrs:{"value":value,"assetType":_vm.AssetType.Result}})]}},{key:"header-inputAssets-tooltip",fn:function(){return [_c('div',{staticClass:"flex flex-col w-64 space-y-1 text-sm"},[_c('span',{staticClass:"font-bold"},[_vm._v("Input Assets and Timeliness")]),_c('span',[_vm._v(" A list of the input assets (datasets, results) of the corresponding, parent data analytics pipeline (from which the specific result derived), along with their timeliness (that provides an indication whether the input asset is considered as fresh or stale at the moment). ")])])]},proxy:true},{key:"inputAssets",fn:function(ref){
                var value = ref.value;
return [(value.length === 0)?_c('span',{staticClass:"italic text-neutral-500"},[_vm._v("There are no input assets")]):(value.length === 1)?_c('RouterLink',{staticClass:"flex flex-row items-center space-x-1",attrs:{"to":{ name: 'assets:view', params: { id: value[0].assetId } }}},[_c('TimelinessBadge',{attrs:{"value":value[0].timeliness,"showTooltip":false,"assetType":_vm.AssetType.Result,"minimal":""}}),_c('span',{staticClass:"truncate whitespace-nowrap asset-name"},[_vm._v(_vm._s(value[0].asset.name))])],1):_c('span',{staticClass:"flex flex-row items-center space-x-1"},[_c('span',[_vm._v(_vm._s(value.length)+" input assets")])])]}},{key:"inputAssets-tooltip",fn:function(ref){
                var value = ref.value;
return [(value.length === 0)?_c('span',{staticClass:"text-sm text-white"},[_vm._v(" There are no input assets used for the generation of this result ")]):_c('div',{staticClass:"flex flex-col space-y-2"},[_c('span',[_vm._v("Input Assets")]),_c('span',{staticClass:"text-xs text-neutral-500"},[_vm._v("Inputs assets used in the pipeline generating this result asset")]),_c('div',{staticClass:"flex flex-col space-y-1 text-sm text-white"},_vm._l((value),function(assetEntry){return _c('RouterLink',{key:assetEntry.assetId,staticClass:"flex flex-row items-center space-x-2",attrs:{"to":{ name: 'assets:view', params: { id: assetEntry.assetId } }}},[_c('TimelinessBadge',{staticClass:"w-20",attrs:{"value":assetEntry.timeliness,"showTooltip":false,"assetType":_vm.AssetType.Result}}),_c('span',{staticClass:"truncate asset-name-large"},[_vm._v(_vm._s(assetEntry.asset.name))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(assetEntry.isTrigger),expression:"assetEntry.isTrigger"}],staticClass:"text-xs text-neutral-500"},[_vm._v("(used as a triggering asset)")])],1)}),1)])]}},{key:"header-runOnNewData-tooltip",fn:function(){return [_c('div',{staticClass:"flex flex-col w-64 space-y-1 text-sm"},[_c('span',{staticClass:"font-bold"},[_vm._v("Run on new data")]),_c('span',[_vm._v(" An indication whether the corresponding, parent data analytics pipeline (from which the specific result derived) is configured to run only on new data in the settings of its corresponding input task. ")])])]},proxy:true},{key:"runOnNewData",fn:function(ref){
                var value = ref.value;
return [(value)?[_vm._v("Yes")]:[_vm._v("No")]]}},{key:"mode",fn:function(ref){
                var value = ref.value;
return [_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(value))])]}},{key:"lastSuccessfulExecution",fn:function(ref){
                var value = ref.value;
return [(value)?[_vm._v(" "+_vm._s(_vm.dateFromNow(value, true))+" ")]:_c('span',{staticClass:"italic text-neutral-500"},[_vm._v("There was no successful execution")])]}},{key:"lastSuccessfulExecution-tooltip",fn:function(ref){
                var value = ref.value;
return [(value)?[_vm._v(" Last successfully executed on "+_vm._s(_vm.formatDate(value, true, 'DD/MM/YY HH:mm', 'UTC'))+" ")]:_c('span',{staticClass:"italic text-neutral-500"},[_vm._v("There was no successful execution")])]}},{key:"header-triggers-tooltip",fn:function(){return [_c('div',{staticClass:"flex flex-col w-64 space-y-1 text-sm"},[_c('span',{staticClass:"font-bold"},[_vm._v("Run on Trigger related to Input Assets")]),_c('span',[_vm._v(" An indication whether the corresponding, parent data analytics pipeline (from which the specific result derived) is configured to run based on triggers concerning its input assets: in full (for all input assets), partially (for some input assets) or not at all (for none of the input assets). ")])])]},proxy:true},{key:"triggers",fn:function(ref){
                var row = ref.row;
return [_c('div',{staticClass:"flex flex-row items-center space-x-1"},[_c('span',{class:{
                        'text-neutral-500 italic': _vm.triggers[row.assetId].totalAssets === 0,
                        'text-red-700':
                            _vm.triggers[row.assetId].totalAssets > 0 && _vm.triggers[row.assetId].coverage === 'No',
                        'text-primary-700':
                            _vm.triggers[row.assetId].totalAssets > 0 && _vm.triggers[row.assetId].coverage === 'Yes',
                        'text-secondary-700': _vm.triggers[row.assetId].coverage === 'Partially',
                    }},[(_vm.triggers[row.assetId].totalAssets === 0)?[_vm._v("No trigger set on the input assets")]:[_vm._v(" "+_vm._s(_vm.triggers[row.assetId].coverage)+" ")]],2),(_vm.triggers[row.assetId].totalAssets > 0)?_c('span',{staticClass:"text-neutral-600"},[_vm._v(" ("+_vm._s(_vm.triggers[row.assetId].assetsAsTriggers)+"/"+_vm._s(_vm.triggers[row.assetId].totalAssets)+") ")]):_vm._e()])]}},{key:"triggers-tooltip",fn:function(ref){
                    var row = ref.row;
return [(_vm.triggers[row.assetId].totalAssets === 0)?_c('div',[_vm._v(" None of the triggers set, if any, refers to the input assets of the parent data analytics pipeline (from which the specific result derived). ")]):(_vm.triggers[row.assetId].coverage === 'Yes')?_c('div',[(_vm.triggers[row.assetId].totalAssets === 1)?[_vm._v(" The input asset is used as part of at least one trigger to cause the result's pipeline to execute ")]:[_vm._v(" All "+_vm._s(_vm.triggers[row.assetId].totalAssets)+" input assets as part of triggers that cause this result's pipeline to execute ")]],2):(_vm.triggers[row.assetId].coverage === 'No')?_c('div',[(_vm.triggers[row.assetId].totalAssets === 1)?[_vm._v(" The input asset is not used as part of any trigger to cause the result's pipeline to execute ")]:[_vm._v(" None of the "+_vm._s(_vm.triggers[row.assetId].totalAssets)+" input assets are used in triggers that cause this result's pipeline to execute ")]],2):(_vm.triggers[row.assetId].coverage === 'Partially')?_c('div',[_vm._v(" Only "+_vm._s(_vm.triggers[row.assetId].assetsAsTriggers)+" out of "+_vm._s(_vm.triggers[row.assetId].totalAssets)+" input assets are used in triggers that cause this result's pipeline to execute ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
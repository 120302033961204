var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col flex-1 overflow-hidden"},[_c('TailwindTable',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"pagination":_vm.pagination,"loading":_vm.loading,"sortBy":_vm.query.sortBy},on:{"change-page":function($event){return _vm.$emit('change-page', $event)},"sort":_vm.changeSort},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var value = ref.value;
var formattedValue = ref.formattedValue;
return [(value)?_c('div',{staticClass:"truncate"},[_vm._v(_vm._s(formattedValue))]):_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("-")])]}},{key:"name",fn:function(ref){
var value = ref.value;
var formattedValue = ref.formattedValue;
return [(value)?_c('div',{staticClass:"truncate"},[_vm._v(_vm._s(formattedValue))]):_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("Pipeline does not exist")])]}},{key:"name-tooltip",fn:function(ref){
var value = ref.value;
var formattedTooltip = ref.formattedTooltip;
return [(value)?_c('div',{staticClass:"truncate"},[_vm._v(_vm._s(formattedTooltip))]):_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("Pipeline does not exist")])]}},{key:"failedExecutions",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex flex-col items-start space-y-0.5 text-xs"},[_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_c('div',{staticClass:"w-16"},[_vm._v("Failed:")]),_c('div',[_c('span',{class:{
                                'font-bold text-red-700': row.failedExecutions > 0,
                            }},[_vm._v(_vm._s(row.failedExecutions))]),_vm._v(" out of "+_vm._s(row.totalExecutions)+" ")])]),_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_c('div',{staticClass:"w-16"},[_vm._v("Cancelled:")]),_c('div',[_c('span',{class:{
                                'font-bold text-orange-700': row.cancelledExecutions > 0,
                            }},[_vm._v(" "+_vm._s(row.cancelledExecutions)+" ")]),_vm._v(" out of "+_vm._s(row.totalExecutions)+" ")])]),_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_c('div',{staticClass:"w-16"},[_vm._v("Skipped:")]),_c('div',[_c('span',{class:{
                                'font-bold text-yellow-700': row.skippedExecutions > 0,
                            }},[_vm._v(_vm._s(row.skippedExecutions))]),_vm._v(" out of "+_vm._s(row.totalExecutions)+" ")])])])]}},{key:"failedExecutions-tooltip",fn:function(ref){
                            var row = ref.row;
return [_c('div',{staticClass:"flex flex-col items-start space-y-1 text-xs"},[_c('div',{staticClass:"flex flex-row items-center py-2 space-x-2 text-sm"},[_c('WeatherDynamicIcon',{attrs:{"weather":row.failedExecutions / row.totalExecutions}}),_c('div',[_vm._v(" "+_vm._s(row.failedExecutions)+" "+_vm._s(row.failedExecutions === 1 ? 'execution' : 'executions')+" "),_c('span',{staticClass:"text-red-400"},[_vm._v("failed")]),_vm._v(" out of "+_vm._s(row.totalExecutions)+" ")])],1),_c('div',{staticClass:"flex flex-row items-center py-2 space-x-2 text-sm"},[_c('WeatherDynamicIcon',{attrs:{"weather":row.cancelledExecutions / row.totalExecutions}}),_c('div',[_vm._v(" "+_vm._s(row.cancelledExecutions)+" "+_vm._s(row.cancelledExecutions === 1 ? 'execution' : 'executions')+" "),_c('span',{staticClass:"text-orange-800"},[_vm._v("cancelled")]),_vm._v(" out of "+_vm._s(row.totalExecutions)+" ")])],1),_c('div',{staticClass:"flex flex-row items-center py-2 space-x-2 text-sm"},[_c('WeatherDynamicIcon',{attrs:{"weather":row.skippedExecutions / row.totalExecutions}}),_c('div',[_vm._v(" "+_vm._s(row.skippedExecutions)+" "+_vm._s(row.skippedExecutions === 1 ? 'execution' : 'executions')+" "),_c('span',{staticClass:"text-yellow-400"},[_vm._v("skipped")]),_vm._v(" out of "+_vm._s(row.totalExecutions)+" ")])],1)])]}},{key:"totalExecutionsAverageTime",fn:function(ref){
                            var value = ref.value;
return [(value)?_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_vm._v(" "+_vm._s(_vm.executionTime(value).value.toFixed(2))+" "+_vm._s(_vm.executionTime(value).metric)+" ")]):_c('div',{staticClass:"italic text-neutral-600"},[_vm._v("None available")])]}},{key:"totalExecutionsAverageTime-tooltip",fn:function(ref){
                            var value = ref.value;
return [(value)?_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_vm._v(" Execution independent of completion status took on average "+_vm._s(_vm.executionTime(value).value.toFixed(2))+" "+_vm._s(_vm.executionTime(value).metric)+" ")]):_c('div',[_vm._v("No average execution time available")])]}},{key:"failedExecutionsAverageTime",fn:function(ref){
                            var value = ref.value;
return [(value)?_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_vm._v(" "+_vm._s(_vm.executionTime(value).value.toFixed(2))+" "+_vm._s(_vm.executionTime(value).metric)+" ")]):_c('div',{staticClass:"italic text-neutral-600"},[_vm._v("None available")])]}},{key:"failedExecutionsAverageTime-tooltip",fn:function(ref){
                            var value = ref.value;
return [(value)?_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_vm._v(" Execution of failed executions took on average "+_vm._s(_vm.executionTime(value).value.toFixed(2))+" "+_vm._s(_vm.executionTime(value).metric)+" ")]):_c('div',[_vm._v("No average execution time available")])]}},{key:"lastExecution",fn:function(ref){
                            var value = ref.value;
return [(value)?_c('div',{staticClass:"flex flex-row items-center flex-1 space-x-2 whitespace-nowrap",class:{
                    'text-green-700 hover:text-green-800': value.status === _vm.ExecutionStatus.Completed,
                    'text-red-700 hover:text-red-800': value.status === _vm.ExecutionStatus.Failed,
                    'text-yellow-700 hover:text-yellow-800':
                        value.status !== _vm.ExecutionStatus.Completed && value.status !== _vm.ExecutionStatus.Failed,
                }},[(value.status === _vm.ExecutionStatus.Completed)?_c('CheckCircleIcon',{staticClass:"w-6 h-6"}):(value.status === _vm.ExecutionStatus.Failed)?_c('XCircleIcon',{staticClass:"w-6 h-6"}):_c('DotsCircleHorizontalIcon',{staticClass:"w-6 h-6"}),_c('span',[_vm._v(_vm._s(_vm.dateFromNow(value.timestamp, true)))])],1):_vm._e()]}},{key:"lastExecution-tooltip",fn:function(ref){
                var value = ref.value;
return [(value)?_c('div',{staticClass:"flex flex-col space-y-2"},[_c('span',{staticClass:"flex flex-row space-x-1 font-bold capitalize",class:{
                        'text-green-500 ': value.status === _vm.ExecutionStatus.Completed,
                        'text-red-500 ': value.status === _vm.ExecutionStatus.Failed,
                        'text-yellow-500 ':
                            value.status !== _vm.ExecutionStatus.Completed && value.status !== _vm.ExecutionStatus.Failed,
                    }},[(value.status === _vm.ExecutionStatus.Completed)?_c('CheckCircleIcon',{staticClass:"w-6 h-6"}):(value.status === _vm.ExecutionStatus.Failed)?_c('XCircleIcon',{staticClass:"w-6 h-6"}):_c('DotsCircleHorizontalIcon',{staticClass:"w-6 h-6"}),_c('span',[_vm._v(_vm._s(value.status))])],1),_c('span',[_vm._v(_vm._s(_vm.formatDate(value.timestamp, true, 'DD/MM/YY HH:mm', 'UTC')))])]):_vm._e()]}},{key:"errors",fn:function(ref){
                    var value = ref.value;
                    var row = ref.row;
return [(value.length === 0)?_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("No reason")]):_c('div',{staticClass:"flex flex-row items-center space-x-1"},[_c('ExecutionError',{attrs:{"error":value[0].errorCode,"occurrences":value[0].count,"totalOccurrences":row.totalFailures}}),_c('QuestionMarkCircleIcon',{staticClass:"w-4 h-4"})],1)]}},{key:"errors-tooltip",fn:function(ref){
                    var value = ref.value;
                    var row = ref.row;
return [(value.length === 0)?_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("No reasons for failure")]):_c('div',{staticClass:"flex flex-col p-3 space-y-1"},[_c('div',{staticClass:"font-bold"},[_vm._v("Reasons for failure")]),_c('div',{staticClass:"flex flex-col space-y-2"},_vm._l((_vm.R.min(value.length, 10)),function(v,vIdx){return _c('ExecutionError',{key:value[vIdx].errorCode,attrs:{"error":value[vIdx].errorCode,"occurrences":value[vIdx].count,"totalOccurrences":row.totalFailures,"extended":true}})}),1),(value.length > 10)?_c('div',{staticClass:"text-sm italic text-neutral-600"},[_vm._v("More reasons exist...")]):_vm._e()])]}},{key:"failedBlocks",fn:function(ref){
                    var value = ref.value;
                    var row = ref.row;
return [(!value || value.length === 0)?_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("No related block")]):_c('div',{staticClass:"flex flex-row items-center space-x-1"},[_c('MostCommonFailedBlock',{attrs:{"block":value[0].blockId,"occurrences":value[0].count,"totalOccurrences":row.totalFailures}}),_c('QuestionMarkCircleIcon',{staticClass:"w-4 h-4"})],1)]}},{key:"failedBlocks-tooltip",fn:function(ref){
                    var value = ref.value;
                    var row = ref.row;
return [(!value || value.length === 0)?_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("No related block")]):_c('div',{staticClass:"flex flex-col p-3 space-y-1"},[_c('div',{staticClass:"font-bold"},[_vm._v("Failed blocks")]),_c('ul',{staticClass:"flex flex-col pl-8 space-y-2 list-disc"},_vm._l((_vm.R.min(value.length, 10)),function(v,vIdx){return _c('li',{key:value[vIdx].blockId},[_c('MostCommonFailedBlock',{attrs:{"block":value[vIdx].blockId,"occurrences":value[vIdx].count,"totalOccurrences":row.totalFailures,"extended":""}})],1)}),0),(value.length > 10)?_c('div',{staticClass:"text-sm italic text-neutral-600"},[_vm._v("More reasons exist...")]):_vm._e()])]}},{key:"footerRow",fn:function(){return [(_vm.rejectedItems)?[_vm._v(" Due to access level limitations, "+_vm._s(_vm.rejectedItems)+" item"+_vm._s(_vm.rejectedItems === 1 ? ' is' : 's are')+" omitted")]:_vm._e()]},proxy:true},{key:"noRowsMessage",fn:function(){return [(!_vm.rejectedItems)?_c('span',[_vm._v("No data to show")]):_c('span',[_vm._v("Due to access level limitations, "+_vm._s(_vm.rejectedItems)+" item"+_vm._s(_vm.rejectedItems === 1 ? ' is' : 's are')+" omitted")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
































































































































import * as R from 'ramda';
import { MonitoringAPI } from '@/app/api';
import { TailwindTable } from '@/app/components';
import { useAxios, useDuration } from '@/app/composable';
import { ColumnPosition, ColumnType, WorkflowType } from '@/app/constants';
import { MonitoringExecutionQuery } from '@/app/types';
import { QuestionMarkCircleIcon } from '@vue-hero-icons/solid';
import { computed, defineComponent, PropType, ref, Ref, watch } from '@vue/composition-api';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import ExecutionError from './ExecutionError.vue';
import MostCommonFailedBlock from './MostCommonFailedBlock.vue';

dayjs.extend(relativeTime);

export default defineComponent({
    name: 'IncidentsTimeline',
    props: {
        query: { type: Object as PropType<MonitoringExecutionQuery>, required: true },
    },
    components: { TailwindTable, ExecutionError, QuestionMarkCircleIcon, MostCommonFailedBlock },
    setup(props, { emit, root }) {
        const { exec, loading } = useAxios(true);
        const { formatToHighestMetric } = useDuration();

        const executions: Ref<any> = ref<any>({
            results: [],
            pagination: {},
            accessControl: { rejectedItems: 0 },
            deletedPipelines: 0,
        });

        const fetchExecutions = async (query: MonitoringExecutionQuery) => {
            await exec(MonitoringAPI.executions(query, WorkflowType.Analytics))
                .then((res: any) => {
                    executions.value = res.data;
                    emit('update-show-results-warning', res.data.pagination.total >= 10000);
                })
                .catch((e) => {
                    (root as any).$toastr.e(e.response.data.message, 'Error');
                    throw e;
                });
        };

        const columns = computed(() => [
            {
                key: 'createdAt',
                label: 'Created At',
                type: ColumnType.Datetime,
                showAfter: 'sm',
                sortable: { enabled: true, key: 'created_at' },
                formatInUtc: true,
            },
            {
                key: 'workflowId',
                label: 'Pipeline ID',
                type: ColumnType.Uid,
                showAfter: 'sm',
                copy: true,
                link: (value: number, row: any) => {
                    return value
                        ? {
                              name: 'history',
                              params: { workflowId: `${value}` },
                              query: {
                                  executionId: row.executionId,
                              },
                          }
                        : null;
                },
            },
            {
                key: ['workflow', 'name'],
                label: 'Name',
                type: ColumnType.String,
            },
            {
                key: 'errors',
                label: 'Incident Reason',
                type: ColumnType.Array,
            },
            {
                key: 'failedBlocks',
                label: 'Most Common Failed Block',
                type: ColumnType.Array,
                showAfter: 'xl',
                position: ColumnPosition.Center,
            },
            {
                key: 'duration',
                label: 'Execution time',
                type: ColumnType.Integer,
                showAfter: 'xl',
                position: ColumnPosition.Center,
                sortable: true,
            },
        ]);

        const changeSort = (sortBy: { field: string; asc: boolean }) => {
            emit('sort', sortBy);
        };

        const rejectedItems = computed(
            () => executions.value?.accessControl?.rejectedItems ?? 0 + executions.value.deletedPipelines ?? 0,
        );

        const executionTime = (t: number) => formatToHighestMetric(t * 1000);

        const failedBlocks = (row: { errors: { blockId: string; blockCategory: string }[] }) => {
            return Object.values(
                row.errors.reduce(
                    (acc: any, error: { blockId: string | undefined; blockCategory: string | undefined }) => {
                        if (error.blockId) {
                            if (!R.has(error.blockId, acc))
                                acc[error.blockId] = { id: error.blockId, category: error.blockCategory, count: 0 };
                            acc[error.blockId].count += 1;
                        }
                        return acc;
                    },
                    {},
                ),
            );
        };

        watch(
            () => props.query,
            (query: MonitoringExecutionQuery, oldQuery: MonitoringExecutionQuery | undefined) => {
                if (!oldQuery || !R.equals(oldQuery, query)) fetchExecutions(query);
            },
            { deep: true, immediate: true },
        );

        watch(
            () => loading.value,
            (l: boolean) => emit('loading', l),
            { immediate: true },
        );

        return { executions, loading, columns, changeSort, rejectedItems, executionTime, R, failedBlocks };
    },
});












































































































































import * as R from 'ramda';
import { defineComponent, ref, watch, computed } from '@vue/composition-api';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { requiredValidator, maxLengthValidator } from '@/app/validators';
import { TwSelect, TwInput, Toggle, FormModal } from '@/app/components';
import { Pipeline, Task } from '../../types';

extend('required', requiredValidator);
extend('max', maxLengthValidator);

export default defineComponent({
    name: 'TaskPipelinesConfiguration',
    model: {
        prop: 'pipelines',
        event: 'update-pipelines',
    },
    props: {
        task: { type: Object, default: null },
        pipelines: {
            type: Array,
            default: () => [],
        },
        dataframes: {
            type: Object,
            default: () => {
                return { upstream: [], downstream: [] };
            },
        },
        readonly: { type: Boolean, default: false },
        availablePipeline: { type: Object, default: null },
    },
    components: { TwSelect, TwInput, ValidationObserver, ValidationProvider, Toggle, FormModal },
    setup(props, { emit }) {
        const pipeline = ref<Pipeline | null>(null);
        const newPipelineDataframe = ref<string | null>(null);
        const newPipelineName = ref<string | null>(null);
        const newPipelineRef = ref<any>(null);
        const pipelineNameInEdit = ref<string | null>(null);
        const addToPipeline = ref<boolean>(false);
        const oldDataframes = ref<Task[]>(props.dataframes as Task[]);

        const upstreamDataframeIds = computed(() => R.pluck('id', props.dataframes.upstream as Task[]));
        const downstreamDataframeIds = computed(() => R.pluck('id', props.dataframes.downstream as Task[]));

        const removeTaskFromPipelines = () => {
            emit(
                'update-pipelines',
                (props.pipelines as Pipeline[]).reduce((acc: Pipeline[], pl: Pipeline) => {
                    if (pl.tasks.includes(props.task.id)) {
                        const updatedTasks = R.slice(0, pl.tasks.indexOf(props.task.id), pl.tasks);
                        if (updatedTasks.length > 1) {
                            acc.push({ ...pl, tasks: updatedTasks });
                        }
                    } else {
                        acc.push(pl);
                    }
                    return acc;
                }, []),
            );
        };

        const addTaskToPipeline = (newPipeline: Pipeline) => {
            emit(
                'update-pipelines',
                (props.pipelines as Pipeline[]).map((pl: Pipeline) => {
                    if (newPipeline.name === pl.name && !pl.tasks.includes(props.task.id)) {
                        if (
                            upstreamDataframeIds.value.length > 0 &&
                            upstreamDataframeIds.value.includes(R.last(pl.tasks) as string)
                        ) {
                            pl.tasks.push(props.task.id);
                        } else if (
                            downstreamDataframeIds.value.length > 0 &&
                            props.task.downstreamTaskIds.some((dti: string) =>
                                downstreamDataframeIds.value.includes(dti),
                            )
                        ) {
                            pl.tasks.unshift(props.task.id);
                        }
                    }
                    return pl;
                }),
            );
        };

        const clear = () => {
            newPipelineDataframe.value = null;
            newPipelineName.value = null;
            pipeline.value = null;
        };

        const nameExists = computed(
            () =>
                !R.isNil(newPipelineName.value) &&
                (props.pipelines as Pipeline[]).filter((pl: Pipeline) => pl.name === newPipelineName.value).length > 0,
        );

        const invalidTooltip = computed(() => {
            if (R.isNil(pipeline.value) && !R.isNil(newPipelineRef.value) && !newPipelineRef.value.validate()) {
                return 'The define pipeline has some errors';
            }
            if (nameExists.value) {
                return 'Analytics pipeline name provided already exists';
            }

            return null;
        });

        const allDataframes = computed(() => [...props.dataframes.downstream, ...props.dataframes.upstream]);

        const newPipelineDataframeObj = computed(() =>
            newPipelineDataframe.value
                ? allDataframes.value.find((df: Task) => df.id === newPipelineDataframe.value)
                : null,
        );

        const change = () => {
            if ((R.isNil(pipeline.value) && !newPipelineRef.value.validate()) || nameExists.value) {
                emit('pipeline-in-edit');
            } else {
                emit('pipeline-done-edit');
            }
        };

        const findPipeline = (task: Task, pipelines: Pipeline[]) => {
            const foundPipeline = pipelines.find((pl: Pipeline) => pl.tasks.includes(task.id));
            return foundPipeline || null;
        };

        const toggleChange = (newValue: boolean) => {
            addToPipeline.value = newValue;
            if (newValue && !R.isNil(props.availablePipeline)) {
                addTaskToPipeline(props.availablePipeline as Pipeline);
            } else if (
                newValue &&
                (R.isNil(pipeline) || (R.isNil(newPipelineName) && R.isNil(newPipelineDataframe.value)))
            ) {
                emit('pipeline-in-edit');
            } else if (!newValue && !R.isNil(pipeline.value)) {
                removeTaskFromPipelines();
            }
        };

        const saveNewPipeline = () => {
            if (!R.isNil(newPipelineName.value) && newPipelineRef.value.validate()) {
                if (upstreamDataframeIds.value.includes(newPipelineDataframe.value as string)) {
                    emit('update-pipelines', [
                        ...props.pipelines,
                        {
                            name: newPipelineName.value.trim(),
                            type: R.intersection(
                                newPipelineDataframeObj.value.block.supportedPipelines,
                                props.task.block.supportedPipelines,
                            )[0],
                            tasks: [newPipelineDataframe.value, props.task.id],
                        },
                    ]);
                } else if (downstreamDataframeIds.value.includes(newPipelineDataframe.value as string)) {
                    emit('update-pipelines', [
                        ...props.pipelines,

                        {
                            name: newPipelineName.value.trim(),
                            type: R.intersection(
                                newPipelineDataframeObj.value.block.supportedPipelines,
                                props.task.block.supportedPipelines,
                            )[0],
                            tasks: [props.task.id, newPipelineDataframe.value],
                        },
                    ]);
                }

                newPipelineDataframe.value = null;
                newPipelineName.value = null;
                emit('pipeline-done-edit');
            }
        };
        const renamePipeline = () => {
            emit(
                'update-pipelines',
                (props.pipelines as Pipeline[]).map((pl: Pipeline) => {
                    if (pipeline.value && pl.name === pipeline.value.name) {
                        return {
                            ...pl,
                            name: pipelineNameInEdit.value,
                        };
                    }
                    return pl;
                }),
            );
            pipelineNameInEdit.value = null;
        };

        watch(
            () => props.pipelines,
            (newPipelines: any[]) => {
                const foundPipeline = findPipeline(props.task as Task, newPipelines);
                if (!R.isNil(foundPipeline)) {
                    pipeline.value = foundPipeline;
                } else {
                    pipeline.value = null;
                }
            },
        );

        watch(
            () => props.task,
            (newTask: any) => {
                const foundPipeline = findPipeline(newTask, props.pipelines as Pipeline[]);
                if (!R.isNil(foundPipeline)) {
                    pipeline.value = foundPipeline;
                } else {
                    pipeline.value = null;
                }
            },
        );

        watch(
            () => allDataframes.value,
            (dataframes: any) => {
                const foundPipeline = findPipeline(props.task as Task, props.pipelines as Pipeline[]);
                if (
                    !R.isNil(foundPipeline) &&
                    dataframes.filter((t: Task) => foundPipeline.tasks.includes(t.id)).length === 0
                ) {
                    removeTaskFromPipelines();
                } else if (foundPipeline) {
                    addTaskToPipeline(foundPipeline);
                } else if (
                    JSON.stringify(oldDataframes.value) !== JSON.stringify(props.dataframes) &&
                    !R.isNil(props.availablePipeline)
                ) {
                    addToPipeline.value = true;
                    addTaskToPipeline(props.availablePipeline as Pipeline);
                }
            },
        );

        watch(
            () => props.availablePipeline,
            () => {
                const foundPipeline = findPipeline(props.task as Task, props.pipelines as Pipeline[]);
                if (
                    !R.isNil(foundPipeline) &&
                    allDataframes.value.filter((t: Task) => foundPipeline.tasks.includes(t.id)).length === 0
                ) {
                    removeTaskFromPipelines();
                } else if (foundPipeline) {
                    addTaskToPipeline(foundPipeline);
                }
            },
        );

        return {
            pipeline,
            newPipelineDataframe,
            newPipelineName,
            newPipelineRef,
            change,
            toggleChange,
            saveNewPipeline,
            clear,
            nameExists,
            addToPipeline,
            invalidTooltip,
            pipelineNameInEdit,
            renamePipeline,
            allDataframes,
        };
    },
});


















































































import * as R from 'ramda';
import { computed, defineComponent } from '@vue/composition-api';
import { PlusIcon } from '@vue-hero-icons/outline';
import { QueryBuilder } from '@/app/components';
import FilterEditor from './FilterEditor.vue';
import FilterViewer from './FilterViewer.vue';

export default defineComponent({
    name: 'Filters',
    model: { prop: 'filters', event: 'change' },
    props: {
        filters: {
            type: Object,
            default: () => {
                return {
                    operator: 'AND',
                    conditions: [],
                };
            },
        },
        fields: {
            type: Array,
            default: null,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        specificField: {
            type: String,
            default: null,
        },
        aggregator: {
            type: String,
            default: null,
        },
    },
    components: { QueryBuilder, FilterEditor, FilterViewer, PlusIcon },
    setup(props, { emit }) {
        const editableFilters = computed({
            get: () => props.filters,
            set: (newFilters: any) => {
                emit('change', newFilters);
            },
        });

        const newConditionTemplate = computed(() => {
            if (!R.isNil(props.specificField)) {
                return {
                    operator: null,
                    type: null,
                    value: null,
                };
            }

            return {
                field: null,
                operator: null,
                type: null,
                value: null,
            };
        });

        const themeCalculator = (query: any) => {
            switch (query.operator) {
                case 'AND':
                    return 'purple';
                case 'OR':
                    return 'teal';
                default:
                    return null;
            }
        };

        const updateConditions = (query: any, newConditions: any[], replace = false) => {
            if (replace) {
                return {
                    ...query,
                    conditions: newConditions,
                };
            }
            return {
                ...query,
                conditions: [...query.conditions, ...newConditions],
            };
        };

        return { newConditionTemplate, updateConditions, themeCalculator, editableFilters };
    },
});

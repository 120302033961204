























import { useExecutionErrors } from '@/app/composable';
import { computed, defineComponent } from '@vue/composition-api';
import { useBlocks } from '../../composable';

export default defineComponent({
    name: 'MostCommonFailedBlock',
    props: {
        block: { type: String, required: true },
        category: { type: String, required: false },
        occurrences: { type: Number, required: false },
        totalOccurrences: { type: Number, required: false },
        extended: { type: Boolean, default: false },
    },
    setup(props) {
        const { errorMessage, errorOccurrencePercentage } = useExecutionErrors();
        const { blocksMap: blocks } = useBlocks();

        const blockName = computed(() => {
            const blockObj = blocks.value[props.block];
            return blockObj ? blockObj.name : props.block;
        });

        return { blockName, errorMessage, errorOccurrencePercentage };
    },
});

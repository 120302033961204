import * as R from 'ramda';
import {
    AggregationFunction,
    ApexChartBorderType,
    ApexChartColors,
    ApexChartCrosshairsFillType,
    ApexChartdecimalsInFloat,
    ApexChartFontFamily,
    ApexChartLegendPosition,
    ApexChartLineCap,
    ApexChartLineCurve,
    ApexChartMarkersShape,
    ApexChartMessages,
    ApexChartPositioning,
    ApexChartTextAlign,
    ApexChartType,
    ApexChartXAxisValidValues,
    ChartConfigType,
} from '../../constants';

export abstract class Chart {
    protected abstract chartType: ApexChartType;

    protected abstract description: string;

    protected iAmPie = false;

    protected validDataTypes: string[] = [];

    protected aggregationMaxFields = 1;

    protected icon: any = null;

    protected chartConfigurationSections: ChartConfigType[] = [
        ChartConfigType.General,
        ChartConfigType.YAxis,
        ChartConfigType.XAxis,
    ];

    protected xAxisTypes: {
        numeric?: readonly string[];
        category?: readonly string[];
        datetime?: readonly string[];
    } = ApexChartXAxisValidValues;

    protected aggregationFunctions: AggregationFunction[] = [
        AggregationFunction.AVG,
        AggregationFunction.COUNT,
        AggregationFunction.MAX,
        AggregationFunction.MIN,
        AggregationFunction.SUM,
    ];

    protected showLabel = false;

    protected showTotal = false;

    private static charts: Chart[] = [];

    protected static palette = [
        '#69ef7b',
        '#18857f',
        '#20d8fd',
        '#40527a',
        '#58a2ee',
        '#5979fe',
        '#fbacf6',
        '#905595',
        '#c551dc',
        '#1932bf',
        '#b8deb7',
        '#428621',
        '#b1d34f',
        '#76480d',
        '#f5a683',
        '#a3162e',
        '#ef6268',
        '#f79302',
        '#fad139',
        '#fe5900',
    ];

    protected static defaultConfiguration(title: string, description: string): any {
        return {
            colors: this.palette,
            title: {
                text: title,
                // margin: 10, //Margins create a line when exporting
                align: ApexChartTextAlign.LEFT,
                style: {
                    fontSize: 22,
                    fontWeight: '500',
                    fontFamily: ApexChartFontFamily.DEFAULT,
                    color: ApexChartColors.Text,
                },
            },
            subtitle: {
                text: description,
                // offsetY: -5,
                // margin: 80,
                align: ApexChartTextAlign.LEFT,
                style: {
                    fontSize: 14,
                    fontWeight: '200',
                    fontFamily: ApexChartFontFamily.DEFAULT,
                    color: ApexChartColors.Text,
                },
            },
            noData: {
                text: ApexChartMessages.NO_DATA,
            },
        };
    }

    // https://apexcharts.com/docs/options/legend/
    protected static defaultLegend = {
        legend: {
            show: true,
            showForSingleSeries: true,
            showForNullSeries: false,
            showForZeroSeries: false,
            position: ApexChartLegendPosition.Top,
            horizontalAlign: 'center',
            floating: false,
            fontSize: 14,
            fontFamily: ApexChartFontFamily.DEFAULT,
            fontWeight: 400,
            inverseOrder: false,
            offsetX: 0,
            offsetY: 0,
            labels: {
                useSeriesColors: false,
            },
            markers: {
                width: 14,
                height: 14,
                strokeWidth: 0,
                strokeColor: ApexChartColors.MarkersStroke,
                radius: 12,
                offsetX: 0,
                offsetY: 0,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 15,
            },
            onItemClick: {
                toggleDataSeries: true,
            },
            onItemHover: {
                highlightDataSeries: true,
            },
        },
    };

    // https://apexcharts.com/docs/options/datalabels/
    protected static defaultDataLabels = {
        dataLabels: {
            enabled: false,
            formatter: function (val: number, opts: any) {
                const decimals = opts.w?.config?.yaxis[0]?.decimalsInFloat;
                if (val && Number.isInteger(val)) {
                    return val;
                }
                const formattedValue = val?.toFixed(decimals || 0);
                return formattedValue || val;
            },
        },
    };

    // https://apexcharts.com/docs/options/stroke/
    protected static defaultStroke = {
        stroke: {
            show: true,
            curve: ApexChartLineCurve.STRAIGHT,
            lineCap: ApexChartLineCap.BUTT,
            colors: undefined,
            width: 2,
            dashArray: 0,
        },
    };

    // https://apexcharts.com/docs/options/chart/
    protected static defaultChartOption = {
        chart: {
            background: ApexChartColors.BackGround,
            toolbar: {
                show: false,
            },
            // Aimations: https://apexcharts.com/docs/options/chart/animations/
            animations: {
                enabled: false, // Default is true
            },
            redrawOnParentResize: true,
            redrawOnWindowResize: true,
        },
    };

    // Chart/Selection (Only in numeric x-axis) https://apexcharts.com/docs/options/chart/selection/
    protected static defaultChartSelection = {
        selection: {
            enabled: true,
            type: 'x',
            fill: {
                color: ApexChartColors.Selection,
                opacity: 0.1,
            },
            stroke: {
                width: 1,
                dashArray: 3,
                color: ApexChartColors.Selection,
                opacity: 0.4,
            },
            xaxis: {
                min: undefined,
                max: undefined,
            },
            yaxis: {
                min: undefined,
                max: undefined,
            },
        },
    };

    // https://apexcharts.com/docs/options/yaxis/
    protected static defaultYAxis = {
        yaxis: {
            show: true,
            showAlways: true,
            showForNullSeries: true,
            opposite: false,
            reversed: false,
            logarithmic: false,
            forceNiceScale: true,
            floating: false,
            decimalsInFloat: ApexChartdecimalsInFloat.DEFAULT,
            labels: {
                show: true,
                align: ApexChartTextAlign.CENTER,
                //minWidth: 20,
                style: {
                    fontSize: '14px',
                    fontFamily: ApexChartFontFamily.DEFAULT,
                    fontWeight: 400,
                    cssClass: 'apexcharts-yaxis-label',
                },
                offsetX: -8,
                offsetY: 0,
                rotate: 0,
            },
            axisBorder: {
                show: true,
                color: ApexChartColors.AxisBorder,
                offsetX: -5,
                offsetY: 0,
            },
            axisTicks: {
                show: false,
                borderType: ApexChartBorderType.SOLID,
                color: ApexChartColors.AxisTicks,
                width: 6,
                offsetX: 4,
                offsetY: 1,
            },
            title: {
                text: 'Y-Axis title',
                rotate: -90,
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontSize: 16,
                    fontFamily: ApexChartFontFamily.DEFAULT,
                    fontWeight: 600,
                    cssClass: 'apexcharts-yaxis-title',
                },
            },
            crosshairs: {
                show: false,
                position: ApexChartPositioning.BACK,
                stroke: {
                    color: ApexChartColors.Crosshairs,
                    width: 1,
                    dashArray: 0,
                },
            },
            tooltip: {
                enabled: false,
                offsetX: 0,
            },
        },
    };

    // https://apexcharts.com/docs/options/xaxis/
    protected static defaultXAxis = {
        xaxis: {
            type: '',
            decimalsInFloat: ApexChartdecimalsInFloat.DEFAULT,
            tickAmount: 'dataPoints',
            labels: {
                show: true,
                rotate: -45,
                rotateAlways: false,
                hideOverlappingLabels: true,
                showDuplicates: false,
                trim: false,
                maxHeight: 120,
                style: {
                    colors: [],
                    fontSize: '12px',
                    fontFamily: ApexChartFontFamily.DEFAULT,
                    fontWeight: 400,
                    cssClass: 'apexcharts-xaxis-label',
                },
                offsetX: 0,
                offsetY: 0,
                datetimeUTC: false,
                datetimeFormatter: {
                    year: 'yyyy',
                    month: "MMM 'yy",
                    day: 'dd MMM',
                    hour: 'HH:mm',
                },
            },
            axisBorder: {
                show: true,
                color: ApexChartColors.AxisBorder,
                height: 1,
                width: '100%',
                offsetX: 0,
                offsetY: 0,
            },
            axisTicks: {
                show: false,
                borderType: ApexChartBorderType.SOLID,
                color: ApexChartColors.AxisTicks,
                height: 6,
                offsetX: 0,
                offsetY: 0,
            },
            floating: false,
            position: 'bottom',
            title: {
                text: 'X-Axis title',
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontSize: 16,
                    fontFamily: ApexChartFontFamily.DEFAULT,
                    fontWeight: 600,
                    cssClass: 'apexcharts-xaxis-title',
                },
            },
            crosshairs: {
                show: false,
                width: 1,
                position: ApexChartPositioning.BACK,
                opacity: 0.9,
                stroke: {
                    color: ApexChartColors.Crosshairs,
                    width: 1,
                    dashArray: 0,
                },
                fill: {
                    type: ApexChartCrosshairsFillType.SOLID,
                    color: ApexChartColors.CrosshairsFill,
                    gradient: {
                        colorFrom: ApexChartColors.CrosshairsGradientFrom,
                        colorTo: ApexChartColors.CrosshairsGradientTo,
                        stops: [0, 100],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                    },
                },
                dropShadow: {
                    enabled: false,
                    top: 0,
                    left: 0,
                    blur: 1,
                    opacity: 0.4,
                },
            },
            tooltip: {
                enabled: false,
                offsetY: 0,
                style: {
                    fontSize: 12,
                },
            },
        },
    };

    // https://apexcharts.com/docs/options/grid/
    protected static defaultGrid = {
        grid: {
            show: true,
            strokeDashArray: 0,
            position: ApexChartPositioning.FRONT,
            xaxis: {
                lines: {
                    show: true,
                },
            },
            yaxis: {
                lines: {
                    show: true,
                },
            },
            row: {
                opacity: 0.4,
            },
            column: {
                opacity: 0.4,
            },
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
        },
    };

    // https://apexcharts.com/docs/options/tooltip/
    protected static defaultTooltip = {
        tooltip: {
            enabled: true,
            shared: true,
            followCursor: false,
            intersect: false,
            inverseOrder: false,
            fillSeriesColor: false,
            style: {
                fontSize: '12px',
                fontFamily: ApexChartFontFamily.DEFAULT,
            },
            onDatasetHover: {
                highlightDataSeries: false,
            },
            x: {
                show: true,
            },
            marker: {
                show: true,
            },
            items: {
                display: 'flex',
            },
            fixed: {
                enabled: false,
                position: 'topRight',
                offsetX: 0,
                offsetY: 0,
            },
        },
    };

    // https://apexcharts.com/docs/options/markers/
    protected static defaultMarkers = {
        markers: {
            size: 5,
            colors: Chart.palette,
            strokeColors: Chart.palette,
            strokeWidth: 1,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [],
            shape: ApexChartMarkersShape.CIRCLE,
            radius: 2,
            offsetX: 0,
            offsetY: 0,
            showNullDataPoints: true,
            hover: {
                size: undefined,
                sizeOffset: 3,
            },
        },
    };

    static register(chart: Chart) {
        Chart.charts.push(chart);
    }

    static findChart(chartType: ApexChartType): Chart {
        const foundChart = this.charts.find((chart: Chart) => chart.chartType === chartType);
        if (R.isNil(foundChart)) {
            throw Error(`Chart of type ${chartType} not found`);
        }
        return foundChart;
    }

    static getAvailableCharts(searchText?: string): Chart[] {
        return Chart.charts.filter(
            (chart: Chart) =>
                chart.isEnabled() &&
                (R.isNil(searchText) || R.isEmpty(searchText) || chart.containedInName(searchText.toLowerCase())),
        );
    }

    static getPalette(): any {
        return this.palette;
    }

    validXaxisTypes(): any {
        return this.xAxisTypes;
    }

    configuration(title: string | null | undefined): any {
        return Chart.defaultConfiguration(
            R.isNil(title) ? 'Title' : title,
            R.isNil(this.description) ? 'Description' : this.description,
        );
    }

    isEnabled(): boolean {
        return this.chartType ? Object.values(ApexChartType).includes(this.chartType) : false;
    }

    isChartType(chartType: ApexChartType): boolean {
        return !R.isNil(this.chartType) && this.chartType === chartType;
    }

    isLabelShown(): boolean {
        return this.showLabel;
    }

    isTotalShown(): boolean {
        return this.showTotal;
    }

    hasSeriesConfigurationSection(): boolean {
        return this.chartConfigurationSections.includes(ChartConfigType.Series);
    }

    hasXAxisConfigurationSection(): boolean {
        return this.chartConfigurationSections.includes(ChartConfigType.XAxis);
    }

    hasYAxisConfigurationSection(): boolean {
        return this.chartConfigurationSections.includes(ChartConfigType.YAxis);
    }

    getLabel(): string {
        return this.chartType;
    }

    getDescription(): string {
        return this.description;
    }

    getChartType(): ApexChartType {
        return this.chartType;
    }

    getIcon(): any {
        return this.icon;
    }

    containedInName(searchText: string): boolean {
        return !!this.chartType && this.chartType?.toLowerCase().includes(searchText.toLowerCase());
    }

    iBelongInThePieFamily(): boolean {
        return this.iAmPie;
    }

    getConfigurationSections(): ChartConfigType[] {
        return this.chartConfigurationSections;
    }

    getAggregationFunctions(): AggregationFunction[] {
        return this.aggregationFunctions;
    }

    // eslint-disable-next-line class-methods-use-this
    yAxisSeriesOptions(structure: any): any[] {
        const temp: any[] = [];
        Object.keys(structure).forEach((key) => {
            // ApexChartValidValues are all valid value types that can be visualized
            if (['int', 'double'].indexOf(structure[key]) !== -1) {
                temp.push({ label: key, value: structure[key] });
            }
        });

        return temp;
    }

    xAxisSeriesOptions(structure: any, xAxisSeriesType: string): any[] {
        const temp: Array<any> = [];
        // Once user selects series type for x axis,filter series options
        Object.keys(structure).forEach((key) => {
            if (this.validDataTypes.length > 0 && this.validDataTypes.includes(structure[key])) {
                temp.push({ label: key, value: structure[key] });
            } else if (xAxisSeriesType && ApexChartXAxisValidValues[xAxisSeriesType].indexOf(structure[key]) !== -1) {
                temp.push({ label: key, value: structure[key] });
            }
        });
        return temp;
    }
}

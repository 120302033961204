



















import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import { SearchIcon } from '@vue-hero-icons/outline';
import { Chart } from '../../types';
import ResultsViewChart from './ResultsViewChart.vue';
import { Scrollbar } from '@/app/components';

export default defineComponent({
    name: 'ChartTypes',
    model: { prop: 'selected', event: 'selected' },
    props: {
        selected: { type: Object as PropType<Chart>, required: false },
        existing: { type: Boolean, default: false },
    },
    components: { ResultsViewChart, SearchIcon, Scrollbar },
    setup() {
        const searchText = ref<string>('');
        const chartTypes = computed(() => Chart.getAvailableCharts(searchText.value));

        return { searchText, chartTypes };
    },
});

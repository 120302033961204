




















































































































import { S } from '@/app/utilities';
import { computed, defineComponent, PropType } from '@vue/composition-api';
import * as R from 'ramda';
import { XCircleIcon, ExclamationIcon } from '@vue-hero-icons/solid';
import { BlockCategory, BlockCategoryWrapper, BlockType, ExecutionType, ExecutionTypeWrapper } from '../../constants';

export default defineComponent({
    name: 'TableViewTask',
    props: {
        task: {
            type: Object,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        highlighted: {
            type: Boolean,
            default: true,
        },
        columns: {
            type: Object,
        },
        runningExecution: {
            type: Object,
            default: null,
        },
        validationInfo: {
            type: Object as PropType<{ isValid: boolean; message?: string; isMajor?: boolean }>,
            required: true,
        },
        failureMessage: { type: String },
    },
    components: { XCircleIcon, ExclamationIcon },
    setup(props, { emit }) {
        const blockCategory = computed(() => BlockCategoryWrapper.find(props.task.block.category as BlockCategory));
        const blockType = computed(() => {
            if (props.task.block.type) {
                return props.task.block.type;
            }
            return null;
        });

        const label = computed(() => {
            if (blockType.value !== null) {
                return `${blockCategory.value?.label} (${blockType.value})`;
            }
            return blockCategory.value?.label;
        });

        const colour = computed(() => blockCategory.value?.colour);
        const iconHtml = computed(() => {
            return blockCategory.value?.iconHtml;
        });

        const taskRunningDryRun = computed(
            () =>
                props.runningExecution &&
                props.runningExecution.task &&
                props.runningExecution.task.id === props.task.id &&
                props.runningExecution.type === ExecutionType.Dry,
        );
        const taskRunningTestRun = computed(
            () =>
                props.runningExecution &&
                props.runningExecution.task &&
                props.runningExecution.task.id === props.task.id &&
                props.runningExecution.type === ExecutionType.Test,
        );

        const runningExecutionTooltip = computed(() => {
            if (!R.isNil(props.runningExecution)) {
                const executionType = ExecutionTypeWrapper.find(props.runningExecution.type);
                return executionType?.message(props.runningExecution.status, props.runningExecution.task).message;
            }
            return null;
        });

        const numberOfColumns = computed(() => (!R.isNil(props.columns) ? Object.keys(props.columns).length : null));

        const hideStructureInformation = computed(
            () =>
                props.task.block.category === BlockCategory.Output ||
                (props.task.block.category === BlockCategory.MachineLearning &&
                    props.task.block.type === BlockType.Train),
        );

        const selectTask = () => {
            emit('select', props.task);
        };

        return {
            label,
            colour,
            iconHtml,
            blockCategory,
            numberOfColumns,
            hideStructureInformation,
            runningExecutionTooltip,
            taskRunningDryRun,
            taskRunningTestRun,
            S,
            selectTask,
        };
    },
});

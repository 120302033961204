










import { defineComponent, computed } from '@vue/composition-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { QueryOperant } from '@/app/constants';
import { DataType } from '@/modules/workflow-designer/constants';

dayjs.extend(utc);

export default defineComponent({
    name: 'FilterViewer',
    props: {
        query: {
            type: Object,
            required: true,
        },
        fields: {
            type: Array,
            required: true,
        },
        datetimeDisplayFormat: {
            type: String,
            default: 'DD MMMM YYYY HH:mm',
        },
        specificField: { type: String, default: null },
        aggregator: { type: String, default: null },
    },
    setup(props) {
        const showField = computed(() => !props.specificField);

        const findField = (fieldName: string) => {
            const foundField = (props.fields as any).find(
                (f: { label: string; value: string }) => f.label === fieldName,
            );
            if (!foundField) throw Error(`Field "${fieldName}" not found in structure.`);

            return foundField;
        };

        const field = computed(() => {
            let fieldName: string | null = null;
            if (props.specificField) {
                fieldName = props.specificField;
            } else if (props.query.field) {
                fieldName = props.query.field;
            }

            if (fieldName) {
                return findField(fieldName);
            }
            return null;
        });

        const fieldType = computed((): DataType | null => {
            if (field.value) return field.value.value;
            return null;
        });

        const value = computed(() => {
            if (fieldType.value === DataType.DateTime) {
                return dayjs.utc(props.query.value).format(`${props.datetimeDisplayFormat} UTC`);
            }

            return props.query.value;
        });

        const operatorName = computed(() => QueryOperant.findBySymbol(props.query.operator)?.name);

        return { operatorName, field, value, showField };
    },
});

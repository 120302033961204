


















































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'LibraryBlock',
    props: {
        block: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: {},
    setup(props: any, { emit }: { emit: any }) {
        const addBlock = () => {
            emit('create-task', props.block);
        };

        return {
            addBlock,
        };
    },
});













































































import { CopyToClipboardButton } from '@/app/components';
import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'WorklfowAPITriggerInstructions',
    components: {
        CopyToClipboardButton,
    },
    props: {
        uid: { type: String, required: true },
    },
    setup(props) {
        const placeholder = '<YOUR_EXECUTION_ID>';
        const postUrl = computed(() => `${process.env.VUE_APP_BACKEND_URL}/api/pipeline-trigger/${props.uid}/execute`);
        const monitoringUrl = computed(
            () =>
                `${process.env.VUE_APP_BACKEND_URL}/api/monitoring/executions/analytics/${props.uid}/execution/${placeholder}/status`,
        );

        return { postUrl, monitoringUrl, placeholder };
    },
});





















































































































































import { defineComponent, computed, PropType, ref, watch } from '@vue/composition-api';
import { ValidationProvider, extend } from 'vee-validate';
import { v4 as uuidv4 } from 'uuid';
import { minValueValidator, requiredValidator } from '@/app/validators';
import { TwSelect, Toggle } from '@/app/components';
import { useVisualisation } from '@/modules/workflow-designer/composable';
import { Chart } from '@/modules/workflow-designer/types';
import {
    AggregationFunction,
    VisualisationOptionsDescription,
    ApexChartXAxisValidValues,
    ApexChartType,
} from '@/modules/workflow-designer/constants';
import { isNil } from 'ramda';
import ChartConfigurationInput from './ChartConfigurationInput.vue';

extend('required', requiredValidator);
extend('min_value', minValueValidator);

export default defineComponent({
    name: 'ChartConfigurationXAxisSeriesInput',
    model: {
        prop: 'chartConfig',
        event: 'update-chart-series',
    },
    props: {
        chart: {
            type: Object as PropType<Chart>,
            required: true,
        },
        chartConfig: {
            type: Object,
            required: true,
        },
        availableSeries: {
            type: Array,
            default: () => [],
        },
        xAxisSeriesOptions: {
            type: Array,
            default: () => [],
        },
        showSeriesName: {
            type: Boolean,
            default: false,
        },
        seriesType: {
            type: String,
            required: true,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        validationRules: {
            type: Object,
        },
    },
    components: {
        TwSelect,
        ValidationProvider,
        ChartConfigurationInput,
        Toggle,
    },
    setup(props, { emit }) {
        const editedChartSeries = computed({
            get: () => props.chartConfig,
            set: (updatedChartSeries: any) => {
                emit('update-chart-series', updatedChartSeries);
            },
        });

        const enableBucketing = ref<boolean>(!!editedChartSeries.value?.groupBy?.buckets?.binSize);
        const enableInterval = ref<boolean>(!!editedChartSeries.value?.groupBy?.buckets?.interval);

        const selectInputUuid = `field_${uuidv4()}`;

        const { addSeriesBuckets, getBucketUnitOptions } = useVisualisation();

        const aggregationFunctionsOptions = computed(() =>
            props.chart
                ? props.chart.getAggregationFunctions().map((func: AggregationFunction) => {
                      return {
                          label: func.label,
                          value: func.key,
                      };
                  })
                : [],
        );

        const handleInputChange = (value: any) => {
            const multipleFields: boolean = value.length > 1;
            const selectedOption: any = multipleFields
                ? null
                : props.availableSeries?.find((x: any) => x.label === value[0]) || null;
            const dataType: any = selectedOption?.value || null;
            const dataTypeHasChanged: boolean = editedChartSeries.value.groupBy.type !== dataType;

            // For multiple groupBy fields, data type is by default category. Buckets are not available for multiple values
            // Change buckets if data type has changed or return null for multiple groupBy fields
            editedChartSeries.value = {
                ...editedChartSeries.value,
                groupBy: {
                    ...editedChartSeries.value.groupBy,
                    fields: value,
                    type: multipleFields ? 'category' : dataType,
                    buckets: dataTypeHasChanged ? null : editedChartSeries.value.groupBy.buckets,
                },
            };
        };

        const bucketUnitOptions = computed((): any => getBucketUnitOptions());

        const currentDataType = computed(() => {
            const dataTypeSelected = editedChartSeries.value.groupBy.type || '';
            if (ApexChartXAxisValidValues.numeric.includes(dataTypeSelected)) {
                return 'numeric';
            }
            if (ApexChartXAxisValidValues.datetime.includes(dataTypeSelected)) {
                return 'datetime';
            }
            if (ApexChartXAxisValidValues.category.includes(dataTypeSelected)) {
                return 'category';
            }
            return dataTypeSelected;
        });

        // Toggle function is common for all series type (numeric, category, datetime),
        // as we add series bucket options according to the datatype selected
        const handleToggle = (enable: boolean) => {
            editedChartSeries.value = {
                ...editedChartSeries.value,
                groupBy: {
                    ...editedChartSeries.value.groupBy,
                    buckets: enable ? addSeriesBuckets(currentDataType.value) : null,
                },
            };
        };

        // Bucketing is available for numeric and datetime series field data types. Scatter chart type doesn't need bucketing
        const bucketType = computed(() => {
            if (editedChartSeries.value.groupBy.fields.length && props.chart.getChartType() !== ApexChartType.Scatter) {
                if ([props.seriesType, currentDataType.value].includes('datetime')) {
                    return 'datetime';
                }
                if ([props.seriesType, currentDataType.value].includes('numeric')) {
                    return 'numeric';
                }
            }
            return '';
        });

        const disableIntervalMessage = computed(() => {
            if (props.readOnly) return 'Editing is disabled';
            if (props.chartConfig.groupBy.fields.length > 1)
                return 'Interval is not available if more than 1 fields are selected';
            return undefined;
        });

        watch(
            () => [enableInterval.value, props.chartConfig.groupBy.fields],
            ([newEnableInterval, newGroupByFields]: any[]) => {
                if (newGroupByFields.length > 1 && newEnableInterval) enableInterval.value = false;
            },
            { deep: true },
        );

        return {
            editedChartSeries,
            handleInputChange,
            aggregationFunctionsOptions,
            bucketUnitOptions,
            selectInputUuid,
            VisualisationOptionsDescription,
            currentDataType,
            handleToggle,
            enableBucketing,
            enableInterval,
            bucketType,
            disableIntervalMessage,
            isNil,
        };
    },
});




































































































































































import { ExclamationCircleIcon } from '@vue-hero-icons/outline';
import { ConfirmModal, GeneralAlert, Scrollbar } from '@/app/components';
import { computed, defineComponent, ref, Ref, watch } from '@vue/composition-api';
import { ChevronLeftIcon, CollectionIcon, ChevronRightIcon, XIcon } from '@vue-hero-icons/outline';
import * as R from 'ramda';
import { ChartTypes, ResultsViewMain, ResultsViewOptions } from '.';
import { useVisualisation } from '../../composable';
import {
    ApexChartType,
    BlockCategory,
    ExecutionStatus,
    ExecutionType,
    VisualisationType,
    WarningMessages,
} from '../../constants';
import { Chart, ChartConfig, Execution, Task, Visualisation } from '../../types';
import ChartConfiguration from './chart-configuration/ChartConfiguration.vue';
import { useQueryParams } from '@/app/composable';

export default defineComponent({
    name: 'ResultsView',
    props: {
        workflow: {
            type: Object,
            required: true,
        },
        tasks: {
            type: Array,
            required: true,
        },
        runningExecution: {
            type: Object,
            default: null,
        },

        visualisationsConfigs: {
            type: Array,
            default: () => [],
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ResultsViewMain,
        ResultsViewOptions,
        ChartConfiguration,
        ConfirmModal,
        ExclamationCircleIcon,
        GeneralAlert,
        ChevronLeftIcon,
        CollectionIcon,
        ChevronRightIcon,
        Scrollbar,
        ChartTypes,
        XIcon,
    },
    setup(props, { emit, root }) {
        const visualisationQueryParam = 'visualisation';
        const { set, get, onChange } = useQueryParams(root, root.$router, 'workflow-designer:edit');

        const selectedTask: Ref<Task | undefined> = ref<Task | undefined>();
        const selectedVisualisationId: Ref<string | undefined> = ref<string | undefined>(get(visualisationQueryParam));
        const isCollapsed = ref<boolean>(false);
        const overviewIconHovered = ref<boolean>(false);
        const chart = ref<Chart>();
        const chartConfiguration = ref<ChartConfig | null>(null);
        const lastSavedChartConfiguration = ref<ChartConfig | null>(null);
        const visualisations: Ref<any> = ref<any>(R.clone(props.visualisationsConfigs));
        const showInvalidConfigurationWarning = ref<boolean>(false);
        const isExecutionRunning = ref<boolean>(false);
        const invalidConfiguration = ref<boolean>(false);
        const displayDisabledMarkersAndLabelsIcon = ref<boolean>(false);
        const refreshDate = ref<Date>(new Date());
        const addForTask: Ref<string | undefined> = ref<string | undefined>();
        const updateChartTypeForVisualisation: Ref<string | undefined> = ref<string | undefined>();

        const workflowTasks = computed(() => props.tasks);
        const {
            createVisualisation,
            updateVisualisation,
            deleteVisualisation,
            isVisualisationConfigValid,
            seriesStructure,
            getRetrievalConfig,
            getSeriesOptions,
            upstreamTask,
            getTaskById,
            computeResultsViewVisualisationData,
            testSampleData,
            warningMessage,
            fetchSampleData,
        } = useVisualisation(workflowTasks, selectedTask, chartConfiguration as Ref<ChartConfig | null>, chart);

        onChange(() => (selectedVisualisationId.value = get(visualisationQueryParam)));

        const showChartTypes: Ref<boolean> = computed(
            () => !!addForTask.value || !!updateChartTypeForVisualisation.value,
        );

        const chartData = computed(() =>
            computeResultsViewVisualisationData({
                config: chartConfiguration.value,
                sampleData: testSampleData.value,
            }),
        );

        const assetId = computed(() => {
            return selectedTask.value?.block.id === 'ui.StoreCloudResult'
                ? selectedTask.value.configuration.assetId.value
                : null;
        });

        const exportTasks = computed(() => {
            // Visualisation is available only for StoreCloudResult block
            return props.tasks.filter(
                (task: any) =>
                    task.block.category === BlockCategory.Output &&
                    task.block.id === 'ui.StoreCloudResult' &&
                    task.upstreamTaskIds.length,
            );
        });

        const showConfiguration = computed(() => {
            return !isExecutionRunning.value && !warningMessage.value && !!chartConfiguration.value;
        });

        const selectedVisualisation = computed(() =>
            visualisations.value.find((v: Visualisation) => selectedVisualisationId.value === v.id),
        );

        const showChart = computed(
            () => !invalidConfiguration.value && !warningMessage.value && chartConfiguration.value && chartData.value,
        );

        const mainPanelMessage = computed(() => {
            if (props.readOnly) {
                return WarningMessages.DEPRECATED_WORKFLOW;
            }
            if (invalidConfiguration.value) {
                return WarningMessages.INVALID_CONFIGURATION;
            }
            return WarningMessages.DEFAULT;
        });

        const isTaskRunning: Ref<boolean> = computed(
            () =>
                !R.isNil(props.runningExecution) &&
                !R.isNil(props.runningExecution.task) &&
                props.runningExecution.task.id === upstreamTask.value?.id,
        );

        const updateChart = (newChart: Chart) => {
            let taskId: string | undefined = undefined;
            if (addForTask.value) {
                taskId = addForTask.value;
            } else if (updateChartTypeForVisualisation.value) {
                const visualisation = visualisations.value.find(
                    (v: Visualisation) => v.id === updateChartTypeForVisualisation.value,
                );
                if (
                    !visualisation ||
                    (visualisation &&
                        Chart.findChart(visualisation.configuration.type).getChartType() === newChart.getChartType())
                )
                    return;
                taskId = visualisation.taskId;
            }

            if (taskId) {
                const task = getTaskById(taskId);
                selectedTask.value = task;
                chart.value = newChart;
                const seriesOptions = getSeriesOptions(
                    chart.value.hasSeriesConfigurationSection(),
                    chart.value.getChartType(),
                );

                chartConfiguration.value = new ChartConfig(
                    newChart,
                    task.displayName,
                    null,
                    chartConfiguration.value?.visualisationId,
                    seriesOptions,
                );

                if (updateChartTypeForVisualisation.value)
                    lastSavedChartConfiguration.value = new ChartConfig(
                        newChart,
                        task.displayName,
                        null,
                        chartConfiguration.value?.visualisationId,
                        seriesOptions,
                    );
            }
        };

        const saveVisualisation = () => {
            if (!chartConfiguration.value) return;

            const retrievalConfig = getRetrievalConfig(chartConfiguration.value, assetId.value || undefined);
            const configuration = {
                title: chartConfiguration.value.configuration.title.text,
                subtitle: chartConfiguration.value.configuration.subtitle.text,
                type: VisualisationType.Chart,
                workflowId: props.workflow.id,
                taskId: selectedTask.value?.id,
                configuration: {
                    options: chartConfiguration.value.configuration,
                    title: chartConfiguration.value.title,
                    type: chartConfiguration.value.chart.getChartType(),
                },
                assetId: assetId.value ? assetId.value : null,
                retrieval: retrievalConfig,
            };
            if (selectedVisualisation.value === undefined) {
                createVisualisation(configuration)
                    .then((res: any) => {
                        visualisations.value.push(res.data);
                        emit('visualisation-created');
                        (root as any).$toastr.s(`Visualisation successfully saved!`, 'Success');
                        if (chartConfiguration.value) {
                            lastSavedChartConfiguration.value = new ChartConfig(
                                chartConfiguration.value.chart as Chart,
                                selectedTask.value?.displayName,
                                chartConfiguration.value.configuration,
                                chartConfiguration.value.visualisationId,
                                chartConfiguration.value.seriesOptions,
                            );
                        }

                        selectedVisualisationId.value = res.data.id;
                    })
                    .catch(
                        (err: {
                            response: {
                                status: number;
                                data: { message: string };
                            };
                        }) => {
                            if (
                                err.response &&
                                err.response?.status === 403 &&
                                err.response?.data?.message === 'Locked'
                            ) {
                                (root as any).$toastr.e('The analytics pipeline is locked by another user', 'Error');
                            } else (root as any).$toastr.e(err.response.data.message, 'Error');
                        },
                    )
                    .finally(() => {
                        refreshDate.value = new Date();
                        updateChartTypeForVisualisation.value = undefined;
                        addForTask.value = undefined;
                    });
            } else {
                updateVisualisation({
                    ...selectedVisualisation.value,
                    ...configuration,
                })
                    .then((res: any) => {
                        visualisations.value = R.clone(visualisations.value).map((vis: any) => {
                            if (vis.id === res.data.id) {
                                return res.data;
                            }
                            return vis;
                        });
                        emit('visualisation-updated');
                        (root as any).$toastr.s(`Visualisation successfully updated!`, 'Success');
                        // Updates last saved config after saving (create/update)
                        if (chart.value && chartConfiguration.value?.visualisationId) {
                            lastSavedChartConfiguration.value = new ChartConfig(
                                chartConfiguration.value.chart as Chart,
                                selectedTask.value?.displayName,
                                chartConfiguration.value.configuration,
                                chartConfiguration.value.visualisationId,
                                chartConfiguration.value.seriesOptions,
                            );
                        }
                    })
                    .catch(
                        (err: {
                            response: {
                                status: number;
                                data: { message: string };
                            };
                        }) => {
                            if (
                                err.response &&
                                err.response?.status === 403 &&
                                err.response?.data?.message === 'Locked'
                            ) {
                                (root as any).$toastr.e('The analytics pipeline is locked by another user', 'Error');
                            } else (root as any).$toastr.e(err.response.data.message, 'Error');
                        },
                    )
                    .finally(() => {
                        refreshDate.value = new Date();
                        updateChartTypeForVisualisation.value = undefined;
                        addForTask.value = undefined;
                    });
            }
        };

        const updateChartConfiguration = (
            configuration: any,
            seriesOptions: { groupBy: any; series: []; filters: any; label: '' },
        ) => {
            if (chartConfiguration.value) {
                chartConfiguration.value = new ChartConfig(
                    chartConfiguration.value.chart as Chart,
                    null,
                    configuration,
                    chartConfiguration.value.visualisationId,
                    seriesOptions,
                );
            }
        };

        const resetChartConfig = () => {
            if (lastSavedChartConfiguration.value) {
                chartConfiguration.value = new ChartConfig(
                    lastSavedChartConfiguration.value.chart as Chart,
                    selectedTask.value?.displayName,
                    lastSavedChartConfiguration.value.configuration,
                    lastSavedChartConfiguration.value.visualisationId,
                    lastSavedChartConfiguration.value.seriesOptions,
                );
            } else {
                chartConfiguration.value = null;
            }
            addForTask.value = undefined;
            updateChartTypeForVisualisation.value = undefined;
        };

        const deleteChartConfig = () => {
            if (!chartConfiguration || !chartConfiguration.value?.visualisationId) return;
            deleteVisualisation(chartConfiguration.value?.visualisationId)
                .then(() => {
                    visualisations.value = R.clone(visualisations.value).filter(
                        (vis: any) => vis.id !== chartConfiguration.value?.visualisationId,
                    );
                    emit('visualisation-deleted');

                    // Reset config
                    chartConfiguration.value = null;
                    lastSavedChartConfiguration.value = null;
                    // Hide warning
                    showInvalidConfigurationWarning.value = false;
                    addForTask.value = undefined;
                    updateChartTypeForVisualisation.value = undefined;
                    // Show success message
                    (root as any).$toastr.s(`Visualisation successfully deleted!`, 'Success');
                })
                .catch((err: { response: { data: { message: string } } }) => {
                    // Hide warning
                    showInvalidConfigurationWarning.value = false;
                    // Show error message
                    (root as any).$toastr.e(err.response.data.message, 'Error');
                });
        };

        const testRun = () => {
            emit('test-run', upstreamTask.value);
        };

        const loadVisualisation = (visualisation: Visualisation) => {
            const newChart = Chart.findChart(visualisation.configuration.type);
            chart.value = newChart;

            chartConfiguration.value = new ChartConfig(
                newChart,
                visualisation.title,
                visualisation.configuration.options,
                visualisation.id,
                R.clone({
                    groupBy: { ...visualisation.retrieval.groupBy, type: visualisation.retrieval.groupBy?.type || '' },
                    series: visualisation.retrieval.series,
                    filters: visualisation.retrieval.filters,
                    label: visualisation.retrieval.label || '',
                    pageSize: visualisation.retrieval.pageSize,
                }),
            );
            lastSavedChartConfiguration.value = new ChartConfig(
                chartConfiguration.value.chart as Chart,
                selectedTask.value?.displayName,
                chartConfiguration.value.configuration,
                chartConfiguration.value.visualisationId,
                chartConfiguration.value.seriesOptions,
            );
            if (upstreamTask.value?.executions?.length && !isVisualisationConfigValid.value) {
                showInvalidConfigurationWarning.value = true;
            }
        };

        const clearVisualisation = () => {
            chartConfiguration.value = null;
            lastSavedChartConfiguration.value = null;
            chart.value = undefined;
        };

        const resetMarkersAndLabels = (reset: boolean) => {
            displayDisabledMarkersAndLabelsIcon.value = reset;
        };

        const isChartScatterPlot = computed(() => chart.value?.isChartType(ApexChartType.Scatter));

        watch(
            () => selectedVisualisationId.value,
            async (visualisationId: string | undefined) => {
                const visualisation = visualisations.value.find((v: any) => v.id === visualisationId);
                if (visualisation) {
                    selectedTask.value = getTaskById(visualisation.taskId);
                    loadVisualisation(visualisation);

                    // clear add task if a visualisation is selected
                    addForTask.value = undefined;

                    // clear update chart type if visualisation is not the same as
                    // the one under update
                    if (
                        updateChartTypeForVisualisation.value &&
                        updateChartTypeForVisualisation.value !== visualisation.id
                    )
                        updateChartTypeForVisualisation.value = undefined;

                    set(visualisationQueryParam, visualisation.id);
                } else {
                    // Reset config if output block without visualisation config is selected
                    clearVisualisation();
                    set(visualisationQueryParam, null);
                }
            },
            { immediate: true },
        );

        watch(
            () => upstreamTask.value?.executions,
            (executions: Execution[] | undefined) => {
                if (
                    executions &&
                    executions[0] &&
                    executions[0].type === ExecutionType.Test &&
                    executions[0].status === ExecutionStatus.Completed &&
                    selectedTask.value
                ) {
                    fetchSampleData(upstreamTask.value as any);
                }
            },
        );

        watch(
            () => props.runningExecution,
            (newRunningExecution: any) => {
                const isRunning =
                    !R.isNil(newRunningExecution) &&
                    !R.isNil(newRunningExecution.task) &&
                    newRunningExecution.task.id === upstreamTask.value?.id;
                isExecutionRunning.value = isRunning;
            },
        );

        watch(
            () => upstreamTask.value,
            async (newUpstreamTask: Task | undefined) => {
                if (newUpstreamTask) fetchSampleData(newUpstreamTask);
            },
            { immediate: true },
        );

        watch(
            () => chart.value,
            (newChartType: Chart | undefined) => {
                if (newChartType) updateChart(newChartType);
            },
        );

        return {
            isCollapsed,
            overviewIconHovered,
            updateChart,
            exportTasks,
            selectedTask,
            saveVisualisation,
            chartConfiguration,
            upstreamTask,
            warningMessage,
            isVisualisationConfigValid,
            testRun,
            showInvalidConfigurationWarning,
            resetChartConfig,
            deleteChartConfig,
            isExecutionRunning,
            visualisations,
            lastSavedChartConfiguration,
            updateChartConfiguration,
            chart,
            invalidConfiguration,
            showConfiguration,
            testSampleData,
            displayDisabledMarkersAndLabelsIcon,
            resetMarkersAndLabels,
            selectedVisualisationId,
            assetId,
            showChart,
            mainPanelMessage,
            WarningMessages,
            isTaskRunning,
            seriesStructure,
            refreshDate,
            showChartTypes,
            addForTask,
            updateChartTypeForVisualisation,
            isChartScatterPlot,
        };
    },
});

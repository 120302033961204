



























































































import { Toggle, TwSelect } from '@/app/components';
import { Variable } from '@/app/interfaces';
import {
    excludedValidator,
    maxLengthValidator,
    maxListLengthValidator,
    maxValueValidator,
    minLengthValidator,
    minListLengthValidator,
    minValueValidator,
    regexValidator,
    requiredValidator,
    variableAwareValidator,
} from '@/app/validators';
import { VariableIcon } from '@vue-hero-icons/outline';
import { PropType, computed, defineComponent, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { extend } from 'vee-validate';
import { useTaskParameterConfiguration } from '../../composable';
import { InputParameterCategory } from '../../constants';
import { InputParameter, Loop, Workflow } from '../../types';
import { ParameterComponent } from './parameter-component.constants';

extend('min_value', minValueValidator);
extend('max_value', maxValueValidator);
extend('required', requiredValidator);
extend('minList', minListLengthValidator);
extend('maxList', maxListLengthValidator);
extend('regex', regexValidator);
extend('excluded', excludedValidator);
extend('min', minLengthValidator);
extend('max', maxLengthValidator);
extend('variableAware', variableAwareValidator);

extend('modelpath', {
    message: (field) => {
        return `The specified ${field} is not a valid path`;
    },
    validate: (value, args) => {
        const rules = JSON.parse(args[0]);
        const winPath = new RegExp(`([a-zA-Z]:)?(\\\\[a-z  A-Z0-9_.-]+)+\\\\?$`);
        const linuxPath = new RegExp(`^/|(/[a-zA-Z0-9_-]+)+$`);
        const isWinPath = winPath.test(value);
        const isLinuxPath = linuxPath.test(value);

        let parts = [];
        if (isWinPath) parts = value.split('\\');
        else if (isLinuxPath) parts = value.split('/');

        const name = parts.length > 0 ? parts[parts.length - 1] : value;

        const minValidation = rules.min ? minLengthValidator.validate(name, [rules.min]) : true;
        const maxValidation = rules.max ? maxLengthValidator.validate(name, [rules.max]) : true;
        const regexValidation = rules.regex ? regexValidator.validate(name, { regex: new RegExp(rules.regex) }) : true;

        return (isWinPath || isLinuxPath) && minValidation && maxValidation && regexValidation;
    },
});

export default defineComponent({
    name: 'TaskParameterConfiguration',
    model: {
        prop: 'parameterConfig',
        event: 'update-parameter-configuration',
    },
    props: {
        workflowId: { type: String, required: true },
        parameterConfig: {
            type: Object,
            default: () => {
                return { value: null };
            },
        },
        parameter: {
            type: Object as PropType<InputParameter>,
            required: true,
        },
        parameters: {
            type: Object,
            required: true,
        },
        dataframes: {
            type: Object,
            default: () => {
                return {};
            },
        },
        columnsPerTask: {
            type: Object,
            default: () => {
                return {};
            },
        },
        workflowConfiguration: {
            type: Object,
            default: () => {
                return {};
            },
        },
        forceUpdate: {
            type: Date,
            default: () => {
                return new Date();
            },
        },
        strict: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        visible: {
            type: Boolean,
            default: true,
        },

        loops: {
            type: Array as PropType<Loop[]>,
            default: () => [],
        },
        isOnPremise: {
            type: Boolean,
            default: false,
        },
        runnerId: {
            type: String,
            default: null,
        },
        parametersDefinition: {
            type: Object,
            required: true,
        },
        availableVariables: {
            type: Object as PropType<Record<string, Variable>>,
            default: () => {
                return {};
            },
        },
        assetStructureForTask: {
            type: Object,
            default: () => {},
        },
        selectedAsset: {
            type: Object,
            default: null,
        },
    },
    components: { Toggle, TwSelect, VariableIcon },

    setup(props, { emit }) {
        // Transforming volitile props into computed properties so that the useTaskParameterConfiguration composable is reactive
        const visible = computed(() => props.visible);
        const forceUpdate = computed(() => props.forceUpdate);
        const workflowConfiguration = computed((): Workflow => props.workflowConfiguration as Workflow);
        const parametersDefinition = computed((): Record<string, InputParameter> => props.parametersDefinition);
        const isArray = computed(() => R.is(Array, props.parameterConfig.value));
        const showVariableInfo = ref<boolean>(false);
        const innerComponent = ref<ParameterComponent | undefined>();
        const availableVariables = computed(() => props.availableVariables);
        const isInLoop = computed(() => props.loops.length > 0);
        const parameterValue = computed(() => props.parameterConfig?.value || props.parameterConfig);

        const {
            // The parameter name
            name,

            // The rules to validate this parameter against
            rules,

            // Based on the input parameter configuration
            // figure out which of the ParameterComponent
            // options this is
            parameterComponent,

            // Whether this parameter is visible right now or not
            display,

            // Custom validation messages
            validationMessages,
        } = useTaskParameterConfiguration(
            props.parameter as InputParameter,
            workflowConfiguration,
            parametersDefinition,
            visible,
            forceUpdate,
            availableVariables,
            isInLoop,
            parameterValue,
        );

        const change = (val: any) => {
            emit('update-parameter-configuration', val);
            emit('change', val);
        };

        const orderColumns = computed(
            () =>
                props.parameter.category === InputParameterCategory.Column && props.parameter.multiple && isArray.value,
        );

        const variables = computed(() =>
            Object.keys(props.availableVariables).map((key: string) => {
                return {
                    key,
                    type: props.availableVariables[key],
                };
            }),
        );

        return {
            name,
            rules,
            display,
            variables,
            showVariableInfo,
            parameterComponent,
            change,
            orderColumns,
            validationMessages,
            innerComponent,
        };
    },
});

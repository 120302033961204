




























import { ColorSwatchIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, PropType, Ref } from '@vue/composition-api';
import { isNil } from 'ramda';
import { BlockCategory, BlockType, ExecutionFramework, ExecutionType } from '../../constants';
import { SampleConfiguration, Task } from '../../types';

export default defineComponent({
    name: 'SampleDescription',
    props: {
        configuration: {
            type: Object as PropType<SampleConfiguration>,
            required: true,
        },
        sourceTask: {
            type: Object as PropType<Task>,
            required: true,
        },
        currentTask: {
            type: Object as PropType<Task>,
            required: true,
        },
        executionType: {
            type: String,
            required: true,
        },
        isInLoop: { type: Boolean, default: false },
        framework: { type: String as PropType<ExecutionFramework>, required: true },
    },
    components: { ColorSwatchIcon },
    setup(props, { emit }) {
        const isEvaluateInLoop = computed(
            () =>
                props.currentTask.block.category === BlockCategory.MachineLearning &&
                !isNil(props.currentTask.block.type) &&
                props.currentTask.block.type === BlockType.Evaluate &&
                props.isInLoop,
        );

        const usesAllOfData = computed(() => {
            const taskToCheck: Task =
                props.framework === ExecutionFramework.python3 ? props.sourceTask : props.currentTask;
            return (
                taskToCheck.block.category === BlockCategory.MachineLearning &&
                !isNil(taskToCheck.block.type) &&
                [BlockType.Evaluate, BlockType.Train].includes(taskToCheck.block.type)
            );
        });

        const message = computed(() => {
            if (isEvaluateInLoop.value)
                return `Results aggregated from all for-loop iterations, obtained through ${props.executionType} execution.`;
            if (props.executionType === ExecutionType.Dry) {
                return `Sample obtained through dry execution.`;
            }
            return `Sample obtained through test execution performed for task '${props.sourceTask.displayName}'.`;
        });

        const sampleDescription = computed(() => {
            if (usesAllOfData.value) return `Bypassing configured sample and using 100% of the data`;
            if (props.executionType === ExecutionType.Dry) return `Using 200 first rows`;
            if (props.configuration.size.rowsCount)
                return `Configured sampling: ${props.configuration.selection} ${props.configuration.size.rowsCount} rows`;
            return `Configured sampling: ${props.configuration.selection} ${
                (props.configuration.size.percentage ?? 0.1) * 100
            }%`;
        });

        const description = computed(() => `${message.value} (${sampleDescription.value})`);

        const tooltip: Ref<{ message: string; click?: any | undefined } | undefined> = computed(() => {
            if (isEvaluateInLoop.value)
                return {
                    message: `The results passed to subsequent blocks in each iteration contain only the data in the row that corresponds to each loop iteration.`,
                };
            if (usesAllOfData.value) return undefined;
            if (!props.configuration.usingOptimisedParquet)
                return {
                    message: `Go to the analytics pipeline's configuration to amend this.`,
                    click: () => emit('show-configuration'),
                };
            return undefined;
        });

        return { description, tooltip };
    },
});

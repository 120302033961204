
















































import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import { GraphViewEditor, GraphViewLibrary } from '.';
import { Block, DagLoop, Execution, ExecutionLog, RunningExecution, Task, WorkflowExecution } from '../../types';
import { RunningExecutionFooter } from '../running-execution';

export default defineComponent({
    name: 'GraphView',
    props: {
        workflow: { type: Object, required: true },
        tasks: { type: Array as PropType<Task[]>, required: true },
        selectedTask: { type: String, required: false },
        runningExecution: { type: Object as PropType<RunningExecution | null>, default: null },
        pipelines: { type: Array, default: () => [] },
        dagLoops: { type: Array as PropType<DagLoop[]>, default: () => [] },
        pendingExecutions: {
            type: Array,
            default: () => {
                return [];
            },
        },
        otherRunningExecutions: {
            type: Array,
            default: () => {
                return [];
            },
        },
        failedExecutions: {
            type: Array as PropType<WorkflowExecution[]>,
            default: () => {
                return [];
            },
        },
        blockedExecutions: {
            type: Array as PropType<WorkflowExecution[]>,
            default: () => {
                return [];
            },
        },
        invalidTaskIds: {
            type: Array,
            default: () => [],
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        validationErrors: {
            type: Array,
            default: () => [],
        },
        latestExecution: {
            type: Object as PropType<Execution>,
            required: false,
        },
        newLogMessageArrived: {
            type: Object as PropType<ExecutionLog>,
            required: false,
        },
    },
    components: { GraphViewLibrary, GraphViewEditor, RunningExecutionFooter },
    setup(props, { emit }) {
        const collapsedLibrary = ref(false);

        const filteredTasks = computed(() => props.tasks);

        const getNewTaskName = (block: Block) => {
            // if task with same name exists then give
            // a unique numeric suffix
            let name = block.name;
            let counter = 2;
            while (props.tasks.some((task: Task) => task.displayName === name)) {
                name = `${block.name} #${counter}`;
                counter += 1;
            }
            return name;
        };

        const createTask = async (block: Block) => {
            emit('create-task', {
                displayName: getNewTaskName(block),
                blockId: block.id,
                blockVersion: block.version,
                workflowId: props.workflow.id,
                configuration: {},
            });
        };

        const showSettings = (task: Task | null) => {
            emit('show-settings', task);
        };

        return { collapsedLibrary, filteredTasks, createTask, showSettings };
    },
});

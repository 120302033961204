var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col overflow-hidden rounded shadow",class:[
        {
            'bg-purple-100': _vm.colour === 'purple',
            'bg-blue-100': _vm.colour === 'blue',
            'bg-green-100': _vm.colour === 'green',
            'bg-orange-100': _vm.colour === 'orange',
            'bg-cherry-100': _vm.colour === 'indigo',
            'ring-primary-400 ring-2 ': _vm.isTaskSelected,
        } ]},[_c('div',{staticClass:"flex flex-row space-x-2 text-white bg-white cursor-pointer group h-14",class:[
            {
                'group-hover:text-purple-200 group-hover:bg-purple-100': _vm.colour === 'purple',
                'group-hover:text-blue-200 group-hover:bg-blue-100': _vm.colour === 'blue',
                'group-hover:text-green-200 group-hover:bg-green-100': _vm.colour === 'green',
                'group-hover:text-orange-200 group-hover:bg-orange-100': _vm.colour === 'orange',
                'group-hover:text-cherry-200 group-hover:bg-cherry-100': _vm.colour === 'indigo',
            } ],on:{"click":function($event){_vm.expanded = !_vm.expanded}}},[_c('div',{staticClass:"flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium",class:[
                {
                    'bg-purple-600 border-purple-600': _vm.colour === 'purple',
                    'bg-blue-700 border-blue-700 ': _vm.colour === 'blue',
                    'bg-green-800 border-green-800 ': _vm.colour === 'green',
                    'bg-orange-800 border-orange-800 ': _vm.colour === 'orange',
                    'bg-cherry-600 border-cherry-600': _vm.colour === 'indigo',
                } ]},[_c('span',{staticClass:"block group-hover:hidden",attrs:{"title":_vm.label},domProps:{"innerHTML":_vm._s(_vm.iconHtml)}}),(_vm.expanded)?_c('ChevronDownIcon',{staticClass:"hidden w-6 h-6 text-white group-hover:block"}):_c('ChevronRightIcon',{staticClass:"hidden w-6 h-6 text-white group-hover:block"})],1),_c('div',{staticClass:"flex flex-row items-center justify-between flex-1 space-x-0.5"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.task.displayName),expression:"task.displayName"}],staticClass:"w-40 min-w-0 py-1 text-sm truncate xl:w-56",class:[
                    {
                        'text-purple-600 ': _vm.colour === 'purple',
                        'text-blue-700  ': _vm.colour === 'blue',
                        'text-green-800 ': _vm.colour === 'green',
                        'text-orange-800 ': _vm.colour === 'orange',
                        'text-cherry-100': _vm.colour === 'indigo',
                    } ]},[_vm._v(" "+_vm._s(_vm.task.displayName)+" ")]),_c('div',{staticClass:"flex flex-row items-center flex-grow-0 flex-shrink-0 pr-2"},[_c('PlusIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                        _vm.disabled ? "Cannot add visualisation at this time" : "Add visualisation for output task"
                    ),expression:"\n                        disabled ? `Cannot add visualisation at this time` : `Add visualisation for output task`\n                    "}],staticClass:"w-6 h-6 text-neutral-500 hover:text-primary-700 focus:ring-0 focus:ring-offset-0",class:{
                        'opacity-50 cursor-not-allowed': _vm.disabled,
                    },on:{"click":function($event){$event.stopPropagation();_vm.disabled ? null : _vm.$emit('add', _vm.task)}}})],1)])]),(_vm.expanded)?_c('div',{staticClass:"flex flex-col text-sm"},[(_vm.visualisations.length === 0 && !_vm.inAddMode)?_c('span',{staticClass:"p-4 text-xs italic text-neutral-600"},[_vm._v(" No visualisations available. "),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                    _vm.disabled ? "Cannot add visualisation to invalid pipeline" : "Add visualisation for output task"
                ),expression:"\n                    disabled ? `Cannot add visualisation to invalid pipeline` : `Add visualisation for output task`\n                "}],staticClass:"cursor-pointer text-primary-500 hover:text-primary-700",class:{
                    'opacity-50 cursor-not-allowed': _vm.disabled,
                },on:{"click":function($event){$event.stopPropagation();_vm.disabled ? null : _vm.$emit('add', _vm.task)}}},[_vm._v(" Add the first one? ")])]):_c('div',{staticClass:"flex flex-col"},_vm._l((_vm.visualisations),function(visualisation){return _c('div',{key:visualisation.id,staticClass:"flex flex-row items-center p-4 space-x-3 text-xs group text-neutral-700 hover:text-neutral-800",class:{
                    'font-semibold': visualisation.id === _vm.selected,
                    'hover:bg-purple-100': _vm.colour === 'purple',
                    'hover:bg-blue-100': _vm.colour === 'blue',
                    'hover:bg-green-100': _vm.colour === 'green',
                    'hover:bg-orange-100': _vm.colour === 'orange',
                    'text-purple-600 bg-purple-100': _vm.colour === 'purple' && visualisation.id === _vm.selected,
                    'text-blue-700 bg-blue-100': _vm.colour === 'blue' && visualisation.id === _vm.selected,
                    'text-green-800 bg-green-100': _vm.colour === 'green' && visualisation.id === _vm.selected,
                    'text-orange-800 bg-orange-100': _vm.colour === 'orange' && visualisation.id === _vm.selected,
                },on:{"click":function($event){return _vm.$emit('selected', visualisation.id)}}},[_c('div',{staticClass:"relative group"},[_c(_vm.getChartIcon(visualisation.configuration.type),{tag:"component",staticClass:"flex items-center justify-center flex-shrink-0 w-6 text-sm font-medium rounded group-hover:opacity-40",class:{
                            'filter-none saturate-100': visualisation.id === _vm.selected,
                            'group-hover:filter group-hover:saturate-0 ': !_vm.selected,
                        }}),_c('PencilAltIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:("Change chart type"),expression:"`Change chart type`"}],staticClass:"absolute w-4 h-4 cursor-pointer top-1 left-1.5 hidden group-hover:block text-secondary-800",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('change-chart-type', visualisation.id)}}})],1),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(visualisation.subtitle),expression:"visualisation.subtitle"}],staticClass:"truncate"},[_vm._v(_vm._s(visualisation.title))])])}),0),(_vm.inAddMode)?_c('div',{staticClass:"flex flex-row items-center p-4 space-x-3 text-xs italic font-semibold text-neutral-600",class:{
                'hover:bg-purple-200 bg-purple-100': _vm.colour === 'purple',
                'hover:bg-blue-200 bg-blue-100': _vm.colour === 'blue',
                'hover:bg-green-200 bg-green-100': _vm.colour === 'green',
                'hover:bg-orange-200 bg-orange-100': _vm.colour === 'orange',
                'hover:bg-cherry-200 bg-cherry-100': _vm.colour === 'indigo',
            }},[_c('PresentationChartBarIcon',{staticClass:"w-6 h-6"}),_vm._v(" "),_c('span',[_vm._v("New Visualisation")])],1):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }




































import { defineComponent } from '@vue/composition-api';
import { PencilAltIcon } from '@vue-hero-icons/outline';

export default defineComponent({
    name: 'FakeWorkflowOverview',
    components: {
        PencilAltIcon,
    },
});

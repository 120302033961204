






















































































import { defineComponent } from '@vue/composition-api';

import { ChevronRightIcon } from '@vue-hero-icons/outline';
import { HtmlModal, SvgImage, TwButton, Scrollbar, ConfirmModal } from '@/app/components';
export default defineComponent({
    name: 'UpgradeVersionModal',
    props: {
        isFinalised: {
            type: Boolean,
            default: false,
        },
        blocksNotSupportedInNewVersion: {
            type: Array,
            default: () => [],
        },
        blocksUpgradedInNewVersion: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        HtmlModal,
        SvgImage,
        TwButton,
        Scrollbar,
        ChevronRightIcon,
        ConfirmModal,
    },
});

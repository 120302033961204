





























































































import { HtmlModal, Pagination, Scrollbar } from '@/app/components';
import { PropType, defineComponent, Ref, ref, computed } from '@vue/composition-api';
import { XIcon } from '@vue-hero-icons/outline';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import * as R from 'ramda';
import { ExecutionDataType } from '../../constants';
import SchemaChangesBarChart from './SchemaChangesBarChart.vue';
import { PaginationOptions } from '@/app/interfaces';
import { ResultCompleteness } from '../../types';

dayjs.extend(utc);

export default defineComponent({
    name: 'ResultCompletenessTypeChangesModal',
    components: { HtmlModal, Scrollbar, SchemaChangesBarChart, XIcon, Pagination },
    model: {
        prop: 'chartModal',
        event: 'select',
    },
    props: {
        chartModal: {
            type: Object as PropType<{ chartType: string; row: number; selectedBar?: number }>,
            required: true,
        },
        completeness: {
            type: Object as PropType<ResultCompleteness>,
            required: true,
        },
        columnTypeChangesSeries: {
            type: Array as PropType<{ series: []; labels: [] }[]>,
            required: true,
        },
        columnTypeChangesTooltipFormatter: { type: Function },
        chartColors: { type: Array as PropType<string[]> },
    },
    setup(props) {
        const pageSize = 100;
        const page: Ref<number> = ref<number>(1);

        const selectedFilters: Ref<ExecutionDataType[]> = ref<ExecutionDataType[]>(Object.values(ExecutionDataType));
        const completeness = computed(() => props.completeness);

        const details: Ref<{
            timestamp: Date | undefined;
            columns: {
                name: string;
                dataType: ExecutionDataType;
                oldDataType: ExecutionDataType;
            }[];
        }> = computed(() => {
            /**
             * If there is a selected bar then we calculate for this entry
             * - the columns changed on that date
             * - the timestamp of when they happened
             */
            if (
                !R.isNil(props.chartModal.selectedBar) &&
                completeness.value?.results[props.chartModal.row].columnDataTypeChanges[props.chartModal.selectedBar]
            )
                return {
                    ...completeness.value?.results[props.chartModal.row].columnDataTypeChanges[
                        props.chartModal.selectedBar
                    ],
                };
            // if no bar is selected then return empty
            return { columns: [], timestamp: undefined };
        });

        const filteredColumns: Ref<
            {
                name: string;
                dataType: ExecutionDataType;
                oldDataType: ExecutionDataType;
            }[]
        > = computed((): {
            name: string;
            dataType: ExecutionDataType;
            oldDataType: ExecutionDataType;
        }[] => {
            if (!details.value) return [];
            return details.value.columns.filter(
                (col: { name: string; dataType: ExecutionDataType; oldDataType: ExecutionDataType }) =>
                    col.dataType && selectedFilters.value.includes(col.dataType),
            );
        });

        const pagination: Ref<PaginationOptions> = computed(() => {
            return {
                page: page.value,
                pageSize,
                total: filteredColumns.value.length,
            };
        });

        const fromPaginationEntry = computed(() =>
            details.value && pagination.value.page ? (pagination.value.page - 1) * pageSize : undefined,
        );
        const toPaginationEntry = computed(() => {
            if (!details.value || !pagination.value.page) return undefined;
            const to = (pagination.value.page - 1) * pageSize + pageSize;

            return to > filteredColumns.value.length ? filteredColumns.value.length : to;
        });
        const visibleColumns = computed(() => {
            if (!details.value || R.isNil(fromPaginationEntry.value) || R.isNil(toPaginationEntry.value))
                return undefined;

            return R.slice(fromPaginationEntry.value, toPaginationEntry.value, filteredColumns.value);
        });

        const toggleFilter = (toType: ExecutionDataType) => {
            if (selectedFilters.value.includes(toType))
                selectedFilters.value = selectedFilters.value.filter((t: ExecutionDataType) => t !== toType);
            else selectedFilters.value.push(toType);
        };

        return {
            pagination,
            visibleColumns,
            dayjs,
            selectedFilters,
            page,
            pageSize,
            filteredColumns,
            ExecutionDataType,
            details,
            toggleFilter,
        };
    },
});







































































































































import { defineComponent, PropType, ref } from '@vue/composition-api';
import { DagLoop, WorkflowExecution } from '../../types';
import Graph from './graph/Graph.vue';

export default defineComponent({
    name: 'GraphViewEditor',
    props: {
        tasks: { type: Array, required: true },
        selectedTask: { type: String, required: false },
        runningExecution: { type: Object, default: null },
        invalidTaskIds: {
            type: Array,
            default: () => [],
        },
        blockedExecutions: {
            type: Array as PropType<WorkflowExecution[]>,
            default: () => [],
        },
        validationErrors: {
            type: Array,
            default: () => [],
        },
        pipelines: {
            type: Array,
            default: () => [],
        },
        dagLoops: {
            type: Array as PropType<DagLoop[]>,
            default: () => [],
        },
        readonly: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
    },
    components: { Graph },
    setup(props, { emit }) {
        const graphAction = ref<any>(null);
        const currentGraphView = ref<string>('LR'); // LR: left to right, TB: top to bottom
        const showSettings = (task: any) => {
            emit('show-settings', task);
        };

        const setGraphAction = (action: string) => {
            graphAction.value = props.tasks.length ? action : null;

            if (action === 'TB' || action === 'LR') {
                currentGraphView.value = action;
                graphAction.value = action;
            }
        };

        return { emit, showSettings, setGraphAction, graphAction, currentGraphView };
    },
});

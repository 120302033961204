









































import { defineComponent } from '@vue/composition-api';

import { HtmlModal, Scrollbar, SvgImage, TwButton } from '@/app/components';
import { ChevronRightIcon } from '@vue-hero-icons/outline';
export default defineComponent({
    name: 'DisabledBlocksModal',
    props: {
        isFinalised: {
            type: Boolean,
            default: false,
        },
        deletedBlocks: {
            type: Array,
            default: () => [],
        },
        queryParams: {
            type: String,
            default: '{}',
        },
    },
    components: {
        HtmlModal,
        SvgImage,
        TwButton,
        Scrollbar,
        ChevronRightIcon,
    },
});

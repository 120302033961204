











































































































import { AdvancedSelect, ConfirmButton, InputErrorIcon, ShortTypeBadge } from '@/app/components';
import { doubleValidator, maxLengthValidator } from '@/app/validators';
import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { extend, ValidationProvider } from 'vee-validate';
import { useDataType } from '../../composable';
import { TrashIcon } from '@vue-hero-icons/outline';
import { ExclamationCircleIcon } from '@vue-hero-icons/solid';
import { TriggerDataLoadedFilter } from '../../types';
import { TriggerDataType, TRIGGER_OPERATORS_MAP } from '../../constants';

extend('double', doubleValidator);
extend('max', maxLengthValidator);

export default defineComponent({
    name: 'TriggerDataLoadedCheck',
    props: {
        columns: {
            type: Array,
            required: true,
        },
        filter: {
            type: Object as PropType<TriggerDataLoadedFilter>,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ValidationProvider,
        AdvancedSelect,
        ShortTypeBadge,
        ConfirmButton,
        InputErrorIcon,
        ExclamationCircleIcon,
        TrashIcon,
    },
    setup(props) {
        const noOfItems = ref<number>(30);
        const { getLabel, getTextColor, getBgColor } = useDataType();

        const operators = Object.entries(TRIGGER_OPERATORS_MAP).map(([op, name]: [string, string]) => ({
            value: op,
            name,
        }));

        const handleFieldChange = (_: string, item: any) => {
            props.filter.type = item.originalType;
            props.filter.operator = null;
            props.filter.value = null;
        };

        const initialiseCheck = () => {
            if (props.filter.type) {
                props.filter.type =
                    (props.columns as { value: string; originalType: string }[]).find(
                        (column) => column.value === props.filter.field,
                    )?.originalType ?? null;
            }
        };

        watch(
            () => props.columns,
            () => {
                initialiseCheck();
            },
            { immediate: true, deep: true },
        );

        const additionErrorClasses = computed(() => (props.disabled ? 'border-red-700' : ''));

        const getErrorTooltip = (errors: string[]) => {
            const errorStrings: string[] = [];

            if (!errors?.length) return null;

            if (errors.length === 1) return errors[0];

            errors.forEach((err: string) => errorStrings.push(`<li>${err}</li>`));

            return `<ul>${errorStrings.join('')}</ul>`;
        };

        return {
            operators,
            getLabel,
            getTextColor,
            getBgColor,
            handleFieldChange,
            getErrorTooltip,
            TriggerDataType,
            additionErrorClasses,
            noOfItems,
        };
    },
});






























































































































import { HtmlModal, Pagination, Scrollbar } from '@/app/components';
import { PropType, defineComponent, Ref, ref, computed } from '@vue/composition-api';
import { FolderAddIcon, FolderRemoveIcon, FolderIcon } from '@vue-hero-icons/solid';
import { XIcon } from '@vue-hero-icons/outline';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import * as R from 'ramda';
import { ColumnChangeStatus, ExecutionDataType } from '../../constants';
import SchemaChangesBarChart from './SchemaChangesBarChart.vue';
import { PaginationOptions } from '@/app/interfaces';
import { ResultCompleteness } from '../../types';

dayjs.extend(utc);

export default defineComponent({
    name: 'ResultCompletenessColumnChangesModal',
    components: {
        HtmlModal,
        Scrollbar,
        SchemaChangesBarChart,
        FolderIcon,
        FolderAddIcon,
        FolderRemoveIcon,
        XIcon,
        Pagination,
    },
    model: {
        prop: 'chartModal',
        event: 'select',
    },
    props: {
        chartModal: {
            type: Object as PropType<{ chartType: string; row: number; selectedBar?: number }>,
            required: true,
        },
        completeness: {
            type: Object as PropType<ResultCompleteness>,
            required: true,
        },
        columnChangesSeries: {
            type: Array as PropType<{ series: []; labels: [] }[]>,
            required: true,
        },
        columnChangesTooltipFormatter: { type: Function },
        chartColors: { type: Array as PropType<string[]> },
    },
    setup(props) {
        const pageSize = 100;
        const page: Ref<number> = ref<number>(1);

        const selectedFilters: Ref<ColumnChangeStatus[]> = ref<ColumnChangeStatus[]>(Object.values(ColumnChangeStatus));

        const details: Ref<
            | {
                  timestamp: Date | undefined;
                  columns: {
                      name: string;
                      dataType: ExecutionDataType;
                      status: ColumnChangeStatus;
                  }[];
              }
            | undefined
        > = computed(() => {
            /**
             * If there is a selected bar then we calculate for this entry
             * - the columns available on that date
             * - the timestamp of when they happened
             */
            if (
                !R.isNil(props.chartModal.selectedBar) &&
                props.completeness?.results[props.chartModal.row].columnChanges[props.chartModal.selectedBar]
            )
                return {
                    ...props.completeness?.results[props.chartModal.row].columnChanges[props.chartModal.selectedBar],
                };
            // if no bar is selected then return empty
            return { columns: [], timestamp: undefined };
        });

        const filteredColumns: Ref<
            {
                name: string;
                dataType: ExecutionDataType;
                status: ColumnChangeStatus;
            }[]
        > = computed((): {
            name: string;
            dataType: ExecutionDataType;
            status: ColumnChangeStatus;
        }[] => {
            if (!details.value) return [];
            return details.value.columns.filter(
                (col: { name: string; dataType: ExecutionDataType; status: ColumnChangeStatus }) =>
                    col.status && selectedFilters.value.includes(col.status),
            );
        });

        const pagination: Ref<PaginationOptions> = computed(() => {
            return {
                page: page.value,
                pageSize,
                total: filteredColumns.value.length,
            };
        });

        const fromPaginationEntry = computed(() =>
            details.value && pagination.value.page ? (pagination.value.page - 1) * pageSize : undefined,
        );
        const toPaginationEntry = computed(() => {
            if (!details.value || !pagination.value.page) return undefined;
            const to = (pagination.value.page - 1) * pageSize + pageSize;

            return to > filteredColumns.value.length ? filteredColumns.value.length : to;
        });
        const visibleColumns = computed(() => {
            if (!details.value || R.isNil(fromPaginationEntry.value) || R.isNil(toPaginationEntry.value))
                return undefined;

            return R.slice(fromPaginationEntry.value, toPaginationEntry.value, filteredColumns.value);
        });

        const toggleFilter = (status: ColumnChangeStatus) => {
            if (selectedFilters.value.includes(status))
                selectedFilters.value = selectedFilters.value.filter((s: ColumnChangeStatus) => s !== status);
            else selectedFilters.value.push(status);
        };

        return {
            pagination,
            visibleColumns,
            ColumnChangeStatus,
            dayjs,
            selectedFilters,
            page,
            pageSize,
            filteredColumns,
            details,
            toggleFilter,
        };
    },
});
























































































import { Asset } from '@/modules/asset/types';
import { useAxios } from '@/app/composable';
import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { WorkflowAPI } from '../../api';
import { TriggerDataLoadedCondition, TriggerDataLoadedFilter, Workflow } from '../../types';
import { PlusIcon } from '@vue-hero-icons/solid';
import { TriggerDataLoadedCheck } from '.';
import { useAssetStructureParameter } from '../../composable/parameters/asset-structure-parameter';
import { ValidationProvider } from 'vee-validate';
import {
    TriggerCheckEvaluation,
    TriggerEntityType,
    TriggerEntityConditionType,
    TriggerConditionType,
} from '../../constants/trigger.constants';
import { TriggerDataType } from '../../constants';
import { AssetTypeId } from '@/modules/asset/constants';
import { v4 as uuidv4 } from 'uuid';

export default defineComponent({
    name: 'TriggerDataLoadedCondition',
    props: {
        condition: {
            type: Object as PropType<TriggerDataLoadedCondition>,
            required: true,
        },
        workflow: {
            type: Object as PropType<Workflow>,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ValidationProvider,
        PlusIcon,
        TriggerDataLoadedCheck,
    },
    setup(props, { emit }) {
        const { exec, error } = useAxios(true);

        const randomUid = uuidv4();
        const assets = ref<Asset[]>([]);
        const entityId = computed(() => props.condition.entityId);

        const { assetStructureForTask } = useAssetStructureParameter(entityId as any, false);
        const columns = computed(
            () =>
                assetStructureForTask.value?.columns?.filter((column: any) =>
                    [TriggerDataType.Double, TriggerDataType.Integer].includes(column.originalType),
                ) || [],
        );

        const fetchInputAssets = () =>
            exec(WorkflowAPI.getInputAssets(props.workflow.id)).then((res: any) => {
                assets.value =
                    res.data?.filter((asset: Asset) =>
                        [AssetTypeId.Dataset, AssetTypeId.Result].includes(asset.assetTypeId),
                    ) || [];
                emit('assets-fetched', assets.value);
            });

        const addCheck = () => {
            props.condition.checks.filters.push({
                field: null,
                operator: null,
                type: null,
                value: null,
            } as TriggerDataLoadedFilter);

            if (props.condition.checks.filters.length > 1) {
                props.condition.checks.evaluation = TriggerCheckEvaluation.same;
            }
        };

        const removeCheck = (_: any, index: number, event: any) => {
            props.condition.checks.filters.splice(index, 1);

            if (props.condition.checks.filters.length < 2) {
                props.condition.checks.evaluation = null;
            }
            if (event) event.stopPropagation();
        };

        const handleAssetChange = () => {
            const entity = assets.value?.find((asset: Asset) => asset.id === props.condition.entityId);

            props.condition.entityName = entity?.name;
            props.condition.entityType = TriggerEntityType.Asset;
            props.condition.entityTypeId = entity?.assetTypeId
                ? entity.assetTypeId === AssetTypeId.Dataset
                    ? TriggerEntityConditionType.Dataset
                    : TriggerEntityConditionType.Result
                : null;
            props.condition.checks = { evaluation: null, filters: [] };
        };

        watch(
            () => props.condition.type,
            () => {
                if (props.condition.type === TriggerConditionType.DataLoaded) fetchInputAssets();
            },
            { immediate: true },
        );

        return {
            assets,
            columns,
            error,
            handleAssetChange,
            addCheck,
            removeCheck,
            randomUid,
        };
    },
});

































































































































import { SvgImage, TwButton } from '@/app/components';
import { useQuery } from '@/app/composable';
import { WorkflowStatus } from '@/modules/apollo/constants';
import { ChevronLeftIcon, CogIcon, DownloadIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { LoopingRhombusesSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { logoBase64 } from '../components/results-view/assets/logo/logoBase64';
import { ApexChart } from '../components/results-view/charts';
import { useVisualisation } from '../composable';
import { WarningMessages, WorkflowDesignerViews } from '../constants';
import GET_VISUALISATION from '../graphql/visualisation.graphql';
import { Chart } from '../types';

export default defineComponent({
    name: 'WorkflowVisualisation',
    metaInfo() {
        return {
            title: `Visualisation${
                (this as any).visualisationConfig ? `: ${(this as any).visualisationConfig.title}` : ''
            }`,
        };
    },
    components: {
        LoopingRhombusesSpinner,
        TwButton,
        ApexChart,
        SvgImage,
        CogIcon,
        DownloadIcon,
        ChevronLeftIcon,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        queryParams: {
            type: String,
            default: '{}',
        },
    },
    setup(props, { root }) {
        const chartRef = ref<any>({});
        const visualisationConfig = ref<any>(null);
        const retrievalData = ref<any>(null);
        const contentRef = ref<any>(null);
        const currentWorkflow = ref<any>(null);
        const hasNormalRunData = ref<boolean>(true);

        const {
            updateVisualisation,
            getVisualisationData,
            loading,
            error,
            computeVisualisationData,
            downloadChart,
        } = useVisualisation();

        const back = () => {
            root.$router.push({ name: 'workflows', query: JSON.parse(props.queryParams) });
        };

        const goToConfiguration = () => {
            root.$router.push({
                name: 'workflow-designer:edit',
                params: {
                    id: currentWorkflow.value.id,
                    backTo: 'workflow-designer:visualize',
                    backToId: props.id,
                    queryParams: props.queryParams,
                },
                query: {
                    view: WorkflowDesignerViews.ResultsView,
                    visualisation: visualisationConfig.value.id,
                },
            });
        };

        const apolloRunner = useQuery(GET_VISUALISATION, { id: props.id }, { fetchPolicy: 'no-cache' });
        apolloRunner.onError((e: any) => {
            const { graphQLErrors }: { graphQLErrors: any[] } = e;

            graphQLErrors.forEach(({ message }: { message: string }) => {
                switch (message) {
                    case 'Forbidden resource':
                        (root as any).$toastr.e('You do not have access to the specific visualisation', 'Error');
                        break;
                    case 'Not Found':
                        (root as any).$toastr.e('The visualisation was not found', 'Error');
                        break;
                }
                root.$router.push({ name: 'workflows', query: JSON.parse(props.queryParams) });
            });
        });
        apolloRunner.onResult((result: any) => {
            if (R.isNil(result?.visualisation)) return;
            const { visualisation } = result;

            currentWorkflow.value = visualisation.workflow;
            const completedExecutionIndex = visualisation?.workflow.executions.findIndex(
                (execution: any) => execution.status === 'completed',
            );

            // Create new Chart instance in order to apply default chart options and merge current ones.
            // This maintains backwards compatibility in almost all cases
            const newChart = Chart.findChart(visualisation.configuration.type);
            const chartConfigurationOptions = R.mergeDeepRight(
                newChart.configuration(visualisation.title) || {},
                visualisation.configuration.options,
            );

            visualisationConfig.value = {
                id: visualisation.id,
                title: visualisation.title,
                subtitle: visualisation.subtitle,
                type: visualisation.type,
                workflowId: visualisation.workflow.id,
                taskId: visualisation.task?.id || null,
                configuration: { ...visualisation.configuration, options: chartConfigurationOptions },
                assetId: visualisation.assetId,
                retrieval: visualisation.retrieval,
            };

            // Get retrieval data if there is assetId and normal run completed
            if (visualisation?.assetId && completedExecutionIndex !== -1) {
                const payload = { ...visualisation.retrieval, assetId: visualisation.assetId };
                getVisualisationData(payload).then((data: any) => {
                    retrievalData.value = data.data;
                });
            } else if (visualisation && completedExecutionIndex !== -1) {
                // Check for assetId and update visualisation retrieval configuration
                const assetId = visualisation.task?.configuration
                    ? visualisation.task.configuration.assetId.value
                    : null;
                if (assetId) {
                    const updatedRetrieval = {
                        ...visualisationConfig.value.retrieval,
                        assetId,
                    };

                    updateVisualisation({
                        ...visualisationConfig.value,
                        assetId,
                        retrieval: updatedRetrieval,
                    }).then((updatedVisualisationConfig: any) => {
                        visualisationConfig.value = updatedVisualisationConfig.data;
                        getVisualisationData(updatedRetrieval).then((data: any) => {
                            retrievalData.value = data.data;
                        });
                    });
                }
            } else if (completedExecutionIndex === -1) {
                hasNormalRunData.value = false;
            }
        });

        const busy = computed(() => loading.value || apolloRunner.loading.value);
        const errors = computed(() => {
            const errorsList = [];
            if (apolloRunner.error.value) {
                errorsList.push(apolloRunner.error.value.message);
            }
            if (error.value) {
                errorsList.push(error.value.message);
            }

            return errorsList;
        });

        const isFinilised = computed(() => {
            return currentWorkflow.value?.status === WorkflowStatus.Ready;
        });

        const hasValidConfiguration = computed(() => {
            if (!visualisationConfig.value?.assetId) return false;
            if (!retrievalData.value) return false;

            return true;
        });

        const newChart = computed(() =>
            visualisationConfig?.value?.configuration
                ? Chart.findChart(visualisationConfig.value.configuration.type)
                : null,
        );

        const inputParams = computed(() => ({
            configuration: visualisationConfig?.value || null,
            visualisationData: retrievalData?.value || null,
            isPie: newChart.value?.iBelongInThePieFamily() || false,
        }));

        const chartData = computed(() => computeVisualisationData(inputParams.value));

        return {
            downloadChart,
            logoBase64,
            back,
            busy,
            errors,
            chartRef,
            chartData,
            visualisationConfig,
            contentRef,
            goToConfiguration,
            hasValidConfiguration,
            isFinilised,
            hasNormalRunData,
            WarningMessages,
        };
    },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col w-full overflow-hidden task-configuration-header"},[_c('div',{staticClass:"flex flex-row justify-between flex-1",class:[
            {
                'bg-purple-600 ': _vm.category.colour === 'purple',
                'bg-blue-700  ': _vm.category.colour === 'blue',
                'bg-green-800 ': _vm.category.colour === 'green',
                'bg-orange-800 ': _vm.category.colour === 'orange',
                'bg-cherry-600': _vm.category.colour === 'indigo',
            } ]},[_c('div',{staticClass:"flex flex-col flex-1 flex-grow flex-shrink px-4 py-6 space-y-1 overflow-hidden sm:px-6"},[(!_vm.showEditName)?_c('div',{staticClass:"flex flex-row items-center justify-between flex-grow-0 flex-shrink-0 space-x-2 cursor-pointer group",on:{"click":function($event){$event.stopPropagation();_vm.showEditName = true}}},[_c('div',{staticClass:"flex overflow-hidden text-lg font-medium text-white py-0.5"},[_c('span',{staticClass:"truncate"},[_vm._v(_vm._s(_vm.newDisplayName))])]),_c('div',{staticClass:"flex flex-grow-0 flex-shrink-0 w-6"},[_c('PencilAltIcon',{staticClass:"hidden w-5 h-5 group-hover:block",class:[
                            {
                                'text-purple-100 ': _vm.category.colour === 'purple',
                                'text-blue-100  ': _vm.category.colour === 'blue',
                                'text-green-100 ': _vm.category.colour === 'green',
                                'text-orange-100 ': _vm.category.colour === 'orange',
                                'text-cherry-100': _vm.category.colour === 'indigo',
                            } ]})],1)]):_c('ValidationProvider',{attrs:{"rules":"required|max:32","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
                        var invalid = ref.invalid;
                        var valid = ref.valid;
return [_c('div',{staticClass:"relative flex flex-row items-center justify-between"},[_c('TwInput',{staticClass:"w-full",attrs:{"type":"text","name":"name","errors":errors,"fullError":false,"additionalInputClasses":[invalid ? 'pr-8' : '', 'rounded-r-none']},on:{"enter":function($event){return _vm.saveDisplayName(valid)}},model:{value:(_vm.newDisplayName),callback:function ($$v) {_vm.newDisplayName=$$v},expression:"newDisplayName"}}),_c('div',{staticClass:"flex flex-row p-2 space-x-1 rounded-r bg-neutral-200",on:{"click":_vm.resetDisplayName}},[_c('XCircleIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:("Cancel rename"),expression:"`Cancel rename`"}],staticClass:"w-4 h-4 cursor-pointer text-neutral-500 hover:text-neutral-700"})],1)],1)]}}])}),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.description),expression:"description"}],staticClass:"flex overflow-hidden"},[_c('p',{staticClass:"text-sm truncate",class:[
                        {
                            'text-purple-100 ': _vm.category.colour === 'purple',
                            'text-blue-100  ': _vm.category.colour === 'blue',
                            'text-green-100 ': _vm.category.colour === 'green',
                            'text-orange-100 ': _vm.category.colour === 'orange',
                            'text-cherry-100': _vm.category.colour === 'indigo',
                        } ]},[_vm._v(" "+_vm._s(_vm.description)+" ")])])],1),(!_vm.isOutput)?_c('div',{staticClass:"flex flex-row items-center flex-grow-0 flex-shrink-0"},[(_vm.runningExecutionTooltip || _vm.isDeprecated)?_c('PlayIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.runningExecutionTooltip),expression:"runningExecutionTooltip"}],staticClass:"w-10 h-10 mr-4 opacity-50 cursor-not-allowed text-neutral-300"}):_c('PlayIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:("Perform a partial run, up to this task, to get a sample"),expression:"`Perform a partial run, up to this task, to get a sample`"}],staticClass:"w-10 h-10 mr-4 text-white cursor-pointer",on:{"click":function($event){$event.stopPropagation();return _vm.testRun.apply(null, arguments)}}})],1):_vm._e()]),_c('div',{staticClass:"flex flex-row flex-1 p-2 px-6 space-x-2 overflow-hidden text-sm",class:[
            {
                'bg-purple-100 ': _vm.category.colour === 'purple',
                'bg-blue-100  ': _vm.category.colour === 'blue',
                'bg-green-100 ': _vm.category.colour === 'green',
                'bg-orange-100 ': _vm.category.colour === 'orange',
                'bg-cherry-100': _vm.category.colour === 'indigo',
            } ]},[_c('v-popover',{staticClass:"flex flex-row flex-1 overflow-hidden",attrs:{"trigger":"hover focus","placement":"top","delay":{ show: 500 }}},[_vm._t("default",function(){return [_c('div',{staticClass:"flex flex-row items-center flex-1 space-x-2 overflow-hidden cursor-help"},[(_vm.type)?_c('span',{staticClass:"px-1 bg-white rounded text-neutral-600"},[_vm._v(" "+_vm._s(_vm.type)+" ")]):_vm._e(),_c('span',{staticClass:"font-bold truncate text-neutral-700"},[_vm._v(" "+_vm._s(_vm.task.block.name)+" ")]),_c('span',{staticClass:"text-neutral-600"},[_vm._v(_vm._s(_vm.task.block.version))])])]}),_c('div',{staticClass:"flex-shrink-0 text-white border rounded shadow-lg w-72 flex-end bg-neutral-900 border-neutral-900 flex-no-grow",attrs:{"slot":"popover"},slot:"popover"},[_vm._v(" This task is based on block "),_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.task.block.name)+" v"+_vm._s(_vm.task.block.version))]),_vm._v(" of category "),_c('span',{staticClass:"font-bold",class:[
                        {
                            'text-purple-600 ': _vm.category.colour === 'purple',
                            'text-blue-700  ': _vm.category.colour === 'blue',
                            'text-green-800 ': _vm.category.colour === 'green',
                            'text-orange-800 ': _vm.category.colour === 'orange',
                            'text-cherry-100': _vm.category.colour === 'indigo',
                        } ]},[_vm._v(_vm._s(_vm.category.label))]),(_vm.type)?_c('span',[_vm._v(" and type "),_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.type))])]):_vm._e()])],2)],1),(_vm.messages && _vm.messages.length)?_c('TaskConfigurationMessages',{attrs:{"messages":_vm.messages,"configuration":_vm.configuration}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
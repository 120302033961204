import * as R from 'ramda';
import { ScatterIcon } from '../../components/results-view/assets/icons';
import { AggregationFunction, ApexChartType } from '../../constants';
import { Chart } from './chart';

export class Scatter extends Chart {
    protected chartType: ApexChartType = ApexChartType.Scatter;

    protected description = 'A scatter graph is used to display values for typically two variables for a set of data';

    protected aggregationMaxFields = 1;

    protected icon: any = ScatterIcon;

    protected aggregationFunctions: AggregationFunction[] = [];

    configuration(title: string): any {
        return R.clone({
            ...Chart.defaultConfiguration(R.isNil(title) ? 'Title' : title, this.description),
            ...Chart.defaultLegend,
            ...Chart.defaultMarkers,
            ...Chart.defaultGrid,
            ...Chart.defaultXAxis,
            ...Chart.defaultYAxis,
            ...Chart.defaultChartOption,
            ...Chart.defaultDataLabels,
        });
    }
}

Chart.register(new Scatter());

import * as R from 'ramda';
import { HeatmapIcon } from '../../components/results-view/assets/icons';
import { AggregationFunction, ApexChartColors, ApexChartType } from '../../constants';
import { Chart } from './chart';

export class Heatmap extends Chart {
    protected chartType: ApexChartType = ApexChartType.Heatmap;

    protected description = 'A Heat map describes a set of data through variations in coloring.';

    protected aggregationMaxFields = 2;

    protected icon: any = HeatmapIcon;

    protected aggregationFunctions: AggregationFunction[] = [AggregationFunction.COUNT, AggregationFunction.SUM];

    configuration(title: string): any {
        const defaultConfig = {
            ...Chart.defaultConfiguration(R.isNil(title) ? 'Title' : title, this.description),
            colors: [ApexChartColors.HeatmapDefault],
        };
        return R.clone({
            ...defaultConfig,
            ...Chart.defaultLegend,
            ...Chart.defaultChartOption,
            ...Chart.defaultXAxis,
            ...Chart.defaultYAxis,
            ...Chart.defaultDataLabels,
            plotOptions: {
                heatmap: {
                    reverseNegativeShade: true,
                    distributed: false,
                    useFillColorAsStroke: false,
                    colorScale: {
                        inverse: false,
                    },
                },
            },
        });
    }
}

Chart.register(new Heatmap());

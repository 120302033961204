























































































































































































































import { MonitoringAPI } from '@/app/api';
import { TailwindTable, TimelinessBadge } from '@/app/components';
import { useAxios, useFilters } from '@/app/composable';
import { ColumnPosition, ColumnType } from '@/app/constants';
import { MonitoringExecutionQuery } from '@/app/types';
import { PropType, Ref, computed, defineComponent, ref, watch } from '@vue/composition-api';
import { ResultTimeliness, ResultTimelinessResult } from '../../types/result-timeliness';
import { AssetType } from '@/modules/asset/constants';
import { ExclamationIcon } from '@vue-hero-icons/solid';

export default defineComponent({
    name: 'ResultTimeliness',
    props: {
        query: { type: Object as PropType<MonitoringExecutionQuery>, required: true },
    },
    components: {
        TailwindTable,
        TimelinessBadge,
        ExclamationIcon,
    },
    setup(props, { root, emit }) {
        const { exec, loading } = useAxios(true);
        const { fromNow: dateFromNow, formatDateAs: formatDate } = useFilters();
        const timeliness: Ref<ResultTimeliness | undefined> = ref<ResultTimeliness>();

        const fetchTimeliness = async (query: MonitoringExecutionQuery) => {
            await exec(MonitoringAPI.executionTimeliness(query, AssetType.Result))
                .then((res: any) => {
                    timeliness.value = res.data;
                    emit('update-show-results-warning', res.data.pagination.total >= 1000);
                })
                .catch((e) => {
                    (root as any).$toastr.e(e.response.data.message, 'Error');
                    throw e;
                });
        };

        const columns = [
            {
                key: 'asset',
                label: 'Asset',
                type: ColumnType.Object,
                link: (value: { id: number }) => {
                    return value ? { name: 'assets:view', params: { id: `${value.id}` } } : null;
                },
            },
            {
                key: 'workflowId',
                label: 'Pipeline ID',
                type: ColumnType.Uid,
                showAfter: 'md',
                copy: true,
                link: (value: number, row: any) => {
                    return value
                        ? {
                              name: 'history',
                              params: { workflowId: `${value}` },
                              query: {
                                  executionId: row.executionId,
                              },
                          }
                        : null;
                },
            },
            {
                key: 'timeliness',
                label: 'Timeliness',
                type: ColumnType.String,
                position: ColumnPosition.Center,
                sortable: true,
            },
            {
                key: 'lastSuccessfulExecution',
                label: 'Last Successful Execution',
                type: ColumnType.Datetime,
                formatInUtc: true,
            },
            {
                key: 'inputAssets',
                label: 'Input Assets and Timeliness',
                type: ColumnType.Object,
            },
            {
                key: 'runOnNewData',
                label: 'Run on new data',
                type: ColumnType.Boolean,
            },
            {
                key: 'triggers',
                label: 'Run on Trigger related to Input Assets',
                showAfter: 'lg',
                type: ColumnType.Datetime,
            },
            {
                key: 'mode',
                label: 'Mode',
                showAfter: 'lg',
                type: ColumnType.String,
            },
        ];

        const rows = computed(() => timeliness.value?.results);

        const pagination = computed(() => timeliness.value?.pagination);

        const triggers = computed(() =>
            rows.value
                ? rows.value.reduce(
                      (
                          acc: Record<
                              number,
                              {
                                  coverage: string;
                                  assetsAsTriggers: number;
                                  totalAssets: number;
                              }
                          >,
                          result: ResultTimelinessResult,
                      ) => {
                          const assetsAsTriggers = result.inputAssets.filter(
                              (asset: { isTrigger: boolean }) => asset.isTrigger,
                          );
                          let coverage: string;
                          switch (assetsAsTriggers.length) {
                              case result.inputAssets.length:
                                  coverage = 'Yes';
                                  break;
                              case 0:
                                  coverage = 'No';
                                  break;
                              default:
                                  coverage = 'Partially';
                                  break;
                          }
                          acc[result.assetId] = {
                              coverage,
                              assetsAsTriggers: assetsAsTriggers.length,
                              totalAssets: result.inputAssets.length,
                          };
                          return acc;
                      },
                      {},
                  )
                : [],
        );
        const changeSort = (sortBy: { field: string; asc: boolean }) => {
            emit('sort', sortBy);
        };

        watch(
            () => props.query,
            (query: MonitoringExecutionQuery, oldQuery: MonitoringExecutionQuery | undefined) => {
                if (!oldQuery || JSON.stringify(oldQuery) !== JSON.stringify(query)) fetchTimeliness(query);
            },
            { deep: true, immediate: true },
        );

        watch(
            () => loading.value,
            (l: boolean) => emit('loading', l),
            { immediate: true },
        );

        return {
            rows,
            loading,
            columns,
            triggers,
            pagination,
            timeliness,
            AssetType,
            dateFromNow,
            formatDate,
            changeSort,
        };
    },
});











































































































































import { Scrollbar } from '@/app/components';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { useBlocks } from '../../composable/block';
import { BlockCategoryWrapper } from '../../constants';
import { Block } from '../../types';
import LibraryBlock from './LibraryBlock.vue';
import LibraryMenuFilters from './LibraryMenuFilters.vue';

export default defineComponent({
    name: 'GraphViewLibrary',
    props: {
        collapsed: {
            type: Boolean,
            default: false,
        },
        framework: {
            type: String,
            required: true,
        },
        frameworkVersion: {
            type: String,
            required: true,
        },
        platform: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: { LibraryMenuFilters, LibraryBlock, Scrollbar },
    setup(props, { emit }) {
        const libraryIconHovered = ref(false);
        const searchText = ref<string>('');
        const activeFilter = ref('input');
        const frameworkVersion = ref<string>(props.frameworkVersion);
        const { visibleBlocks, loading, refetch } = useBlocks(
            searchText,
            activeFilter,
            props.framework,
            frameworkVersion,
            props.platform,
        );

        const changeFilter = (filter: string) => {
            // if (!props.disableClickEvent) {
            activeFilter.value = filter;
            searchText.value = '';
            // }
        };

        const fullFilterName = computed(() => {
            const blockCategory = BlockCategoryWrapper.find(activeFilter.value);
            return blockCategory ? blockCategory.label : BlockCategoryWrapper.INPUT.label;
        });

        const createTask = (block: Block) => {
            emit('create-task', block);
        };

        const collapseLibrary = () => {
            emit('collapsed');
            searchText.value = '';
            activeFilter.value = 'input';
        };

        watch(
            () => props.frameworkVersion,
            (version) => {
                frameworkVersion.value = version;
                refetch();
            },
        );

        return {
            loading,
            searchText,
            activeFilter,
            visibleBlocks,
            fullFilterName,
            libraryIconHovered,
            emit,
            createTask,
            changeFilter,
            collapseLibrary,
        };
    },
});

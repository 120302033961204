






















































































































import { ConfirmModal, SvgImage } from '@/app/components';
import { ChevronLeftIcon, ChevronRightIcon, CogIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, PropType, Ref, ref, watch } from '@vue/composition-api';
import { TaskConfiguration } from '..';
import { BlockCategoryWrapper } from '../../constants';
import { Loop, Task } from '../../types';

export default defineComponent({
    name: 'TaskConfigurationPanel',
    props: {
        workflowId: { type: String, required: true },
        collapsed: {
            type: Boolean,
            required: true,
        },
        task: {
            type: Object as PropType<Task>,
            required: false,
        },
        tasks: {
            type: Map as PropType<Map<string, Task>>,
            default: new Map<string, Task>(),
        },
        columnsPerTask: {
            type: Object,
        },
        runningExecution: {
            type: Object,
            default: null,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        pipelines: {
            type: Array,
            default: () => [],
        },
        loops: {
            type: Array as PropType<Loop[]>,
            default: () => [],
        },

        isOnPremise: {
            type: Boolean,
            default: false,
        },
        runnerId: {
            type: String,
            default: null,
        },
        invalidTaskIds: {
            type: Array,
            default: () => [],
        },
        saving: {
            type: Boolean,
            default: false,
        },
        deleting: {
            type: Boolean,
            default: false,
        },
        disableChange: {
            type: Boolean,
            default: false,
        },
        isDeprecated: {
            type: Boolean,
            default: false,
        },
        isFinalised: {
            type: Boolean,
            default: false,
        },
    },
    components: { TaskConfiguration, SvgImage, ConfirmModal, CogIcon, ChevronLeftIcon, ChevronRightIcon },
    setup(props, { emit, root }) {
        const cogIconHovered = ref<boolean>(false);
        const checkForChanges: Ref<boolean> = ref<boolean>(false);
        const showTaskEditCancelConfirmation: Ref<boolean> = ref<boolean>(false);
        const taskInConfig = ref<Task | undefined>();
        const forceUpdate = ref<Date>(new Date());

        const blockCategory = computed(() =>
            taskInConfig.value ? BlockCategoryWrapper.find(taskInConfig.value.block.category) : undefined,
        );

        const backgroundColor = computed(() => {
            switch (blockCategory.value) {
                case BlockCategoryWrapper.INPUT:
                    return 'bg-blue-700';
                case BlockCategoryWrapper.PREPARATION:
                    return 'bg-green-800';
                case BlockCategoryWrapper.ML:
                    return 'bg-orange-800';
                case BlockCategoryWrapper.OUTPUT:
                    return 'bg-purple-600';
                case BlockCategoryWrapper.CONTROL:
                    return 'bg-cherry-600';
                default:
                    return 'bg-secondary-700';
            }
        });

        const hoveredTextColor = computed(() => {
            switch (blockCategory.value) {
                case BlockCategoryWrapper.INPUT:
                    return 'group-hover:text-blue-200';
                case BlockCategoryWrapper.PREPARATION:
                    return 'group-hover:text-green-200';
                case BlockCategoryWrapper.ML:
                    return 'group-hover:text-orange-200';
                case BlockCategoryWrapper.OUTPUT:
                    return 'group-hover:text-purple-200';
                case BlockCategoryWrapper.CONTROL:
                    return 'group-hover:text-indigo-200';
                default:
                    return 'group-hover:text-secondary-200';
            }
        });

        const setCheckChanges = (check: boolean) => {
            checkForChanges.value = check;
        };

        const close = () => {
            emit('close');
        };

        const cancel = () => {
            if (checkForChanges.value) {
                showTaskEditCancelConfirmation.value = true;
            } else {
                showTaskEditCancelConfirmation.value = false;
            }
        };

        const discardChanges = () => {
            checkForChanges.value = false;
            showTaskEditCancelConfirmation.value = false;
            taskInConfig.value = undefined;
            forceUpdate.value = new Date();
            taskInConfig.value = props.task;
        };

        const keepEditing = () => {
            emit('show-settings', taskInConfig.value, true);
            showTaskEditCancelConfirmation.value = false;
        };

        const handleEscape = (e: KeyboardEvent) => {
            if (e.key === 'Esc' || e.key === 'Escape') {
                close();
            }
        };
        document.addEventListener('keydown', handleEscape);
        root.$once('hook:beforeDestroy', () => {
            document.removeEventListener('keydown', handleEscape);
        });

        watch(
            () => props.task,
            (newTask: Task | undefined) => {
                if (checkForChanges.value && newTask?.id !== taskInConfig.value?.id) {
                    showTaskEditCancelConfirmation.value = true;
                } else {
                    if (newTask?.id !== taskInConfig.value?.id) checkForChanges.value = false;
                    taskInConfig.value = newTask;
                }
            },
            { deep: true },
        );

        watch(
            () => props.collapsed,
            (isCollapsed: boolean) => {
                if (!isCollapsed) forceUpdate.value = new Date();
            },
        );

        return {
            taskInConfig,
            forceUpdate,
            checkForChanges,
            cogIconHovered,
            backgroundColor,
            hoveredTextColor,
            showTaskEditCancelConfirmation,
            close,
            cancel,
            keepEditing,
            discardChanges,
            setCheckChanges,
        };
    },
});

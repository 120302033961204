






















import { TwButton, AdvancedOrderedSelect } from '@/app/components';

import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
    name: 'EditColumns',
    components: {
        TwButton,
        AdvancedOrderedSelect,
    },
    props: {
        visibleColumns: {
            type: Array,
            default: () => [],
        },
        hiddenColumns: {
            type: Array,
            default: () => [],
        },
        structureColumns: {
            type: Array,
            default: () => [],
        },
    },
    setup(props, { emit }) {
        const columns = ref({
            selected: props.visibleColumns,
            unselected: props.hiddenColumns,
            structure: props.structureColumns,
        });

        const changeColumns = () => emit('update-columns', columns.value);

        return {
            columns,
            changeColumns,
        };
    },
});

















































import { computed, defineComponent, PropType } from '@vue/composition-api';
import * as R from 'ramda';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useFilters, useMonitoringPeriod } from '@/app/composable';
import { MonitoringExecutionQuery } from '@/app/types';
import { BlockCategory, BlockType, TriggerSource } from '../../constants';
dayjs.extend(utc);

export default defineComponent({
    name: 'MonitoringQuerySummary',
    props: {
        query: { type: Object as PropType<MonitoringExecutionQuery>, required: true },
        availableFacets: {
            type: Object as PropType<
                Record<
                    string,
                    {
                        label: string;
                        visible: boolean;
                        default: string[];
                        valueLabel?: any;
                        permitted?: string[] | undefined;
                    }
                >
            >,
            required: true,
        },
    },
    setup(props) {
        const { formatDateAs: formatDate } = useFilters();
        const { findPeriod } = useMonitoringPeriod();
        const formatList = (list: string[]) => {
            if (R.isNil(list) || R.isEmpty(list)) return null;
            if (list.length === 1) return list[0];
            const last = list.pop();
            return `${list.join(',')}${last ? ' or ' + last : ''}`;
        };

        const period = computed(() => findPeriod(props.query.query.dateRange?.start, props.query.query.dateRange?.end));

        const ownership = computed(() => (props.query.query.organisationId ? "my organisation's" : 'my'));

        const showAnd = (previousFacetKeys: string[]) =>
            previousFacetKeys.some(
                (key: string) => props.query.facets && props.query.facets[key] && props.query.facets[key].length > 0,
            );

        const getBlockCategoryNames = (blockCategories: string[]) =>
            blockCategories.map((category: string) => {
                switch (category) {
                    case BlockCategory.Input:
                        return 'Input';
                    case BlockCategory.Output:
                        return 'Output';
                    case BlockCategory.Control:
                        return 'Control';
                    case BlockCategory.Prep:
                        return 'Data Preparation';
                    case BlockType.Apply:
                        return 'ML Apply';
                    case BlockType.Train:
                        return 'ML Train';
                    case BlockType.Evaluate:
                        return 'ML Evaluate';
                    default:
                        return 'Unknown';
                }
            });

        const getTriggerSourceNames = (triggerSources: string[]) =>
            triggerSources.map((source: string) => {
                switch (source) {
                    case TriggerSource.Manual:
                        return 'manually';
                    case TriggerSource.Schedule:
                        return 'via a schedule';
                    case TriggerSource.Trigger:
                        return 'via a trigger';
                    default:
                        return 'Unknown';
                }
            });

        return { period, ownership, showAnd, formatDate, formatList, getBlockCategoryNames, getTriggerSourceNames };
    },
});

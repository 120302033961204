




































import { Scrollbar } from '@/app/components';
import { defineComponent, PropType } from '@vue/composition-api';
import { Trigger } from '.';
import { PlusIcon } from '@vue-hero-icons/outline';
import { Workflow, WorkflowTrigger } from '../../types';

export default defineComponent({
    name: 'TriggersOverview',
    components: {
        Trigger,
        Scrollbar,
        PlusIcon,
    },
    props: {
        workflow: {
            type: Object as PropType<Workflow>,
            required: true,
        },
        triggers: {
            type: Array as PropType<WorkflowTrigger[]>,
            required: true,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Object as PropType<WorkflowTrigger>,
        },
        disabled: {
            type: Boolean,
            default: true,
        },
        disabledMessage: {
            type: String,
        },
    },
});

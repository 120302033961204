













































































































































import { computed, defineComponent, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';

import { DateTimePicker, InputErrorIcon, TwSelect, TwInput } from '@/app/components';
import { QueryOperant } from '@/app/constants';
import { doubleValidator, integerValidator, requiredValidator } from '@/app/validators';
import { DataType } from '@/modules/workflow-designer/constants';

extend('required', requiredValidator);
extend('integer', integerValidator);
extend('double', doubleValidator);

export default defineComponent({
    name: 'FilterEditor',
    props: {
        query: {
            type: Object,
            required: true,
        },
        fields: {
            type: Array,
            required: true,
        },
        specificField: {
            type: String,
            default: null,
        },
        aggregator: {
            type: String,
            default: null,
        },
    },
    components: {
        InputErrorIcon,
        ValidationProvider,
        ValidationObserver,
        DateTimePicker,
        TwSelect,
        TwInput,
    },
    setup(props, { emit }) {
        const findField = (fieldName: string) => {
            const foundField = (props.fields as any).find(
                (f: { label: string; value: string }) => f.label === fieldName,
            );
            if (!foundField) throw Error(`Field "${fieldName}" not found in structure.`);

            return foundField;
        };

        const disableField = computed(() => !!props.specificField);

        const field = computed(() => {
            let fieldName: string | null = null;
            if (props.specificField) {
                fieldName = props.specificField;
            } else if (props.query.field) {
                fieldName = props.query.field;
            }

            if (fieldName) {
                return findField(fieldName);
            }
            return null;
        });

        const fieldType = computed((): DataType | null => {
            if (field.value) return field.value.value;
            return null;
        });

        const fieldTypeOperants = computed(() => {
            if (!fieldType.value) return [];

            switch (fieldType.value) {
                case DataType.Integer:
                case DataType.Double:
                case DataType.DateTime:
                    return [
                        QueryOperant.EQUALS,
                        QueryOperant.NOT_EQUALS,
                        QueryOperant.GREATER_THAN,
                        QueryOperant.GREATER_THAN_OR_EQUAL_TO,
                        QueryOperant.LESS_THAN,
                        QueryOperant.LESS_THAN_OR_EQUAL_TO,
                    ];
                case DataType.Boolean:
                    return [QueryOperant.EQUALS, QueryOperant.NOT_EQUALS];
                case DataType.String:
                    return [
                        QueryOperant.EQUALS,
                        QueryOperant.NOT_EQUALS,
                        QueryOperant.CONTAINS,
                        QueryOperant.STARTS_WITH,
                        QueryOperant.ENDS_WITH,
                    ];
                default:
                    return [];
            }
        });

        const fieldTypeOptions = computed(() => {
            if (!fieldType.value) return [];
            switch (fieldType.value) {
                case DataType.Boolean:
                    return [
                        { key: true, name: 'true' },
                        { key: false, name: 'false' },
                    ];
                default:
                    return [];
            }
        });
        const preprocessNumbers = (val: any) => {
            if (R.isNil(val) || R.isEmpty(val)) return null;
            return parseFloat(val);
        };

        watch(
            () => fieldType.value,
            (newFieldType: DataType | null) => {
                emit('change', { ...props.query, type: newFieldType });
            },
        );

        return {
            field,
            DataType,
            fieldType,
            disableField,
            fieldTypeOperants,
            fieldTypeOptions,
            preprocessNumbers,
        };
    },
});































import { computed, defineComponent, PropType, Ref, ref, watch } from '@vue/composition-api';
import { Chart, Task, Visualisation } from '../../types';
import ResultsViewTask from './ResultsViewTask.vue';

export default defineComponent({
    name: 'ResultsViewOptions',
    model: {
        prop: 'selected',
        event: 'update',
    },
    props: {
        tasks: { type: Array as PropType<Task[]>, default: () => [] },
        selected: { type: String, required: false },
        isValidWorkflow: { type: Boolean, default: true },
        visualisations: { type: Array as PropType<Visualisation[]>, default: () => [] },
        readOnly: { type: Boolean, default: true },
        chart: { type: Object as PropType<Chart>, required: false },
        addForTask: { type: String, required: false },
        updateChartTypeForVisualisation: { type: String, required: false },
    },
    components: { ResultsViewTask },
    setup(props, { emit }) {
        const isBlockListExpanded = ref<boolean>(true);
        const selectedChart: Ref<Partial<Chart> | undefined> = ref<Chart | undefined>(props.chart);
        const isShowMoreDisabled = computed(() => {
            return props.tasks.length < 2;
        });

        const selectedVisualisation = computed(() =>
            props.selected
                ? props.visualisations.find((visualisation: Visualisation) => visualisation.id === props.selected)
                : undefined,
        );

        const selectedTask: Ref<Task | undefined> = computed(() => {
            if (props.addForTask) props.tasks.find((task: Task) => props.addForTask === task.id);
            else if (selectedVisualisation.value)
                props.tasks.find((task: Task) => selectedVisualisation.value?.taskId === task.id);
            return undefined;
        });

        const filteredVisualisations = computed(() => {
            if (props.readOnly) {
                if (props.visualisations && props.tasks && props.tasks.length) {
                    const filteredVis: any = [];

                    props.visualisations.forEach((visualisation: any) => {
                        const taskWithVisualisation = props.tasks.filter(
                            (task: any) => task.id === visualisation.taskId,
                        );

                        if (taskWithVisualisation && taskWithVisualisation.length) {
                            filteredVis.push(taskWithVisualisation[0]);
                        }
                    });

                    return filteredVis;
                }
            }

            return props.tasks;
        });

        const visualisationsMap = computed(() =>
            props.tasks.reduce((acc: Record<string, Visualisation[]>, task: Task) => {
                acc[task.id] = props.visualisations.filter(
                    (visualisation: Visualisation) => visualisation.taskId === task.id,
                );
                return acc;
            }, {}),
        );

        const addNewChart = (task: Task) => {
            emit('update', undefined);
            emit('add-for-task', task.id);
        };

        const editChartType = (visualisationId: string) => {
            emit('update', visualisationId);
            emit('change-chart-type', visualisationId);
        };

        watch(
            () => props.chart,
            (newChart: Chart | undefined) => (selectedChart.value = newChart),
        );

        return {
            isShowMoreDisabled,
            isBlockListExpanded,
            filteredVisualisations,
            visualisationsMap,
            selectedTask,
            selectedChart,
            addNewChart,
            editChartType,
        };
    },
});
